import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ResponsiveAppBar from "../../components/TopNavigationBar/AppBar/AppBarMui";
import NeramFooter from "../../components/Footer/footer";
import HeroWavesComponent from "../../components/Sections/WaveAnimate";
import MyButton from "../../components/Basic components/Buttons/Buttons";

const ImageNotFound = styled.img`
  max-width: 20rem;
`;

const NotFound = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/");
  };

  return (
    <div style={{ backgroundColor: "var(--lightpink)" }}>
      <ResponsiveAppBar variant="opaque" showBackButton={true} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "6rem",
        }}
      >
        <ImageNotFound src="images/notfound.png" alt="" srcset="" />
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "2rem 0" }}
      >
        <MyButton variant={"contained"} onClick={handleButtonClick}>
          Home
        </MyButton>
      </div>
      <div className="position-relative">
        <HeroWavesComponent position="top" bgcolor={"var(--lightpink)"} />
        <NeramFooter />
      </div>
    </div>
  );
};

export default NotFound;
