import * as React from "react";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import JoinPremium from "./JoinPremiumPopup";
// JJEEE
const setsData = [
  {
    name: "2005",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EWtDoTnMJGtBnFl5QIAikL8BmJAjnQeHclvz1f87Vw-etA?e=901GpQ",
        videoLink: "https://www.youtube.com/watch?v=zag2jNjbnW8",
      },
    ],
  },
  {
    name: "2006",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EeTE8EFN77xJkWQTMUMDJPkBrCCUzDd4hjIzxkMCpV8jYA?e=e9cFZA",
        videoLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EeTE8EFN77xJkWQTMUMDJPkBrCCUzDd4hjIzxkMCpV8jYA?e=e9cFZA",
      },
    ],
  },
  {
    name: "2007",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/ERaVmjRywklFhYyv0oVA-oABlms9SE0GKHpbz9LglfGZdQ?e=rRerSh",
        videoLink: "https://youtu.be/ej1UgtsfvGs",
      },
    ],
  },
  {
    name: "2008",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EU59sdh2XQVDiokB3TuVKZwBOzWM0Z_4NFWy9tkYmoB2eA?e=tIiM0R",
        videoLink: "https://youtu.be/Js6hgnsXQ9Q",
      },
    ],
  },
  {
    name: "2009",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EXxo8o40R6JNogHKDUZan3QBVGHrsUfMjjN35GyJc7bW0w?e=llqNOE",
        videoLink: "https://youtu.be/9o6jJwFPnFg",
      },
    ],
  },
  {
    name: "2010",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/ER_G4SSrERxDlRs_3sbZYo0BN5V3TEM6N6bL0T7SiGdnYA?e=5bYlcB",
        videoLink: "https://youtu.be/LXQDvFVWJeA",
      },
    ],
  },
  {
    name: "2011",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/Edy5-KKDnyFAveo7W0aoK0ABazeTuHp535ZiISv7jJBGKA?e=bLGWo1",
        videoLink: "https://youtu.be/P8m-ubsyx4g",
      },
    ],
  },
  {
    name: "2012",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EVtUBTCpadFLkhwDOAiRM38BznspBvYLMJeISdSadtpoJg?e=Cgjruc",
        videoLink: "https://youtu.be/nNgEtK1stD0",
      },
    ],
  },
  {
    name: "2013",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/Ed-RG7nWUjVHkSOvAmEHlaIBmb_aBfoFmnt3P_qlucwf4Q?e=G4exNV",
        videoLink: "https://youtu.be/ISlorxO3Wlg",
      },
    ],
  },
  {
    name: "2014",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EW7sg6sbZFlMmSHNSmB0S-UBdEidsw27GF97ZHy9gXkjjA?e=0ua6DR",
        videoLink: "https://youtu.be/zUjDOH-cG5o",
      },
    ],
  },
  {
    name: "2015",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EXi8EzqQ6fpDo22P2ar1OGkBNN-GJyUFctG44Ebnm8uEag?e=RcCaZ5",
        videoLink: "https://youtu.be/Vbqdl4hyE4s",
      },
    ],
  },
  {
    name: "2016",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EShqi8lTD-RIk9OzLhvufIsBnskCuHNsjCizlYeH3eRblQ?e=j7tTvd",
        videoLink: "https://youtu.be/Cy9mnJAt88Q",
      },
    ],
  },
  {
    name: "2017",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EfckHd8pM0FEj5Y-2piDy_QB8lsBlD7iZlYMV_JK8Ub-tw?e=dC8usV",
        videoLink: "https://youtu.be/KeeiAcgrP9M",
      },
    ],
  },
  {
    name: "2018",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EQcHPWjXbNpEpDZcIVYYRwsBzFtfpoetqLAloRri4eMfNA?e=6YUKZT",
        videoLink: "https://youtu.be/-XPX9cxrbxI",
      },
    ],
  },
  {
    name: "2019",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EW8kTX464B1EqIgu1VfcRZgB8J8Rwu62Jimcj6cxPI706w?e=HRvNpH",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EYQ-O9jbDl5HpoH8R87Yk6YB8MOL1_SD2jhutmQLWjor-g?e=XKrQTw",
        videoLink: "https://youtu.be/movnrqAz4cA",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EbWWnVexXe5MgAju6xXFFHIBhpBIHcNheQIlf9yfsDPQgw?e=2pKiEn",
        videoLink: "https://youtu.be/jjXauAEO200",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EcjfLqiI2OxFvU-3VqxL8dkBxbwqvLiNsUWLxo2n68lPjA?e=QX2vnm",
        videoLink: "https://youtu.be/4B5Dr4jz0r8",
      },
    ],
  },
  {
    name: "2020",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/ERQWM0WcMwpFvAT9QXisSh8BDN8O7phU0dY8yHT9m1Vv9A?e=vyY8et",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EVh9vEMU6UJGjSOT6LLiTyMB6_fmt_o3WSYACeW6h1m7ZA?e=bfQQWx",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EX2Dz7AzxttAvfNFYGZD8CkBtq_iXotAeEHCAxrGnuZJHw?e=3y5zyO",
        videoLink: "",
      },
    ],
  },
  {
    name: "2021",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EVjMevbRKKxPlRRSmnsamGwB2U4sygLiQJ-EOAXv1T8-zg?e=zO3TF5",
        videoLink: "https://youtu.be/ijpkLVv7TL8",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EQJ2eXtxBeVGv-22hOw2CvMBUtxTfu-UgeR_22p4zKssqA?e=7xklQa",
        videoLink: "https://youtu.be/ijpkLVv7TL8",
      },
    ],
  },
  {
    name: "2022",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EQCRV6grYhVMica2yE61jkwBRsNtI6ElVJMh5XIVZQpdIQ?e=ulTr4y",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/ERixMFfrXUxAmm0vPm-QLjoBdcXfkqr8HQjjjUueIebUCQ?e=6smytJ",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EYMu--8_DgBKo65fcAGlIX8B3uPUF5EeY4TqrzVp9YxSuw?e=akzpAF",
        videoLink: "",
      },
    ],
  },
  {
    name: "2023",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EdZmfqN33zdMj99lmlGELlgBXaYXNzp0fXfocwyyhLMQkg?e=k9E8G3",
        videoLink: "",
      },
    ],
  },
  {
    name: "2024",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/Edr-sllWQLBMnw33QKPLBXcBKC9UkkmUWX1aCzXO1VbTDA?e=Jsv6pq",
        videoLink: "",
      },
    ],
  },
];

// NATA
const NATAsetsData = [
  {
    name: "2017",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/ES6SXkDOQWxBiHZevIgrA5EB4f6Qidf77wwxoXnfTKuYtA?e=xMy6SM",
        videoLink: "",
      },
    ],
  },
  {
    name: "2018",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EY-06Auzuj1KnDZkJcYO67QB_-7RTWQTW5S-ogb_hkRIsQ?e=24XTDS",
        videoLink: "",
      },
    ],
  },
  {
    name: "2019",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EcbrTRe-M5JNrLi89-5aWgQB_PhImS_n6PO7ouZgBAIxSQ?e=Dxd75t",
        videoLink: "",
      },
    ],
  },
  {
    name: "2020",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EfFSU-IWuyRLlyAJsNj0waEBTAX19QgZsMqyUdpn6Npi9g?e=1ZShTm",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EYUQiuf0UMVCoQwD2aMjnmsBBQ24qvxBWJLhx-B20dl6Tg?e=F5yHwv",
        videoLink: "",
      },
    ],
  },
  {
    name: "2021",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EVvi832-ZllJiiF6RoF941YBlyAvwfi-5uf340wIDUUZTg?e=qPqeB1",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EbYQ-ZQFloxGvbGLNQZgXxIBr2SQ_mALSBKldDq2WOiIQw?e=id4t2Q",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EQlLyLfgpFJEitB3RqsUNnoBEeIUUyIwjeznLVU3S3rkyg?e=BenJlp",
        videoLink: "",
      },
    ],
  },
  {
    name: "2022",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EcK601ubCTxCmuqmIcQbLUEBlQbra46_cLuyKfAuyM0dIA?e=YLhcWG",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/ES6t6NZQAM9CkAAb7Dd82yMBnR0ySZRxNWmLv771Tv7zHQ?e=dThE7o",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/ERWs4YvvybtJg3zDTiP5XzEBgnJg83s-7t-wlKEmCjSywg?e=v4ONMS",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/ET7uEOdF6TVImJ2l931j2MQBCHS7e8H2rUfXHISbboUu6Q?e=0fqQhK",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EXX0xPKwjNxOtc0zAHUC4w8BiRaqdvxLM0aOll_aCrC4Pg?e=GXmdnp",
        videoLink: "",
      },
    ],
  },
  {
    name: "2023",
    sets: [
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EQ2f0dhNb8xJqb75tc1IescBs9FqT6gl2UwNGXQYnsXRaA?e=k0ZZSf",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:b:/s/StudyZone/EShquGqB5DBDiKSB2fm6J40BSLDxAfTzjgLd8oEB0R3UkQ?e=QKxeRf",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:u:/s/StudyZone/ERP69FZmYWBJkY7Z7y558pMBVT7I0-81f2Sc0iHElgWsrA?e=FYbdlO",
        videoLink: "",
      },
      {
        pdfLink:
          "https://neramclasses.sharepoint.com/:u:/s/StudyZone/EeVl4rvszItAjtN9dYwImPEBuDT9AOCGArBYNjYhwaCZVQ?e=aQlYhV",
        videoLink: "",
      },
    ],
  },
];
//
export default function QuestionCard({ variant, type }) {
  return (
    <React.Fragment>
      {setsData.map((yearData, index) => (
        <QuestionCardItem
          type={type}
          key={index}
          yearData={yearData}
          variant={index % 2 === 0 ? "var(--grey50)" : "var(--grey100)"}
        />
      ))}
    </React.Fragment>
  );
}

export function NATAQuestionCard({ variant, type }) {
  return (
    <React.Fragment>
      {NATAsetsData.map((yearData, index) => (
        <QuestionCardItem
          type={type}
          key={index}
          yearData={yearData}
          variant={index % 2 === 0 ? "var(--grey50)" : "var(--grey100)"}
        />
      ))}
    </React.Fragment>
  );
}

function QuestionCardItem({ yearData, variant, type }) {
  const shortDesc = `Number of papers: ${yearData.sets.length}`;

  // JOINPREMIUM

  const [open, setOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  // -----------

  return (
    <>
      <JoinPremium open={open} handleClose={handleDialogClose} />
      <Card
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: variant,
          marginBottom: "1rem",
        }}
      >
        <Box
          className="Q_Maintitle"
          sx={{
            padding: "0 1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <h4 className="Q_Year">
            <b>{yearData.name}</b>
          </h4>
          <div className="no_sets">{shortDesc}</div>
        </Box>
        <Box
          className="Q_set_full"
          sx={{
            backgroundColor: "var(--lightpink)",
            padding: "0 1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "20rem",
          }}
        >
          {yearData.sets.map((setData, setIndex) => (
            <div
              key={setIndex}
              className="oneSet"
              style={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>Set {setIndex + 1}</span>
              {type === "free" ? (
                <IconButton aria-label="pdf" onClick={handleDialogOpen}>
                  <PictureAsPdfIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="pdf">
                  <Link to={setData.pdfLink}>
                    <PictureAsPdfIcon />
                  </Link>
                </IconButton>
              )}
              {type === "free" ? (
                <IconButton aria-label="pdf" onClick={handleDialogOpen}>
                  <OndemandVideoIcon />
                </IconButton>
              ) : (
                <IconButton aria-label="pdf">
                  <Link to={setData.videoLink}>
                    <PictureAsPdfIcon />
                  </Link>
                </IconButton>
              )}
            </div>
          ))}
        </Box>
      </Card>
    </>
  );
}
