import { Typography } from "@mui/material";

const styles = {
  bold: {
    fontWeight: "bold",
    color: "var(--violet)",
  },
};

function StepFour({ formData }) {
  return (
    <>
      <Typography sx={{ textDecoration: "underline", marginBottom: "1rem" }}>
        Kindly confirm this details
      </Typography>
      <Typography>
        Hi, I am the student named{" "}
        <span style={styles.bold}>{formData.Sname}</span> son of{" "}
        <span style={styles.bold}>{formData.Fname}</span> born as{" "}
        <span style={styles.bold}>{formData.Gender}</span> studying{" "}
        <span style={styles.bold}>{formData.Class}</span> in{" "}
        <span style={styles.bold}>{formData.Board}</span> Board (2024) living at
        the address <span style={styles.bold}>{formData.Address}</span>{" "}
        <span style={styles.bold}>{formData.State}</span> -{" "}
        <span style={styles.bold}>{formData.ZipCode}</span>{" "}
        <span style={styles.bold}>{formData.Country}</span>. You can reach me at
        the phone number <span style={styles.bold}>{formData.Phone}</span> or
        email <span style={styles.bold}>{formData.Email}</span>. I would like to
        take <span style={styles.bold}>{formData.Course}</span> and I paid fees
        using <span style={styles.bold}>{formData.Payby}</span>{" "}
        {formData.IG === ""
          ? "I don't use Instagram"
          : `I use Instagram as ${formData.IG}`}
        .
      </Typography>
    </>
  );
}

export default StepFour;
