import React from "react";
import InputField from "../../ApplicationCard/Children/InputDropField";
import styled from "styled-components";
import SixRowTable, {
  FaqAccordion1,
  FaqAccordion2,
  FaqAccordion3,
  FaqAccordion4,
  FaqAccordion5,
  FaqAccordion6,
} from "./FaqAccord";
import { createGlobalStyle } from "styled-components";
import { useState } from "react";

const TitleH1 = styled.h1`
  color: var(--violet);
`;

const FaqBody = () => {
  const [activeAccordion, setActiveAccordion] = useState("FaqAccordion1");
  const [ClickedRowIndex, setClickedRowIndex] = useState(null);

  const handleRowClick = (index) => {
    setClickedRowIndex(index);
    // Set the active accordion based on the clicked row index
    switch (index) {
      case 0:
        setActiveAccordion("FaqAccordion1");
        break;
      case 1:
        setActiveAccordion("FaqAccordion2");
        break;
      case 2:
        setActiveAccordion("FaqAccordion3");
        break;
      case 3:
        setActiveAccordion("FaqAccordion4");
        break;
      case 4:
        setActiveAccordion("FaqAccordion5");
        break;
      case 5:
        setActiveAccordion("FaqAccordion6");
        break;
      default:
        setActiveAccordion(null);
        break;
    }
  };

  return (
    <div className="container">
      <span className="row d-flex title-girl justify-content-center">
        <TitleH1 className="col-md-6 align-items-end">
          How can we help you
        </TitleH1>
        <img
          src="https://i.ibb.co/HnBmzLq/jee-math-chapterwise-weightage.png"
          alt="NATA doubts and questions"
          className="col-md-6"
          style={{ width: "350px", height: "325px" }}
        ></img>
      </span>
      <InputField label={"What are you looking for"} />
      <div className="row mt-4">
        <div className="col-md-4">
          <SixRowTable
            onRowClick={handleRowClick}
            ClickedRowIndex={ClickedRowIndex}
          />
        </div>
        <div className="col-md-8">
          {activeAccordion && (
            <>
              {activeAccordion === "FaqAccordion1" && <FaqAccordion1 />}
              {activeAccordion === "FaqAccordion2" && <FaqAccordion2 />}
              {activeAccordion === "FaqAccordion3" && <FaqAccordion3 />}
              {activeAccordion === "FaqAccordion4" && <FaqAccordion4 />}
              {activeAccordion === "FaqAccordion5" && <FaqAccordion5 />}
              {activeAccordion === "FaqAccordion6" && <FaqAccordion6 />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqBody;
