import styled from "styled-components";

const Welcome = styled.img`
  left: 50%;
  top: -53px;
  transform: translateX(-50%) scale(0.7);
  z-index: 1;
  width: 17rem;
`;

export default function Welcomeimage() {
  return (
    <Welcome
      src="images/img/card-top.png"
      alt="NATA Application form nata.in"
      className="position-absolute"
    />
  );
}
