import React from "react";
import styled from "styled-components";

const Text = styled.p`
  font-size: 1rem;
  font-weight: 700;
  color: var(--violet);
`;

function Advantages() {
  return (
    <div className="container my-5 pb-5">
      <div className="d-block d-lg-flex">
        <AdvantageComponent />
      </div>
    </div>
  );
}

const AdvantageData = [
  {
    imageUrl: "images/img/advantages/classes-icon.svg",
    altText: "",
    name: `Study from Alumnus of
    IIT & NIT Architects`,
  },
  {
    imageUrl: "images/img/advantages/self-paced-learning.svg",
    altText: "Alt text for image 2",
    name: `Watch missed Classes
    anytime`,
  },
  {
    imageUrl: "images/img/advantages/weekday-icon.svg",
    altText: "Alt text for image 3",
    name: `Regular classes to ensure regular study`,
  },
  {
    imageUrl: "images/img/advantages/doubts-icon.svg",
    altText: "Alt text for image 4",
    name: `Mentors will guide you personally`,
  },
  {
    imageUrl: "images/img/advantages/guidance-icon.svg",
    altText: "mock",
    name: `100s of Mock Tests
    based on new syllabus`,
  },
];

function AdvantageComponent() {
  return AdvantageData.map((adv, index) => (
    <span
      key={index}
      className="d-flex d-lg-block align-items-center my-3 my-lg-0"
    >
      <img
        src={adv.imageUrl}
        alt={adv.altText}
        height={"80"}
        width={"80"}
        className="me-4 me-lg-0"
      />
      <Text className="text-start text-lg-center mt-0 mt-lg-4">{adv.name}</Text>
    </span>
  ));
}

export default Advantages;
