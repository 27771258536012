import React, { useState, useEffect } from "react";
import styled from "styled-components";

const ClockContainer = styled.div`
  z-index: 0;
  position: relative;
  width: 18.6rem;
  height: 18.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(images/clock.png);
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 -35px 35px rgba(255, 255, 255, 0.15),
    inset 0 -15px 15px rgba(255, 255, 255, 0.15),
    0 35px 35px rgba(0, 0, 0, 0.15), inset 0 15px 15px rgba(0, 0, 0, 0.15);

  &:before {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background: #fff;
    border-radius: 50%;
    z-index: 960;
    @media (max-width: 767px) {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 767px) {
    width: 12.5rem;
    height: 12.5rem;
    box-shadow: 0 -20px 20px rgba(255, 255, 255, 0.15),
      inset 0 -10px 10px rgba(255, 255, 255, 0.15),
      0 20px 20px rgba(0, 0, 0, 0.15), inset 0 10px 10px rgba(0, 0, 0, 0.15);
  }
`;

const Hour = styled.div`
  width: 16.25rem;
  height: 9.375rem;
  position: absolute;
  @media (max-width: 767px) {
    width: 16.25rem;
    height: 5.938rem;
  }
`;

const Hr = styled.div`
  width: 16.25rem;
  height: 9.375rem;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  z-index: 10;
  border-radius: 6px 6px 0 0;
  @media (max-width: 767px) {
    width: 16.25rem;
    height: 5.938rem;
  }
  &:before {
    content: "";
    position: absolute;
    width: 12px;
    height: 70px;
    background: #ff105e;
    z-index: 10;
    border-radius: 6px 6px 0 0;
    @media (max-width: 767px) {
      width: 8px;
      height: 50px;
    }
  }
`;

const Minute = styled.div`
  width: 250px;
  height: 190px;
  position: absolute;
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    width: 250px;
    height: 130px;
  }
`;

const Mn = styled.div`
  display: flex;
  justify-content: center;
  width: 250px;
  height: 190px;
  position: absolute;
  @media (max-width: 767px) {
    width: 250px;
    height: 130px;
  }
  &:before {
    display: block;
    position: absolute;
    content: "";
    width: 4px;
    height: 100px;
    z-index: 11;
    border-radius: 6px 6px 0 0;
    @media (max-width: 767px) {
      width: 3px;
      height: 60px;
    }
  }
`;

const Second = styled.div`
  width: 330px;
  height: 205px;
  position: absolute;
  @media (max-width: 767px) {
    height: 140px;
  }
`;

const Sc = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  width: 330px;
  height: 205px;
  @media (max-width: 767px) {
    height: 140px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 130px;
    background: #fff;
    z-index: 12;
    border-radius: 6px 6px 0 0;
    @media (max-width: 767px) {
      height: 85px;
    }
  }
`;

const Hand = styled.div`
  position: absolute;
  border-radius: 50%;
  &:before {
    content: "";
    position: absolute;
    z-index: ${(props) => props.zindex};
    background: ${(props) => props.color};
    border-radius: ${(props) => props.radius};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
  }
`;

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const deg = 6;
  const hours = time.getHours() * 30;
  const minutes = time.getMinutes() * deg;
  const seconds = time.getSeconds() * deg;

  return (
    <ClockContainer className="mx-auto">
      <Hour>
        <Hr style={{ transform: `rotateZ(${hours + minutes / 12}deg)` }}>
          <Hand />
        </Hr>
      </Hour>
      <Minute>
        <Mn style={{ transform: `rotateZ(${minutes}deg)` }}>
          <Hand
            color="#fff"
            radius="6px 6px 0 0"
            width="4px"
            height="100px"
            zindex={11}
          />
        </Mn>
      </Minute>
      <Second>
        <Sc style={{ transform: `rotateZ(${seconds}deg)` }}>
          <Hand
            color="#fff"
            radius="6px 6px 0 0"
            width="2px"
            height="130px"
            zindex={12}
          />
        </Sc>
      </Second>
    </ClockContainer>
  );
};

export default Clock;
