import React from "react";
import styled from "styled-components";
import SectionBackground from "../../components/Sections/Background.js";
import HeroWavesComponent from "../../components/Sections/WaveAnimate.js";
import NeramFooter from "../../components/Footer/footer.js";
import RegistrationSection from "../../components/ApplicationCard/Children/Contact/RegistrationSection.js";
import ResponsiveAppBar from "../../components/TopNavigationBar/AppBar/AppBarMui.js";
import CityDesign from "../../components/ApplicationCard/Children/Contact/Citys/CityDesign.js";

const ContactPage = () => {
  return (
    <div style={{ background: "var(--lightpink)" }}>
      <ResponsiveAppBar
        variant="transparent"
        showBackButton={true}
        title="Contact Us"
      />
      <div>
        <SectionBackground type="grad" height={"auto"}>
          <RegistrationSection />
          <HeroWavesComponent bgcolor={"var(--lightpink)"} />
        </SectionBackground>
        <div className="container my-5" style={{}}>
          <CityDesign />
        </div>
      </div>
      <div className="position-relative">
        <HeroWavesComponent position="top" bgcolor={"#fff"} />
        <NeramFooter></NeramFooter>
      </div>
    </div>
  );
};

export default ContactPage;
