import React from "react";
import { HomePage } from "./Pages/Home/Home.js";
import ApplicationPage from "./Pages/ApplicationFresh/ApplicationPage.js";
import CutoffPage from "./Pages/CutoffCalc/Cutoff.js";
import ContactPage from "./Pages/ContactUs/ContactPage.js";
import FaqsPage from "./Pages/Faqs/FaqSection.js";
import PremiumBooks from "./Pages/Books/PremiumBooks.js";
import FreeBooks from "./Pages/Books/FreeBooks.js";
import NotFound from "./Pages/Error/Notfound.js";
import JeeSyllabus from "./Pages/JEE_Syllabus/JeeSyllabus.js";
import Askseniors from "./Pages/AskSeniors/Askseniors.js";
import Select from "./Pages/AskSeniors/Select.js";
import SelectCol from "./Pages/AskSeniors/Select.js";

const routes = [
  { path: "/", element: <HomePage /> },
  { path: "/NATA_Application_Form_2025", element: <ApplicationPage /> },
  { path: "/Application-form-Nata-Coaching", element: <ApplicationPage /> },
  { path: "/nata-cutoff-calculator", element: <CutoffPage /> },
  { path: "/NATA_Coaching_center_near_me_address", element: <ContactPage /> },
  { path: "/NATA-coaching-centers-nearby", element: <ContactPage /> },
  { path: "/faqs", element: <FaqsPage /> },
  { path: "/Premium_Books", element: <PremiumBooks /> },
  { path: "/NATA_Free_Books", element: <FreeBooks /> },
  {
    path: "/Free-Nata-Class-books-online-registration",
    element: <FreeBooks />,
  },
  {
    path: "/JEE_B.arch_Syllabus",
    element: <JeeSyllabus />,
  },
  {
    path: "/NATA_Best_Architecture_Colleges",
    element: <Askseniors />,
  },
  {
    path: "/select",
    element: <SelectCol />,
  },
  // 404
  { path: "*", element: <NotFound /> },
];

export default routes;
