import { RedOrangeText } from "../Askseniors";
import GetRegisteredBtn from "./GetRegistered";

const BaseChatImg = () => {
  return (
    <div className="d-flex flex-column align-items-center my-5">
      <img
        src="/images/asksenior/NATA_Top_Score_Mark_2024.png"
        style={{ maxWidth: "30rem" }}
      />
      <RedOrangeText>1000+ Served so far</RedOrangeText>
      <img
        src="/images/asksenior/NATA_Top_Score_Mark_2023.png"
        style={{ maxWidth: "30rem" }}
      />
      <br />
      <GetRegisteredBtn content={"Get Registered"} />
    </div>
  );
};

export default BaseChatImg;
