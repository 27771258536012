import React from "react";
import styled from "styled-components";
import CalculatorBody from "../../components/ApplicationCard/Children/Calculator/CalculatorBody";
import HeroWavesComponent from "../../components/Sections/WaveAnimate";
import NeramFooter from "../../components/Footer/footer";
import ResponsiveAppBar from "../../components/TopNavigationBar/AppBar/AppBarMui";

const PageBackground = styled.div`
  background-color: var(--lightpink);
  position: realtive;
`;

const CutoffPage = () => {
  return (
    <>
      <PageBackground>
        <ResponsiveAppBar
          variant="opaque"
          showBreadCrumBar={true}
          showBackButton={true}
          largerScreenTitle="NATA Cutoff calculator"
          smallerScreenTitle="Cutoff calculator"
          breadcrumbName={"NATA Cutoff Calculator"}
        />

        <CalculatorBody />
        <div className="position-relative">
          <HeroWavesComponent position="top" bgcolor={"var(--lightpink)"} />
          <NeramFooter />
        </div>
      </PageBackground>
    </>
  );
};

export default CutoffPage;
