import React from "react";
import styled from "styled-components";
import MyButton, {
  HilightBtn,
} from "../../../components/Basic components/Buttons/Buttons";

import IconButton from "@mui/material/IconButton";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

const ClockText = styled.h1`
  font-family: "Handlee", cursive;
  font-size: 24px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
  /* Media query for maximum width of 992px */
  @media (max-width: 992px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
    text-align: center;
    letter-spacing: 1px;
    padding: 0 45px;
  }
`;

const ClockSubText = styled.h2`
  font-family: Handlee, cursive;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
  font-size: 17px;
  text-align: justify;
  line-height: 27px;
  /* Media query for maximum width of 992px */
  @media (max-width: 992px) {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 30px;
    text-align: center;
    padding: 0 45px;
  }
`;

const NormalText = styled.span`
  color: #fff;
  border-bottom: 4px solid #ff105e;
  font-family: Poppins, sans-serif;
  letter-spacing: 0;
`;

const whiteIconButtonStyle = {
  color: "white",
};

const HeroText = () => {
  return (
    <div className="col-12 col-lg-7 col-md-6 col-xl-6 my-auto offset-lg-0 order-2 text-start">
      <ClockText className=" mt-2 mt-sm-0">
        Why neram Classes for <NormalText>NATA Coaching</NormalText> ?{" "}
      </ClockText>
      <br></br>
      <div
        id="Social"
        className="d-flex justify-content-center justify-content-lg-start"
      >
        <span className="d-flex align-items-center text-light">
          Follow Us :
        </span>
        <Tooltip title="Youtube Channel">
          <IconButton
            href="https://www.youtube.com/@neramclassesnata"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "var(--yellow)" }}
            variant="text"
          >
            <YouTubeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Official Instagram Handle">
          <IconButton
            href="https://www.instagram.com/neramclassrooms/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "var(--yellow)" }}
          >
            <InstagramIcon />
          </IconButton>
        </Tooltip>
      </div>

      <ClockSubText>
        We are team of talented practicing architects from IITs &amp; NITs
        started this for betterment of this Architecture profession
      </ClockSubText>
      <div className="text-center text-lg-start">
        <Tooltip title="B.Arch Exam Cutoff calculation">
          <Link to="/nata-cutoff-calculator" className="me-2">
            <HilightBtn variant="contained">Calculator</HilightBtn>
          </Link>
        </Tooltip>
        <Tooltip title="NATA Students Meetup Registration Form">
          <Link to="/NATA_Best_Architecture_Colleges">
            <MyButton variant="contained">#AskSeniors</MyButton>
          </Link>
        </Tooltip>
      </div>
    </div>
  );
};

export default HeroText;
