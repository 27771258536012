import Clock from "./Clock";
import HeroText from "./HeroText";
import styled from "styled-components";

// Clock Wrapper

const ClockWrapper = styled.div`
  @media (min-width: 768px) {
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
`;

function Hero() {
  return (
    <div className="container my-5">
      <div className="row my-5 py-md-5">
        <ClockWrapper className="col-md-6 col-lg-5 col-xl-4 offset-xl-1 order-1 mb-4 mb-md-0">
          <Clock />
        </ClockWrapper>
        <HeroText className="col-12 col-lg-7 col-md-6 col-xl-6 my-auto offset-lg-0 order-2" />
      </div>
    </div>
  );
}

export default Hero;
