import "bootstrap/dist/css/bootstrap.min.css";
import SectionBackground from "../../components/Sections/Background.js";
import { SectionTitle } from "../../components/Sections/Heading.js";
import CardContentIteration from "../../components/Basic components/Card.js";
import Minifaqs from "../Home/FAQs/minifaqs.js";
import Advantages from "../Home/Advantages/advantage.js";
import NeramFooter from "../../components/Footer/footer.js";
import TopperCarousel from "./Toppers/Toppers.js";
import AchieveLogos from "./Achievements/achieve.js";
import ParentsCarousel from "./Parents/Parents.js";
import Hero from "./Hero/Hero.js";
import HeroWavesComponent from "../../components/Sections/WaveAnimate.js";
import Wave1, {
  Wave10,
  Wave2,
  Wave7,
  Wave8,
  Wave9,
} from "../../assets/SVG/WaveSvg.js";
import FaqSvg from "../../assets/SVG/FaqSvg.js";
import AudioCarousel from "./AudioReviews/AudioReview.js";
import WhatsappCarousel from "./Whatsapp_reviews/Wpreview.js";
import TeamCardIte from "./Team/Team.js";
import ResponsiveAppBar from "../../components/TopNavigationBar/AppBar/AppBarMui.js";
import BackToTopButton from "../../components/Basic components/BackToTop.js";
import MyButton from "../../components/Basic components/Buttons/Buttons.js";
import YouTubeVideosGrid from "./Achievements/YoutubeCarousel/YtCarousel.js";
import { Box } from "@mui/material";

export const HomePage = () => {
  return (
    <div className="App">
      {/* <NeramAppBar scrollable={true} /> */}
      <BackToTopButton />
      <ResponsiveAppBar variant="transparent" />
      <>
        <SectionBackground type="grad" height={"auto"}>
          <Box sx={{ height: "100%", display: "grid", placeItems: "center" }}>
            <Hero />
          </Box>
          <HeroWavesComponent bgcolor={"var(--lightpink)"} />
        </SectionBackground>

        <SectionBackground
          type="pink"
          height={"auto"}
          paddingBottomLarge={"4rem"}
          paddingBottomMedium={"7rem"}
        >
          <div className="pt-5">
            <SectionTitle
              title="JEE / NATA Toppers"
              subtitle="They Climbed to success & We continue to be Ladders"
            />
          </div>
          <div>
            <TopperCarousel />
          </div>
        </SectionBackground>

        <Wave7 />
        <SectionBackground color={"#fff"} height={"auto"}>
          <div className="py-4 py-lg-0 pb-lg-5">
            <SectionTitle
              title="Our Students Loves Us"
              subtitle="Alumnus & Achievements"
            />
          </div>
          <AchieveLogos />
        </SectionBackground>
        <Wave1 />

        <SectionBackground
          type={"pink"}
          height={"auto"}
          paddingBottomLarge={"0"}
        >
          <div className="pt-5 pt-lg-3">
            <SectionTitle
              title="Also Parents does"
              subtitle="Alumnus & Achievements"
            />
          </div>
          <div className="pb-5 pb-lg-0">
            <ParentsCarousel />
          </div>
          {/* <div className="pb-2 pb-lg-0">
            <MyButton variant="contained">More</MyButton>
          </div> */}
        </SectionBackground>
        <Wave2 />
        <SectionBackground height={"auto"} color={"#fff"}>
          <div className="mt-2">
            <SectionTitle
              title="Our content quality speaks"
              subtitle="Follow us on youtube and IG"
            />
          </div>
          <YouTubeVideosGrid />
          <div className="my-5">
            <MyButton
              variant="contained"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/@neramclassesnata",
                  "_blank"
                )
              }
            >
              View Our YouTube Channel
            </MyButton>
          </div>
        </SectionBackground>
        <SectionBackground
          type="grad"
          height={"auto"}
          paddingBottomMedium={"0"}
        >
          <Wave8 />
          <SectionTitle
            titleColor={"var(--yellow)"}
            subTitleColor={"#fff"}
            title="Course Details"
            subtitle="Year Long | Crash course"
          />
          <Box sx={{ height: "100%", display: "grid", placeItems: "center" }}>
            <CardContentIteration />
          </Box>

          <Wave9 />
        </SectionBackground>

        <SectionBackground type="pink" height={"auto"}>
          <SectionTitle
            title="Alumnus Review about NATA Coaching in Neram"
            subtitle="Calls From Happy Students/Parents"
          />
          <AudioCarousel />
          <br />
          <hr className="my-5" />
          <SectionTitle
            title="Whatsapp image"
            subtitle="Calls From Happy Students/Parents"
          />
          <WhatsappCarousel />
          <br />
          <br />
        </SectionBackground>
        <SectionBackground type="grad" height={"auto"}>
          <FaqSvg />
          <SectionTitle
            title="Faqs "
            subtitle="Frequently asked questions about NATA"
            titleColor={"#fff"}
            subTitleColor={"var(--yellow)"}
          />
          <Minifaqs />
          <Wave9 />
        </SectionBackground>
        <SectionBackground type={"pink"} height={"auto"}>
          <SectionTitle
            title="Introducing our Team"
            subtitle="Meet the people who Teach Awesome stuffs"
          />
          <TeamCardIte />
          <Wave10 />
        </SectionBackground>
        <SectionBackground color={"#fff"} height={"auto"}>
          <SectionTitle title="Advantages" subtitle="Alumnus & Achievements" />
          <Advantages />
        </SectionBackground>
        <div className="position-relative">
          <HeroWavesComponent position="top" bgcolor={"#fff"} />

          <NeramFooter />
        </div>
      </>
    </div>
  );
};

export default HomePage;
