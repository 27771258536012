import React from "react";
import styled from "styled-components";
import ResponsiveAppBar from "../../components/TopNavigationBar/AppBar/AppBarMui";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Home, QuestionAnswer, Link } from "@mui/icons-material";
import Bookslider from "./BookComp/Bookslider";
import QuestionAccord from "./BookComp/QuestionPaper";

const BodyContainer = styled.div`
  background-image: url(../img/SVG/Asset6.svg),
    linear-gradient(
      295deg,
      #2b2d4e 1.557291666666667%,
      #e1148b 101.34895833333333%
    );
  background-image: url(../images/SVG/Asset6.svg),
    -moz-linear-gradient(295deg, #2b2d4e 1.557291666666667%, #e1148b
          101.34895833333333%);
  background-image: url(../images/SVG/Asset6.svg),
    -webkit-linear-gradient(295deg, #2b2d4e 1.557291666666667%, #e1148b
          101.34895833333333%);
  background-blend-mode: multiply;
  background-repeat: repeat;
  min-height: 100vh;
  height: ${(props) =>
    props.value === 0 ? "100vh" : "100%"}; /* Conditional height */
  color: white;
  background-size: cover;
  display: flex;
  flex-direction: column;
  .margintop {
    margin-top: 7rem;
  }
  .bottom-navigation {
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    z-index: 1000; /* Ensure it's above other content */
  }
`;

const BotmNav = styled(BottomNavigation)`
  background-color: var(--yellow) !important;
  width: 25rem;
  border-radius: 10px 10px 0 0;
  @media (max-width: 768px) {
    width: 100%;
    border-radius: 0;
  }
`;

const FreeBooks = () => {
  const [value, setValue] = React.useState(0);
  const [showBookslider, setShowBookslider] = React.useState(true); // State to control visibility of Bookslider
  const [showQuestionAccord, setShowQuestionAccord] = React.useState(true); // State to control visibility of Bookslider

  return (
    <BodyContainer value={value}>
      <ResponsiveAppBar
        variant="transparent"
        showBackButton={true}
        title="Free NATA Syllabus Books & study materials"
      />
      <div
        className={`h-100 d-flex justify-content-center ${
          value !== 1 && value !== 2 ? "align-items-center" : "margintop"
        } `}
      >
        {/* Conditionally render Bookslider based on the selected tab */}
        {value === 0 && showBookslider && <Bookslider variant="free" />}
        {value === 1 && showQuestionAccord && <QuestionAccord type="free" />}
        {/* Add additional conditions here if needed for other tabs */}
      </div>

      <div className="bottom-navigation">
        <BotmNav
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            // Toggle showBookslider based on the selected tab
            if (newValue === 0) {
              setShowBookslider(true); // Show Bookslider for Recents tab
              setShowQuestionAccord(false); // Hide QuestionAccord
            } else if (newValue === 1) {
              setShowBookslider(false); // Hide Bookslider for Q.Bank tab
              setShowQuestionAccord(true); // Show QuestionAccord
            }
          }}
        >
          <BottomNavigationAction label="Books" icon={<Home />} />
          <BottomNavigationAction label="Q.Bank" icon={<QuestionAnswer />} />
          <BottomNavigationAction label="Imp.Links" icon={<Link />} disabled />
        </BotmNav>
      </div>
    </BodyContainer>
  );
};

export default FreeBooks;
