import React from "react";
import { SolidButton } from "./Button";
import styled from "styled-components";

import { Link } from "react-router-dom";

const CardText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--black900);
  width: 90%;
  text-align: center;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkCourse = styled.div`
  text-decoration: underline;
`;

const MobileCard = styled.div`
  position: relative;
  max-width: 275px;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.14), -8px 10px 17px 0 rgba(0, 0, 0, 0.38);
  border-radius: 10px;
  margin: 100px auto 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  @media (max-width: 992px) {
    width: 100%;
  }
`;

const CardBody = styled.div`
  padding: 20px;
  margin-top: 70px;
`;

const CardImage = styled.img`
  width: auto;
  height: 173px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: ${(props) => (props.index === 1 ? "-138px" : "-104px")};
  transform: ${(props) => (props.index === 1 ? "scale(0.75)" : "none")};
`;

const NERAMLiveLogo = styled.img`
  width: 130px;
  position: relative;
  height: auto;
  bottom: 28px;

  @media (min-width: 768px) {
    width: 125px;
  }
`;

const MobileCardFooter = styled.div`
  border-radius: 0 0 10px 10px;
  padding: 3px 20px;
  height: 35px;
  background-image: ${(props) =>
    props.buttoncolor}; // Use buttoncolor instead of buttonColor
  margin-top: auto;
  color: white;
  font-weight: 600;
`;

const CardContent = [
  {
    description: "NATA / JEE 2 Year long Online/Offline Coaching",
    link: "images/card/play-learn-final.webp",
    knowmore: "Course Details",
    buttontext: "Join Class",
    footertext: "Sample",
    buttoncolor: "var(--gradyellowgreen)",
  },
  {
    description: "NATA / JEE 2 Crash coaching both Online/Offline",
    link: "images/card/live-nata-online.webp",
    knowmore: "Course Details",
    buttontext: "Join Class",
    footertext: "Sample2",
    buttoncolor: "var(--gradblueviolet)",
  },
  {
    description: "NATA / JEE 2 LongTerm coaching both Online/Offline",
    link: "images/card/nata-study-materials.webp",
    knowmore: "Course Details",
    buttontext: "Join Class",
    footertext: "Sample3",
    buttoncolor: "var(--gradpinkred)",
  },
];

// iterating array

function CardContentIteration() {
  return (
    <div className="container my-5">
      <div className="row justify-content-around">
        {CardContent.map((item, index) => (
          <div key={index} className="col-12 col-lg-3 mt-5 mt-lg-0">
            <CardTypeOne
              imgLink={item.link}
              cardDescription={item.description}
              moreDetails={item.knowmore}
              cardButtonText={item.buttontext}
              cardFooterText={item.footertext}
              buttoncolor={item.buttoncolor}
              footerColor={item.buttoncolor}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

// Parent component
function CardTypeOne(props) {
  const {
    imgLink,
    cardDescription,
    moreDetails,
    cardButtonText,
    cardFooterText,
    buttoncolor,
    footerColor,
    index,
  } = props;

  return (
    <MobileCard>
      <CardImage src={imgLink} index={index}></CardImage>{" "}
      {/* Pass index prop to CardImage */}
      <CardBody>
        {index === 1 ? (
          <NERAMLiveLogo src="/images/logos/NERAM-LIVE.svg" alt="NERAM LIVE" />
        ) : null}
        <CardText>{cardDescription}</CardText>

        <Link to="/NATA_Application_Form_2025">
          <SolidButton
            className="py-1 px-5"
            rounded="partial"
            color={buttoncolor}
          >
            {cardButtonText}
          </SolidButton>
        </Link>
        <LinkCourse to="/NATA_Application_Form_2025" className="my-4">
          {moreDetails}
        </LinkCourse>
      </CardBody>
      <MobileCardFooter buttoncolor={footerColor}>
        {cardFooterText}
      </MobileCardFooter>
    </MobileCard>
  );
}

export default CardContentIteration;
