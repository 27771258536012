import * as React from "react";
import { Box, Button, TextField, Typography, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { ref, push } from "firebase/database";
import { database } from "../../../components/Firebase/setup";
import {
  getCurrentDate,
  getCurrentTime,
} from "../../../components/Basic components/DateTime";
import axios from "axios";
import styled from "styled-components";
import PopupHeader from "../CardComponent/PopupHeader";
import SelectCol from "../Select";

const FormModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 24;
  .MuiFormControl-root {
    margin: 0.5rem 0;
  }

  @media (max-width: 768px) {
    width: 80%;
  }
  @media (max-width: 576px) {
    width: 98%;
  }
`;

const GetRegisteredBtn = ({ content }) => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    city: "",
    email: "",
    phoneNumber: "",
    boardScore: "",
    boardTotal: "",
    nataScore1: "",
    nataScore2: "",
    nataScore3: "",
    selectedColleges: [],
    date: getCurrentDate(),
    time: getCurrentTime(),
    locationName: "",
  });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState(1);

  const handleOpen = () => {
    setOpen(true);
    setSuccess(false);
  };
  const handleClose = () => {
    setOpen(false);
    setStep(1);
    setFormData({
      name: "",
      city: "",
      email: "",
      phoneNumber: "",
      boardScore: "",
      boardTotal: "",
      nataScore1: "",
      nataScore2: "",
      nataScore3: "",
      selectedColleges: [],
      date: getCurrentDate(),
      time: getCurrentTime(),
      locationName: "",
    });
    setErrors({});
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getLocationName(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const getLocationName = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json`,
        {
          params: {
            q: `${latitude}+${longitude}`,
            key: "0f2ea0cee8b64644a6548b43a25256fc",
          },
        }
      );
      const location = response.data.results[0].formatted;
      setFormData((prevData) => ({
        ...prevData,
        locationName: location,
      }));
    } catch (error) {
      console.error("Error fetching location name:", error);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleTransferListChange = (selectedColleges) => {
    setFormData({
      ...formData,
      selectedColleges,
    });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "Name is required.";
    tempErrors.phoneNumber = formData.phoneNumber
      ? ""
      : "Phone number is required.";
    tempErrors.email = formData.email ? "" : "Email is required.";
    tempErrors.city = formData.city ? "" : "City is required.";
    setErrors({ ...tempErrors });
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (step === 1) {
        setStep(2);
      } else if (step === 2) {
        const formDataRef = ref(database, "askEvent");
        push(formDataRef, formData)
          .then(() => {
            setSuccess(true);
            setStep(3);
          })
          .catch((error) => {
            console.error("Error storing form data:", error);
          });
      }
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const renderStep1 = () => (
    <form onSubmit={handleSubmit} className="p-4 p-md-5">
      <div className="d-flex gap-2">
        <TextField
          margin="normal"
          size="small"
          fullWidth
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          size="small"
          margin="normal"
          fullWidth
          label="City"
          name="city"
          value={formData.city}
          onChange={handleInputChange}
          error={!!errors.city}
          helperText={errors.city}
        />
      </div>
      <TextField
        size="small"
        margin="normal"
        fullWidth
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        error={!!errors.email}
        helperText={errors.email}
      />
      <TextField
        size="small"
        margin="normal"
        fullWidth
        label="Phone Number"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleInputChange}
        error={!!errors.phoneNumber}
        helperText={errors.phoneNumber}
      />

      <div className="d-sm-flex align-items-center gap-2">
        <label htmlFor="" style={{ whiteSpace: "nowrap" }}>
          Board/Diploma Mark
        </label>
        <div className="d-flex align-items-center gap-2">
          <TextField
            size="small"
            margin="normal"
            label="Scored"
            name="boardScore"
            value={formData.boardScore}
            onChange={handleInputChange}
            error={!!errors.boardScore}
            helperText={errors.boardScore}
            style={{ flex: 1 }}
          />
          <label
            htmlFor=""
            style={{
              whiteSpace: "nowrap",
            }}
          >
            Out of
          </label>
          <TextField
            size="small"
            margin="normal"
            label="Total"
            name="boardTotal"
            value={formData.boardTotal}
            onChange={handleInputChange}
            error={!!errors.boardTotal}
            helperText={errors.boardTotal}
            style={{ flex: 1 }}
          />
        </div>
      </div>

      <div className="d-sm-flex align-items-center gap-2">
        <label htmlFor="" style={{ whiteSpace: "nowrap" }}>
          NATA Score
        </label>
        <div className="d-flex align-items-center gap-2">
          <TextField
            size="small"
            margin="normal"
            label="1st Exam"
            name="nataScore1"
            value={formData.nataScore1}
            onChange={handleInputChange}
            error={!!errors.nataScore1}
            helperText={errors.nataScore1}
            style={{ flex: 1 }}
          />

          <TextField
            size="small"
            margin="normal"
            label="2nd Exam"
            name="nataScore2"
            value={formData.nataScore2}
            onChange={handleInputChange}
            error={!!errors.nataScore2}
            helperText={errors.nataScore2}
            style={{ flex: 1 }}
          />
          <TextField
            size="small"
            margin="normal"
            label="3rd Exam"
            name="nataScore3"
            value={formData.nataScore3}
            onChange={handleInputChange}
            error={!!errors.nataScore3}
            helperText={errors.nataScore3}
            style={{ flex: 1 }}
          />
        </div>
      </div>

      <br />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
        size="large"
      >
        Next
      </Button>
      <Typography
        variant="caption"
        sx={{
          fontStyle: "italic",
          color: "var(--violet)",
        }}
      >
        * Details will be used to give <b>Free Tools & Fee concession</b>{" "}
        Coupons
      </Typography>
    </form>
  );

  const colleges = [
    "Anna university",
    "NIT Trichy",
    "NIT Nagpur",
    "PAPNI Chennai",
    "Periyar maniammai Thanjavur",
    "Dhanalakshmi Srinivasan University - Trichy",
    "Christ Bangalore",
    "VIT Vellore",
    "Thiyagarajar College, Mdu",
    "CEPT Ahmedabad",
    "SPA Delhi",
    "McGans Ooty",
    "MEASI Chennai",
    "PSG Cbe",
    "SRM Chennai",
    "Karpagam University, Cbe",
    "Adhiyamaan Hosur",
    "CARE Trichy",
    "Crescent Chennai",
  ];

  const renderStep2 = () => (
    <form onSubmit={handleSubmit}>
      <SelectCol
        content={colleges}
        onSelectionChange={handleTransferListChange}
      />
      <br />
      <div className="d-flex justify-content-center align-items-center gap-2 my-4">
        <Button
          variant="outlined"
          size="large"
          sx={{ width: "6rem" }}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          sx={{ width: "6rem" }}
        >
          Submit
        </Button>
      </div>
    </form>
  );

  const renderStep3 = () => (
    <Box className="p-4">
      <Box sx={{ height: "5rem" }}>
        <Typography variant="h6" component="h2">
          Thank you for registering!
        </Typography>
        <Typography variant="subtitle1">
          We will share event details soon in your registered email ID and Phone
          number
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleClose}
        className="my-3"
      >
        Close
      </Button>
    </Box>
  );

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        {content}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FormModal>
          <PopupHeader
            text={"Event registration form"}
            handleClose={handleClose}
          />
          <div className="popupbody">
            {!success ? (
              <>
                {step === 1 && renderStep1()}
                {step === 2 && renderStep2()}
              </>
            ) : (
              renderStep3()
            )}
          </div>
        </FormModal>
      </Modal>
    </>
  );
};

export default GetRegisteredBtn;
