import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../components/Basic components/CarouselArrow";

import Modal from "@mui/material/Modal";
import { useState } from "react";

// POPUP CSS

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ImageModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  border-radius: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: aliceblue;
`;

//  REGULAR CSS

const CustomSlider = styled(Slider)`
  /* Add any styles for the slider here */
`;

const ImgCss = styled.img`
  border-radius: 10px;
  background: linear-gradient(145deg, #e6dee6, #ffffff);
  box-shadow: 2px 2px 5px #666366, 2px 2px 5px #ffffff;
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
`;

const WhatsappReview = [
  {
    imageLink: "images/img/whatsapp/boojashri-2.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/1.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/annline.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/boojashri.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/boojashri-3.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/Gopika.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/iyline.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/north.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/north-2.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/north-3.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/2021/afz.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/2021/Arulmathi.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/2021/Aysha.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/2021/dhakshinya.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/2021/jeni.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/2021/new.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/2021/reshmi.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/2021/sivaram.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/2021/unk.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/2021/unk2.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
  {
    imageLink: "images/img/whatsapp/2021/unko.webp", // Add your image link here
    alt: "NATA topper tamilnadu",
    loading: "lazy",
  },
];

const OneItemContainer = styled.div`
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3rem;
  cursor: pointer;
`;

const OneItem = ({ imgLink, alt }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <OneItemContainer onClick={handleOpenModal}>
        <ImgCss
          alt={alt}
          src={imgLink}
          loading="lazy"
          width="100%"
          style={{ objectFit: "cover", height: "100%" }}
        />
      </OneItemContainer>
      <ImageModal
        isOpen={openModal}
        handleClose={handleCloseModal}
        imageUrl={imgLink}
        imageAlt={alt}
      />
    </>
  );
};

const ImageModal = ({ isOpen, handleClose, imageUrl, imageAlt }) => {
  const handleCloseModal = () => {
    handleClose();
  };

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleCloseModal}
      aria-labelledby="image-modal-title"
      aria-describedby="image-modal-description"
    >
      <ImageModalContent onClick={handleOverlayClick}>
        <CloseButton onClick={handleCloseModal}>Close</CloseButton>
        <ImgCss src={imageUrl} alt={imageAlt} />
      </ImageModalContent>
    </Modal>
  );
};

function WhatsappCarousel() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    mobileFirst: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className="top-50 end-0 w-auto" />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container container py-5">
      <CustomSlider {...settings}>
        {WhatsappReview.map((item, index) => (
          <div key={index}>
            <OneItem imgLink={item.imageLink} alt={item.alt} />
          </div>
        ))}
      </CustomSlider>
    </div>
  );
}

export default WhatsappCarousel;
