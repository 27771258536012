import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import QuestionCard, { NATAQuestionCard } from "./QuestionCard";
import styled from "@emotion/styled/macro";
import MyButton from "../../../components/Basic components/Buttons/Buttons";
import { Link } from "react-router-dom";

const TransparentAccordion = styled(Accordion)`
  background-color: transparent; // Set the background color to transparent
  color: white;
  .Mui-expanded svg:first-child,
  .MuiAccordionSummary-expandIconWrapper {
    color: white;
  }
`;

export default function QuestionAccord({ type }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Container>
      <TransparentAccordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            1. JEE Question Papers
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <QuestionCard variant={"light"} type={type} />
        </AccordionDetails>
      </TransparentAccordion>
      <TransparentAccordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            2. NATA Question Papers
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <NATAQuestionCard variant={"light"} type={type} />
        </AccordionDetails>
      </TransparentAccordion>
      <TransparentAccordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        className={type === "free" ? "d-block" : "d-none"}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            3. Important Links
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Go Premium to receive access to our NATA Self study resources
            <Link
              to="/NATA_Application_Form_2025"
              style={{
                display: "flex",
                justifyContent: "center",
                textDecoration: "none",
                padding: "2rem 0",
                backgroundColor: "var(--lightpink)",
              }}
            >
              <MyButton variant="contained">Get access</MyButton>
            </Link>
          </Typography>
        </AccordionDetails>
      </TransparentAccordion>
    </Container>
  );
}
