import { styled } from "@mui/system";
import { Button } from "@mui/material";
import { pink } from "@mui/material/colors";
import { keyframes } from "@mui/material";

const CustomButton = styled(Button)`
  color: ${(props) => (props.variant === "contained" ? "white" : "white")};
  border: ${(props) =>
    props.variant === "outlined" ? "2px solid white" : "none"};
  border-radius: 40px;
  text-transform: none;
  background-color: ${(props) =>
    props.variant === "contained" ? pink["A400"] : "transparent"};
  &:hover {
    border: ${(props) =>
      props.variant === "outlined"
        ? "2px solid white"
        : "2px solid pink[A400]"};
    color: ${(props) =>
      props.variant === "outlined" ? pink["A400"] : pink["A400"]};
    background-color: ${(props) =>
      props.variant === "outlined" ? "white" : "white"};
  }
`;

export const GradientButton = ({ fullWidth, children }) => {
  return (
    <Button
      variant="contained"
      sx={{
        background: "linear-gradient(to right, #FFCE31, #FF3D00)",
        color: "white",
        "&:hover": {
          background: "linear-gradient(to right, #FFCE31, #FF3D00)", // Adjust hover background if needed
        },
        width: fullWidth ? "100%" : "auto", // Set width based on fullWidth prop
      }}
    >
      {children}
    </Button>
  );
};

const MyButton = ({ children, variant, onClick }) => {
  return (
    <CustomButton variant={variant} size="large" onClick={onClick}>
      {children}
    </CustomButton>
  );
};

// Define keyframes for the animation
export const shinyBtnAnimation = keyframes`
  0% {
    transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    transform: scale(50) rotate(45deg);
    opacity: 0;
  }
`;

// Styled component for HighlightButton
const HighlightButton = styled(Button)`
  text-transform: none;
  border-radius: 40px;
  background: linear-gradient(103deg, rgb(251 33 134) 0%, rgb(76 234 139) 100%);
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.7;
  }

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background: #fff;
    animation: ${shinyBtnAnimation} 3s ease-in-out infinite; /* Apply the animation here */
  }

  &:active {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
      -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
      inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
      inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const HilightBtn = ({ children, variant }) => {
  return (
    <HighlightButton variant={variant} size="large">
      {children}
    </HighlightButton>
  );
};

export default MyButton;
