import React from "react";
// Slick
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Styled
import styled from "styled-components";
// Basic components
import NeramBadges from "../../../components/Basic components/Badges";
import ContentBaseTitle from "../../../components/Sections/ContentBaseTitle";
// Carousel arrows
import {
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../components/Basic components/CarouselArrow";

const BadgeWrapper = styled.div`
  position: absolute;
  left: 53%;
  top: 65%;
`;

const CircleBackground = styled.img`
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  width: 200px; /* Specify width */
  height: 200px; /* Specify height */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const PersonImage = styled.img`
  filter: drop-shadow(12px 12px 15px rgba(0, 0, 0, 0.56));
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.49);
  border-radius: 50rem;
  -webkit-tap-highlight-color: transparent;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1),
    border-radius 250ms cubic-bezier(0.4, 0, 0.2, 1),
    top 250ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 400px;
  cursor: pointer;
  &:hover {
    transform: translate(-50%, -50%) scale(0.5);
    top: 40%;
    border-radius: 1rem;
  }
`;

const ClipContainer = styled.div`
  position: relative;
  height: 15rem;
  transform: scale(0.76);
  transform-origin: bottom;
`;

const CustomSlider = styled(Slider)`
  .slick-dots {
    bottom: -50px; /* Add your custom margin */
  }
`;

const TopperList = [
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/muthu.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#1",
    },
    content: {
      primary: "100 Percentile",
      secondary: "2024 AIR 01",
      tertiary_name: "Muthu",
      tertiary_clg: "NIT Trichy",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/chembian-circle.webp",
      alt: "NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Varsha.webp",
      studentimagealt: "NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#1",
    },
    content: {
      primary: "99.98 Percentile",
      secondary: "2022 Topper",
      tertiary_name: "Varsha",
      tertiary_clg: "NIT trichy",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/sameer-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/chembian.webp",
      studentimagealt: "India NATA Exam 2021 top score",
      loading: "lazy",
      badge: "#3",
    },
    content: {
      primary: "99.97 Percentile",
      secondary: "2021 Topper",
      tertiary_name: "Chembian",
      tertiary_clg: "NIT trichy",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Balwin.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#3",
    },
    content: {
      primary: "182/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Balwin",
      tertiary_clg: "Anna University",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Abinav.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#2",
    },
    content: {
      primary: "179/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Abinav",
      tertiary_clg: "Anna University",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Charubala.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#2",
    },
    content: {
      primary: "99.91 Percentile",
      secondary: "2021 Topper",
      tertiary_name: "Charubala",
      tertiary_clg: "NIT Nagpur",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Alan.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#1",
    },
    content: {
      primary: "180/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Alan",
      tertiary_clg: "Anna University",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Thabis.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#3",
    },
    content: {
      primary: "177/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Thabis",
      tertiary_clg: "Anna University",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Vishnu.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#1",
    },
    content: {
      primary: "182/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Vishnu",
      tertiary_clg: "Anna University",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Dhakshiniya.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#2",
    },
    content: {
      primary: "180/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Dakshiniya",
      tertiary_clg: "Measi",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Jagadeesh.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#1",
    },
    content: {
      primary: "99.93 Percentile",
      secondary: "2021 Topper",
      tertiary_name: "Jagadeesh",
      tertiary_clg: "NIT Trichy",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Bharathan.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#3",
    },
    content: {
      primary: "99.95 Percentile",
      secondary: "2021 Topper",
      tertiary_name: "Bharathan",
      tertiary_clg: "NIT Trichy",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Sivaram.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#1",
    },
    content: {
      primary: "183/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Sivaram",
      tertiary_clg: "Anna University",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Arulmathi.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#3",
    },
    content: {
      primary: "177/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Arulmathi",
      tertiary_clg: "Anna University",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Reshmi.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#2",
    },
    content: {
      primary: "170/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Reshmi",
      tertiary_clg: "Anna University",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Afzan.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#3",
    },
    content: {
      primary: "173/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Afzan",
      tertiary_clg: "Cresent",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Gunaa.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#3",
    },
    content: {
      primary: "167/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Gunaa",
      tertiary_clg: "Thiyagaraja",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Sakthi.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#2",
    },
    content: {
      primary: "159/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Sakthi",
      tertiary_clg: "Thiyagaraja",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Karthik.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#3",
    },
    content: {
      primary: "166/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Karthik",
      tertiary_clg: "Thiyagaraja",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Deepika.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#1",
    },
    content: {
      primary: "169/200 NATA Score",
      secondary: "2021 Topper",
      tertiary_name: "Deepika",
      tertiary_clg: "Anna University",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Aylin.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#1",
    },
    content: {
      primary: "99.92 Percentile",
      secondary: "2021 Topper",
      tertiary_name: "Lylin",
      tertiary_clg: "NIT Trichy",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Pavan sanjay.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#2",
    },
    content: {
      primary: "99.91 Percentile",
      secondary: "2021 Topper",
      tertiary_name: "Durgule Pavan sanjay",
      tertiary_clg: "NIT Trichy",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/10.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#2",
    },
    content: {
      primary: "99.96 Percentile",
      secondary: "2021 Topper",
      tertiary_name: "10",
      tertiary_clg: "NIT Trichy",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/jatin.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#1",
    },
    content: {
      primary: "99.95 Percentile",
      secondary: "2024 Topper",
      tertiary_name: "Jatin",
      tertiary_clg: "NIT Trichy",
    },
  },
  {
    circle: {
      circle: "images/img/jee-2021/background/durga-circle.webp",
      alt: "India NATA topper tamilnadu",
      loading: "lazy",
    },
    person: {
      studentimage: "images/img/jee-2021/wep/Krishna.webp",
      studentimagealt: "India NATA Exam 2020 top score",
      loading: "lazy",
      badge: "#1",
    },
    content: {
      primary: "99.94 Percentile",
      secondary: "2024 Topper",
      tertiary_name: "Krishna",
      tertiary_clg: "NIT Trichy",
    },
  },

  // Add more objects as needed
];

// ContentCarousel component
const ContentCarousel = ({ circle, person, content }) => {
  return (
    <div className="d-flex flex-column">
      <ClipContainer className="mb-3">
        <CircleBackground
          src={circle.circle}
          alt={circle.alt}
          loading={circle.loading}
        />
        <PersonImage
          src={person.studentimage}
          alt={person.studentimagealt}
          loading={person.loading}
        />
        <BadgeWrapper>
          <NeramBadges content={person.badge} />
        </BadgeWrapper>
      </ClipContainer>
      <ContentBaseTitle
        primary={content.primary}
        secondary={content.secondary}
        tertiary={
          <span>
            {content.tertiary_name}
            <b className="ms-2">{content.tertiary_clg}</b>
          </span>
        }
      />
    </div>
  );
};

// Define styled component for the next arrow

// carousel

function TopperCarousel() {
  const settings = {
    mobileFirst: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className="top-50 end-0 w-auto" />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container container">
      <CustomSlider {...settings}>
        {TopperList.map((item, index) => (
          <div key={index}>
            <ContentCarousel
              circle={item.circle}
              person={item.person}
              content={item.content}
            />
          </div>
        ))}
      </CustomSlider>
    </div>
  );
}

export default TopperCarousel;
