import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowLeft } from "@mui/icons-material";

const StyledSlider = styled(Slider)`
  width: 100%;
`;

const Slide = styled.div`
  width: 100%;
`;

const Image = styled.img`
  width: 100%;
`;

const VerticalCarousel = ({ slides }) => {
  const containerRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 768); // Adjust breakpoint as needed
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const settings = {
    arrows: false,
    infinite: true,
    slidesToShow: isSmallScreen ? 3 : 4, // Change slidesToShow based on screen size
    slidesToScroll: 1,
    vertical: !isSmallScreen,
    verticalSwiping: !isSmallScreen,
    autoplay: false,
    autoplaySpeed: 3000, // Adjust autoplay speed here
  };

  return (
    <>
      <StyledSlider {...settings}>
        {slides.map((slide, index) => (
          <Slide key={index}>
            <Image src={slide} alt={`slide${index + 1}`} />
          </Slide>
        ))}
      </StyledSlider>
      {/* Add more slides as needed */}
    </>
  );
};

export default VerticalCarousel;
