import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
} from "@mui/material";
import MyButton from "../../../components/Basic components/Buttons/Buttons";

function Minifaqs() {
  return (
    <div className="container mt-5">
      <Accordion>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Typography>
            Which Exam should I learn to become Architect?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            For pursuing architecture course in India, you must have qualified
            in NATA ( National Aptitude Test for Architecture ). All colleges
            which act under state government accept NATA score as eligibility
            for getting admission in state government college. For colleges
            which come under the Central government of India, like NITs and
            IITs, JEE Paper 2 Exam will be conducted. IITs & NITs will not take
            students based on NATA score since NATA is conducted by council of
            Architecture in India while JEE Mains Exam is conducted by MHRD -
            India ( Ministry of Human resource and development )
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <Typography>Can I prepare only for NATA ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, Students can prepare only for the nata exam and get admission
            in State government colleges. As an expert in this field we advise
            students to prepare for both JEE Paper 2 & NATA exams simultaneously
            because the things to be learnt will be the same in both cases.
            Students will have an option of colleges since they will be able to
            participate in two counsellings ( JOSAA is the counselling conducted
            ).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <Typography>Difference between NATA & JEE ?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            NATA & JEE both are exam conducted in India for admitting students
            into architecture colleges, Difference between both the exams are
            JEE mains paper 2 is conducted by MHRD ( Ministry of Human resource
            and Development ) while NATA is conducted by COA ( Council of
            Architecture - India ). NATA syllabus and JEE syllabus varies by
            30%. NATA has 3 drawing questions to be answered and the syllabus
            contains PCM ( Physics, Chemistry & Math ) apart from Architectural
            aptitude & Logical Reasoning. While JEE Mains paper 2 has Two
            Drawing questions with 25 Math Questions, Architectural Aptitude &
            Reasoning visualization Questions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <Typography>
            What are the best colleges for studying Architecture ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            All over India there are many good institutions which are good in a
            variety of factors & Infrastructure but based on competitiveness of
            students we can list some of the colleges in India. SPA Delhi JJ
            School of Architecture, Mumbai CEPT, Ahmedabad NIT, Trichy IIT,
            Roorkee IIT, Bombay NIT, Calicut In Tamilnadu SAP,Chennai
            MEASI,Chennai TCE, Madurai.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <Typography>
            Is JEE is tough, will a average student prepare for JEE ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            One cannot judge students whether they can do good in JEE Paper 2
            based on their School academics, We have seen students who have
            score dvery less in School board exams but got into IITs and NITs.
            Also there are students who are toppers in school have become
            ineligible in JEE Score. This JEE Paper 2 ( Architecture ) is
            different from JEE Mains Paper 1 ( Engineering ).JEE paper 2 does
            not contain Chemistry and Physics instead you will get drawing and
            visualization questions which can be performed well even by an
            average performer in school who is good in the creative side.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <Typography>
            Will joinning in neram will affect my school studies ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We give equal importance for school academics and we have designed &
            Scheduled our class in way that it won’t interfere with school
            classes, Every week our NATA online class time table differs, we
            schedule our classes based on student's convenience and also if
            student was not able to attend the class, they can watch any class
            recordings from Neram Classes App. Download Neram Classes App from
            Play-Store.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <Typography>
            Can i start learning for NATA after 12th Exams ?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes we conduct crash courses after board exams, But students who are
            attending year-long classes will get more practise than the students
            who join after Board Exams.Unlike other entrance exams NATA & JEE
            Paper 2 has drawing questions which has 50% weightage.Drawing
            cannont be learned from Books, one will be able to draw good only if
            he/she is practising regularly.Having mentors from neram will boost
            you practise. Also Year-long students will be able to perform good
            in JEE paper 2 ( Attempt 1 ) which will be conducted before 12th
            board exams.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <div className="my-5">
        <MyButton variant="contained" color="primary">
          More Faqs
        </MyButton>
      </div>
    </div>
  );
}

export default Minifaqs;
