import React from "react";
import styled, { keyframes } from "styled-components";

// Define keyframes for the wave animation
const moveForever = keyframes`
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
`;

// Styled SVG component
const HeroWavesSvg = styled.svg`
  display: block;
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 0; /* Default position */
  ${({ position }) =>
    position === "top"
      ? "top: 0; transform: scaleY(-1);"
      : ""}/* Dynamic styling based on position */
`;

// Styled group for the wave animation
const WaveGroup = styled.g`
  use {
    animation: ${moveForever} 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }

  /* Animation properties for different waves */
  &.wave1 use {
    animation-delay: -2s;
    animation-duration: 7s;
  }

  &.wave2 use {
    animation-delay: -3s;
    animation-duration: 10s;
  }

  &.wave3 use {
    animation-delay: -4s;
    animation-duration: 13s;
    fill: ${(props) => props.fill}; /* Dynamic fill color based on position */
  }

  &.wave4 use {
    animation-delay: -5s;
    animation-duration: 20s;
  }
`;

// Styled path for the wave
const WavePath = styled.path`
  fill: ${(props) => props.fill};
`;

const HeroWavesComponent = ({ position, bgcolor }) => {
  return (
    <HeroWavesSvg
      viewBox="0 24 150 28"
      className="hero-waves"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      position={position} // Dynamic positioning prop
    >
      {/* Define your wave patterns */}
      <defs>
        <WavePath
          id="wave-path"
          d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
        />
      </defs>
      {/* Wave groups with different animations */}
      <WaveGroup className="wave1">
        <use fill="rgba(255,255,255, .1)" x="50" xlinkHref="#wave-path" y="1" />
      </WaveGroup>
      <WaveGroup className="wave2">
        <use
          fill="rgba(255,255,255, .2)"
          x="50"
          xlinkHref="#wave-path"
          y="-2"
        />
      </WaveGroup>
      {/* WaveGroup for wave3 with dynamically set fill color */}
      <WaveGroup className="wave3" fill={bgcolor}>
        <use
          fill={bgcolor} // Use bgcolor directly for wave3
          x="50"
          xlinkHref="#wave-path"
          y="7"
        />
      </WaveGroup>
    </HeroWavesSvg>
  );
};

export default HeroWavesComponent;
