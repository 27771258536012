import React, { useState } from "react";
import styled from "styled-components";

const LabelAnim = styled.label`
  position: absolute;
  background-color: #fff;
  cursor: text;
  padding: ${({ isActive }) => (isActive ? " 0 0.5rem" : "")};
  color: ${({ isActive }) =>
    isActive ? "var(--violet)" : "var(--violetopacity)"};
  top: ${({ isActive }) => (isActive ? "-7px" : "14px")};
  font-size: ${({ isActive }) => (isActive ? "15px" : "15px")};
  line-height: ${({ isActive }) => (isActive ? "15px" : "15px")};
  font-weight: ${({ isActive }) => (isActive ? "400" : "400")};
  margin: ${({ isActive }) => (isActive ? "0 0 0 0.5rem" : "0rem 0 0 1rem")};

  transition: top 0.2s ease-in-out, font-size 0.2s ease-in-out;
  @media (max-width: 768px) {
    top: ${({ isActive }) => (isActive ? "-8px !important" : "8px")};
    font-size: ${({ isActive }) => (isActive ? "12px !important" : "12px")};
    /* margin: ${({ isActive }) =>
      isActive ? "0.6rem 0 0 0.25rem !important" : "0.5rem 0 0 0.25rem"}; */
  }
`;

const Input = styled.input`
  width: 100%;
  height: 44px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid var(--lightgrey);
  border-radius: 5px;
  @media (max-width: 768px) {
    height: 32px;
  }
  &:focus {
    font-weight: 400;
    border-color: var(--violet) !important;
    box-shadow: 0 0 0 0.25rem #88206d24;
    outline: none;
    /* @media (max-width: 768px) {
    } */
  }
`;

const InputContainer = styled.div`
  position: relative;
`;

const Select = styled.select`
  width: 100%;
  font-weight: 400;
  border: 1px solid var(--lightgrey);
  font-size: 14px;
  height: 44px;
  color: var(#000); /* Default color for select options */
  border-radius: 5px;
  padding: 0 0 0 0.5rem;

  @media (max-width: 768px) {
    height: 32px;
  }

  &:focus {
    border-color: var(--violet);
    box-shadow: 0 0 0 0.25rem #88206d45;
    outline: none;
  }
`;

function InputField({ type, connect, label, options }) {
  const [isActive, setIsActive] = useState(false);

  const handleFocus = () => {
    setIsActive(true);
  };

  const handleBlur = (event) => {
    if (!event.target.value) {
      setIsActive(false);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (type === "number") {
      // Regular expression to match only numbers
      const regex = /^[0-9\b]+$/;
      // Check if the input value matches the regex pattern for numbers
      if (regex.test(value)) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    } else {
      setIsActive(true);
    }
  };

  const Component = type === "dropdown" ? Select : Input;

  return (
    <InputContainer className="col">
      <LabelAnim htmlFor={connect} isActive={isActive}>
        {label}
      </LabelAnim>
      <Component
        id={connect}
        name={connect}
        type={type === "number" ? "text" : type} // Convert type to "text" if it's "number"
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
      >
        {options &&
          options.map((option, index) => (
            <option
              key={index}
              value={option.value}
              className={index === 0 ? "default-option disabled" : ""}
            >
              {option.label}
            </option>
          ))}
      </Component>
    </InputContainer>
  );
}

export default InputField;
