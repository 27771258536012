import {
  Box,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

// COURSE

const Fees = [
  {
    Tuition: "Rs. 10000",
    Tax: "Rs. 450",
    Total: "",
    info1: "Most affordable Fee",
    info1Amt: "",
    info2: "For the Quality we provide",
    info2Amt: "",
  },
  {
    Tuition: "Rs. 30000",
    Tax: "Rs. 450",
    Total: "",
    info1: "Part Payment :",
    info1Amt: "Rs.16,450",
    info2: ": Full Payment",
    info2Amt: "Rs.25,450 (5k Less)",
  },
  {
    Tuition: "Rs. 35000",
    Tax: "Rs. 450",
    Total: "",
    info1: "Part Payment :",
    info1Amt: "Rs.16,450",
    info2: ": Full Payment",
    info2Amt: "Rs.30,450 (5k Less)",
  },
];

Fees.forEach((fee) => {
  // Parse the numeric values from the strings and remove "Rs."
  const tuition = parseInt(fee.Tuition.replace("Rs. ", ""));
  const tax = parseInt(fee.Tax.replace("Rs. ", ""));

  // Calculate the total and add "Rs." prefix
  fee.Total = "Rs. " + (tuition + tax);
});

// ----

// TABLE

function createData(name, fees) {
  return { name, fees };
}

function BasicTable({ selectedOption }) {
  const selectedFee = Fees[selectedOption];

  const rows = [
    // createData("Tuition Fees:", selectedFee.Tuition),
    // createData("Tax / Processing:", selectedFee.Tax),
    createData("Course Total Fee:", selectedFee.Total),
  ];

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.name}
                sx={{
                  "&:last-child": {
                    borderBottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                  },
                  ...(index === rows.length - 1 && {
                    fontWeight: "bold",
                  }),
                }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  <b style={{ color: "var(--pink)" }}>{row.fees}</b>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "var(--violet)",
          padding: "0.5rem 1rem",
          color: "white",
          border: "2px solid var(--violet)",
          marginBottom: "1rem",
          borderRadius: "0 0 5px 5px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ fontSize: "12px" }}>{selectedFee.info1}</Typography>
          <Typography sx={{ fontSize: "14px" }}>
            <b>{selectedFee.info1Amt}</b>
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", textAlign: "right" }}
        >
          <Typography sx={{ fontSize: "12px" }}>{selectedFee.info2}</Typography>
          <Typography sx={{ fontSize: "14px" }}>
            <b>{selectedFee.info2Amt}</b>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

// -----

// TAB for Course

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CourseTabs({ formData, setFormData, tabLabels }) {
  const [value, setValue] = React.useState(0);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(formData);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            // Adjust tab styles here
            "& .MuiTab-root": {
              padding: isSmallScreen ? "8px" : "8px 15px",
              fontSize: isSmallScreen ? "12px" : "14px",
            },
          }}
        >
          {tabLabels.map((label, index) => (
            <Tab
              label={isSmallScreen ? sliceLabel(label, index) : label}
              key={index}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </Box>
      {Fees.map((fee, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          <BasicTable selectedOption={index} />
        </CustomTabPanel>
      ))}
    </Box>
  );
}

// Label

const sliceLabel = (label, index) => {
  switch (index) {
    case 0:
      return label.slice(0, 5); // For the first tab, slice 5 characters
    case 1:
      return label.slice(0, 7); // For the second tab, slice 6 characters
    case 2:
      return label.slice(0, 4); // For the third tab, slice 4 characters
    default:
      return label;
  }
};

export default CourseTabs;
