import styled from "styled-components";

const NextArrow = styled.img`
  height: 50px; /* Set the initial height */
  display: block;
  transition: all 0.3s ease; /* Add a transition effect */
  z-index: 1;
  cursor: pointer;

  /* Override the height for .slick-next class */
  .slick-next {
    height: 40px !important; /* Override the height */
    margin-right: 1.5rem !important;
  }

  /* Change the image on hover */
  &:hover {
    content: url("images/img/iconset/nav/Separated/SVG/Asset%204.svg");
  }
`;

// Define styled component for the next arrow
const PrevArrow = styled.img`
  height: 50px; /* Set the initial height */
  display: block;
  transition: all 0.3s ease; /* Add a transition effect */
  z-index: 1;
  cursor: pointer;
  .slick-prev & {
    height: 40px !important; /* Override the height */
    margin-left: 1.5rem;
  }

  /* Change the image on hover */
  &:hover {
    content: url("images/img/iconset/nav/Separated/SVG/Asset%207.svg");
  }
`;

// Custom arrow

export function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  console.log(props.className);
  return (
    <NextArrow
      src="images/img/iconset/nav/Separated/SVG/Asset%205.svg"
      className={({ ...className }, "position-absolute end-0 top-50")}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}

export function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <PrevArrow
      src="images/img/iconset/nav/Separated/SVG/Asset%206.svg"
      className={({ ...className }, "position-absolute top-50")}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}
