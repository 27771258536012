import React from "react";
import ResponsiveAppBar from "../../components/TopNavigationBar/AppBar/AppBarMui";
import NeramFooter from "../../components/Footer/footer";
import JEE_Sylabus_Comp from "./Components/JEE_Sylabus_Comp";
import HeroWavesComponent from "../../components/Sections/WaveAnimate";
import { Box } from "@mui/material";

const JeeSyllabus = () => {
  return (
    <Box sx={{ background: "var(--lightpink)" }}>
      <ResponsiveAppBar
        variant="opaque"
        showBreadCrumBar={true}
        showBackButton={true}
        largerScreenTitle="JEE B.Arch Syllabus 2025"
        smallerScreenTitle="JEE B.Arch Syllabus"
        breadcrumbName={"JEE Syllabus 2025"}
      />
      <JEE_Sylabus_Comp />
      <div className="position-relative">
        <HeroWavesComponent position="top" bgcolor={"var(--lightpink)"} />
        <NeramFooter />
      </div>
    </Box>
  );
};

export default JeeSyllabus;
