import { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { IconButton, Typography, Button, Modal } from "@mui/material";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth, firestore } from "../../../Firebase/setup";

// MyCOmponents
import PhoneInput from "./PopUP/PhoneNumber";
import OtpInput from "./PopUP/otpSixDigitComponent";
import MyButton from "../../../Basic components/Buttons/Buttons";

const PopupCard = styled.div`
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 60%;
  padding: 5rem 0;
  border-radius: 1rem;
  -webkit-box-shadow: 2px 4px 54px -21px rgba(0, 0, 0, 0.83);
  -moz-box-shadow: 2px 4px 54px -21px rgba(0, 0, 0, 0.83);
  box-shadow: 2px 4px 54px -21px rgba(0, 0, 0, 0.83);
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Screen1 = ({ onNext }) => {
  // Back
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="back"
        onClick={handleGoBack}
        style={{ position: "absolute", top: "0", margin: "1rem 1rem" }}
      >
        <ArrowBackIcon />
      </IconButton>
      {/* TITLE */}
      <Typography
        id="modal-modal-title"
        variant="h5"
        fontWeight={600}
        align="center"
        sx={{ marginBottom: "1rem" }}
      >
        Enroll now to receive all the latest updates about <br />
        <span style={{ color: "var(--violet)" }}>
          NATA and JEE Paper 2 examinations
        </span>
      </Typography>
      {/* MOBILE NUMBER */}
      <Typography sx={{ textAlign: "center", marginBottom: "2rem" }}>
        <span style={{ fontSize: "20px", fontWeight: "600" }}>
          Mobile number
        </span>
        <br />
        <span style={{ fontSize: "14px" }}>
          Please enter your mobile number
        </span>
      </Typography>
      {/* Render PhoneInput component */}
      <div
        className="width"
        style={{ width: "100%", display: "flex", justifyContent: "center" }}
      >
        <PhoneInput onNext1={onNext} />
      </div>
      <div className="d-flex justify-content-center my-3">
        <div id="recaptcha"></div>
      </div>
    </>
  );
};

// Inside Screen2 component
const Screen2 = ({ onEdit, fullNumber, verifyOtp, setCurrentScreen }) => {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  // Function to check if all OTP fields have a value
  const [error, setError] = useState(null); // State variable to track errors

  const allFieldsFilled = () => {
    return otp.every((field) => field.trim() !== "");
  };

  // Function to handle OTP verification
  const handleVerifyOtp = async () => {
    try {
      setError(null); // Clear any previous errors
      await verifyOtp(otp.join("")); // Join the otp array without specifying a delimiter
      console.log("OTP Verification successful");
      setCurrentScreen(3); // Move to Screen3 upon successful verification
    } catch (error) {
      console.error("OTP Verification error:", error);
      setError("Invalid OTP. Please try again."); // Set error message
    }
  };

  // Effect to trigger OTP verification when all fields are filled
  useEffect(() => {
    if (allFieldsFilled()) {
      handleVerifyOtp();
    }
  }, [otp]); // Watch for changes in the otp state array

  // Function to handle OTP field changes
  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  // Function to clear all OTP fields
  const handleClearOtp = () => {
    setOtp(["", "", "", "", "", ""]); // Reset otp state to an array of empty strings
    setError(null); // Clear any previous errors
  };

  const handleResendOtp = async (phoneNumber) => {
    console.log("Full number:", phoneNumber);
    try {
      setError(null);
      console.log("Auth object:", auth);
      // Resend OTP to the user's phone number
      await signInWithPhoneNumber(auth, phoneNumber);
      console.log("OTP Resent successfully");
    } catch (error) {
      console.error("OTP Resend error:", error);
      setError("Error resending OTP. Please try again.");
    }
  };

  // Pass auth object to handleResendOtp function when calling it
  const handleResendOtpClick = () => {
    handleResendOtp(fullNumber); // Pass fullNumber as an argument
  };

  return (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="back"
        onClick={onEdit}
        style={{ position: "absolute", top: "0", margin: "1rem 1rem" }}
      >
        <ArrowBackIcon />
      </IconButton>

      <Typography sx={{ textAlign: "center" }}>
        <span style={{ fontSize: "20px", fontWeight: "600" }}>Enter OTP</span>
        <br />
        <span style={{ fontSize: "14px" }}>
          OTP has been sent on your mobile number: {fullNumber}
          <Button
            variant="text"
            size="small"
            onClick={onEdit}
            style={{
              backgroundColor: "transparent",
              boxShadow: "none",
              textTransform: "none",
              padding: "0",
              minWidth: "40px",
            }}
          >
            Edit
          </Button>
        </span>
      </Typography>
      <OtpInput otp={otp} handleOtpChange={handleOtpChange} />
      <div className="d-flex justify-content-center ">
        <Button
          variant="text"
          size="small"
          onClick={handleResendOtpClick}
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            textTransform: "none",
            padding: "0",
          }}
        >
          Resend OTP
        </Button>
        {/* Clear button */}
        {error && <Button onClick={handleClearOtp}>Clear</Button>}
      </div>
      <div className="d-flex justify-content-center ">
        {error && <p style={{ color: "red" }}>{error}</p>}
      </div>
      <div className="d-flex justify-content-center mt-5">
        <Button
          variant="contained"
          color="primary"
          disabled={!allFieldsFilled()}
          onClick={handleVerifyOtp} // Call handleVerifyOtp function
        >
          Verify OTP
        </Button>
      </div>
    </>
  );
};

const Screen3 = ({ handleClose }) => {
  return (
    <>
      <Typography sx={{ textAlign: "center" }}>
        <span style={{ fontSize: "20px", fontWeight: "600" }}>
          Welcome to neramClasses.com
        </span>
        <br />
        <span style={{ fontSize: "14px" }}>
          All Updates will be sent to your WhatsApp number
        </span>
      </Typography>
      <div className="d-flex justify-content-center mt-5">
        <MyButton variant="contained" onClick={handleClose}>
          Open Calculator
        </MyButton>
      </div>
    </>
  );
};

const Popup = ({ onPhoneNumberSubmit }) => {
  // TIMER
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 7000);

    return () => clearTimeout(timer);
  }, []);

  // SEND OTP

  const sendOtp = async (phoneNumber, onSuccess, setUser) => {
    const recaptcha = new RecaptchaVerifier(auth, "recaptcha", {
      timeout: 10000, // Adjust the timeout duration as needed (in milliseconds)
    });
    console.log("Before sending otp");
    try {
      const confirmation = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        recaptcha
      );
      console.log("Confirmation object:", confirmation);
      console.log("User object:", confirmation.user); // Log the user object
      console.log("OTP Sent successfully");

      onSuccess();
      // Update user state if necessary
      if (setUser) {
        setUser(confirmation);
        console.log("Verification successfulz");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };

  // Verify OTP

  const verifyOtp = async (otpValue) => {
    console.log("Before confirming OTP");
    try {
      const data = await user.confirm(otpValue);
      console.log("OTP Verification successful");
      return data;
    } catch (error) {
      console.error("OTP Verification error:", error);
      throw error; // Re-throw the error to be caught by the caller
    }
  };

  // Your remaining code for Popup component

  // CLOSE

  const handleClose = () => {
    setOpen(false);
  };

  //
  const [currentScreen, setCurrentScreen] = useState(1);
  const [fullNumber, setFullNumber] = useState("");

  const handleNext = (phoneNumber) => {
    setFullNumber(phoneNumber);
    // Call the function passed from the parent component
    onPhoneNumberSubmit(phoneNumber);
    sendOtp(
      phoneNumber,
      () => {
        setCurrentScreen(2);
      },
      setUser
    );
  };

  const handleEdit = () => {
    setCurrentScreen(1); // Change to Screen1
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={currentScreen === 3 ? handleClose : undefined}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <PopupCard>
          {/* Content rendering based on currentScreen */}
          {currentScreen === 1 ? (
            <Screen1 onNext={handleNext} />
          ) : currentScreen === 2 ? (
            <Screen2
              onEdit={handleEdit}
              fullNumber={fullNumber}
              verifyOtp={verifyOtp}
              setCurrentScreen={setCurrentScreen}
            />
          ) : (
            <Screen3 handleClose={handleClose} />
          )}
          {/* Conditional rendering of recaptcha element */}
          <div className="d-flex justify-content-center my-3">
            {currentScreen === 1 && <div id="recaptcha"></div>}
          </div>
        </PopupCard>
      </Modal>
    </div>
  );
};

export default Popup;
