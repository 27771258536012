import React from "react";
import { Typography, Box, Divider } from "@mui/material";
import styled from "styled-components";

const YellowHR = styled.div`
  h5 {
    color: var(--yellow);
    font-weight: 500;
  }
`;

const AboutUs = () => {
  return (
    <YellowHR>
      <Typography variant="h5">About Us</Typography>
      <br />
      <Typography variant="caption" component="p">
        <em>
          "Architecture is a learned game, correct and magnificent, of forms
          assembled in the light."
        </em>
        <br />
        <span>- Architect Le Corbusier</span>
      </Typography>
      <Typography variant="caption" component="p">
        Neram Classes is an entrance exam coaching center, primarily focusing on
        architecture exams such as NATA and JEE-MAINs. We are committed to
        giving back to society by providing coaching to students from smaller
        towns and rural areas. Established in 2017 by alumni from prestigious
        institutions including IITs, NITs, SPA, AU, and many other
        well-recognized organizations. In 2021, we expanded our coaching
        services to seven cities in Tamil Nadu: Chennai, Madurai, Trichy,
        Coimbatore, Tirupur, Kanchipuram, and Pudukkottai. Additionally, we
        offer online coaching programs to reach students beyond geographical
        boundaries.
      </Typography>
      <Typography variant="caption" component="p">
        At Neram, we provide specialized online coaching for students aspiring
        to pursue architecture. Our courses prepare students for entrance exams
        such as NATA, JEE-mains Paper-II, JEE-advanced Paper-II, UCEED, NID, and
        AAT for B.Arch.
      </Typography>
      <Typography variant="caption" component="p">
        We strongly believe in the words of Nelson Mandela: "Education is the
        most powerful weapon you can use to change the world." Education is more
        than just securing well-paid jobs; it empowers individuals to pursue
        their passions diligently, fostering positive change within themselves
        and the society they inhabit. At Neram Classes, we are dedicated to
        nurturing future architects and changemakers who will shape the world
        for the better.
      </Typography>
      <br /> <br />
      <div className="text-start">
        <Typography variant="h5">
          WHY ARE WE THE BEST FOR NATA ONLINE COACHING?
        </Typography>
        <br />
        <Typography variant="caption" component="p">
          Architecture is considered one of the most challenging courses in the
          world. The commonly known architecture entrance exam papers include
          NATA, JEE-MAINs, UCEED, NIFT, NID, and AAT. The entrance exam papers
          help the students secure a good college and help them get an insight
          into Architecture itself and what they are to learn for the next five
          years. NATA and JEE-MAINs, JEEAdvanced are considered critical
          national-level architecture entrance exam papers all over India.
          Furthermore, these tests are accepted by most architecture colleges.
          <br />
          <br />
          At Neram, we believe that it is essential to give personalized
          training to students, and we provide just that through online
          coaching. We are one of the largest institutions in India. We offer to
          coach at seven cities in Tamilnadu Chennai, Madurai, Trichy,
          Coimbatore, Tirupur, Kanchipuram, and Pudukkottai.
          <br />
          <br />
          Neram is one of the few online coaching centers to achieve 100%
          success rate. Every student we have coached has been in the most
          esteemed colleges in India. Our educators are practicing architects
          who are alumni from IITs, NITs, AU, SPA. We also have tutors from UK
          and Australia.Neram is one of the few online coaching centers to
          achieve 100% success rate. Every student we have coached has been in
          the most esteemed colleges in India. Our educators are practicing
          architects who are alumni from IITs, NITs, AU, SPA. We also have
          tutors from UK and Australia.
          <br />
          <br />
          We provide interactive learning sessions, verified study material,
          mock tests, quizzes, pre-recorded video sessions, and much more. We
          believe in interactive coaching and individual attention. We try to
          understand each student discretely and cater to everybody's needs.
          <br />
          <br />
          In India, online coaching in architecture for entrance exams is a
          relatively developing stream of education, and Neram has been very
          successful in doing it for the past four years. It helps students
          attend exams like NATA, JEE-MAINs, UCEED, and more even from the
          remotest corner of the world online, inspiring us to help and reach
          out to more and more students each passing year.
        </Typography>
        <br /> <br />
        <Typography variant="h5">
          WHY CHOOSE NERAM FOR ONLINE NATA COACHING?
        </Typography>
        <br />
        <Typography variant="caption" component="p">
          Very few coaching centers in India understand the importance of proper
          internet usage and technology in education, and we are one such online
          entrance exam coaching center. Our best interest lies in introducing
          architecture in the best way possible to students, to coach students
          for entrance exams like NATA, JEE-PAPER-2JEE MAINS-2, JEE-ARCH, UCEED,
          NIFT, NID, AAT.
          <br />
          <br />
          At Neram, we believe in stirring the students' passion for
          architecture. We like to provide a holistic experience where we engage
          the students in various QnA sessions, quizzes, personalized doubt
          clearing sessions, and interactive classes. We focus on helping
          students use their entire capacity through online means.
          <br />
          <br />
          Because of the easy access to online platforms, students can learn
          from well-accomplished educators worldwide. It is among India's first
          online coaching centers. Neram understands that it is a part of the
          educating process to ensure that students are not constrained to
          memorize concepts. Students are encouraged to attend many quizzes,
          mock tests, and more. At Neram, we ensure that we coach the students
          100% before they attend their entrance exam papers.
          <br />
          <br />
          Neram emphasizes personalized learning. From statistics, we understand
          that students provided with individual care show good progress. Hence,
          we analyze and tend to improve every student's personal learning
          profile. Each concept gets tagged at different levels of difficulty to
          create a smooth learning curve that keeps students motivated through
          any new learning challenge. Whenever errors are made, motivating
          remedial methods are generated to strengthen and improve students'
          learning period.
          <br />
          <br />
          NATA papers have been one of the primary architecture entrance exams
          in India. Every year our students secure about 50% of seats in top
          architecture colleges in Tamil Nadu and Kerela.JEE paper 2 Highest
          marks in 2020 2021 are from Neram. 99.99 percentile in 2021.99.98 in
          2020. We intend to provide the most affordable and quality NATA
          coaching in all of India
        </Typography>
        <br /> <br />
        <Typography variant="h5">
          OUR TEACHING METHODOLOGY AND PERSONAL NATA TRAINING
        </Typography>
        <br />
        <Typography variant="caption" component="p">
          To understand our teaching methodology, we need to understand NATA
          more. The National Aptitude Test in Architecture (NATA) is conducted
          by the National Institute of Advanced Studies in Architecture. NATA is
          a national-level architecture entrance examination for studying
          B.Arch. The" test checks the eligibility in the students' aptitude in
          drawing and observation skills, sense of proportion, aesthetic
          sensitivity, and critical thinking.
          <br />
          <br />
          We at Neram believe students must not stick to the traditional
          educational system. Architecture, or any other designing course, is
          not meant to be taught in such ways. We believe teaching cannot be a
          one-size-fits-all thing, and education is supposed to cater to and
          acknowledge students' differences and abilities. Personalized learning
          is the foundation of our unique programs for students. Our tutors
          create interactive two-way sessions for students to feel to be a part
          of. In this manner, we tend to make every student a part of the class,
          express themselves, and eventually build their passion. We like to
          accept that errors, corrections, slow learning, mistakes are all a
          part of the learning process and only meant to be corrected and
          improvised. Motivating them to achieve their perfection is our motto.
        </Typography>
        <br /> <br />
        <Typography variant="h5">
          TECHNOLOGY-ENABLED NATA PREPARATION
        </Typography>
        <br />
        <Typography variant="caption" component="p">
          In India, education systems have been offline based for a very long
          time; most students have to move to places to get access to good
          education. Very few companies like BYJU's, Vedantu are trying to break
          the stereotype and make education within reach to all. Neram is one
          such online coaching center
          <br />
          <br />
          Online coaching in Architecture has helped in breaking many
          stereotypes.
          <br />
          <br />
          We reach students directly at the luxury of their homes, with the best
          teachers, materials, and domain. We also offer pre-recorded video
          lectures to help students to be independent. We provide 24x7 NATA
          helping center, where we answer all their queries. Our teachers also
          give personalized one-to-one sessions to students.
          <br />
          <br />
          Thus, we believe our students get the perfect coaching to face the
          papers. And so has been it. Our students have attained 99.99
          percentile in 2021 and will continue maintaining the records in the
          upcoming years.
          <br />
          <br />
        </Typography>
        <br /> <br />
        <Typography variant="h5">Key features of NERAM classroom</Typography>
        <br />
        <ul>
          <li>INTERACTIVE LEARNING SESSIONS IN NATA COACHING</li>
          <li>HGHH QUALITY OF MOCK TESTS JUST LIKE REAL EXAM QUESTIONS</li>
          <li>PERSONALIZED INDIDUAL MENTORS FOR MONITORING EACH STUDENT</li>
          <li>ONLINE DRAWING EVALUATION SESSIONS</li>
          <li>WE ARE ARCHITECTS PASSIONATE IN TEACHING</li>
        </ul>
        <br /> <br />
        <Typography variant="h5">
          Best Teachers & Engaging NATA EXAM ContentS
        </Typography>
        <br />
        <Typography variant="caption" component="p">
          NERAM classroom Learning Programs provide students a holistic learning
          experience. Students across regions can access the best teachers.Very
          few teams like BYJU’S, VEDANTA in India have a great sense for the
          education sector as well as how technology can be used for effective
          scaling, NERAM is one such team. Hours and Hours of Study with no fun,
          is a bad idea for you, foreseeing the long run. To ensure that
          motivation is stirred in the best proportion for your clear
          understanding, a good number of quizzes and Objective tests are
          organized to impart knowledge. FREE Sample Papers and Important
          questions are extracted, solved and discussed, ensuring that you are
          100% prepared before any exam.
          <br />
          <br />
          Hours and Hours of Study with no fun, is a bad idea for you,
          foreseeing the long run. To ensure that motivation is stirred in the
          best proportion for your clear understanding, a good number of quizzes
          and Objective tests are organized to impart knowledge. FREE Sample
          Papers and Important questions are extracted, solved and discussed,
          ensuring that you are 100% prepared before any exam.
        </Typography>
      </div>
    </YellowHR>
  );
};

export default AboutUs;
