import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";
import GetRegisteredBtn from "./GetRegistered";

// SECTION TEXT CONTENT

export const SmHeading = ({ content }) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Box
        width={"1rem"}
        height={"1rem"}
        sx={{ background: "var(--gradredorange)" }}
      ></Box>
      <Typography
        variant="p"
        color={"var(--violet)"}
        fontWeight={"500"}
        textTransform={"uppercase"}
        fontFamily={"Bakbak one"}
      >
        {content}
      </Typography>
    </Box>
  );
};

const TextSectionStyled = styled.div`
  padding: 170px 0;
  @media (max-width: 576px) {
    padding: 50px 0;
  }
`;

const TextSection = ({ smTitle, bgTitle, desc }) => {
  return (
    <TextSectionStyled>
      <SmHeading content={smTitle} />
      <Typography variant="h4" fontFamily={"Suez One"}>
        {bgTitle}
      </Typography>
      <br />
      <Typography variant="p" fontSize={"14px"}>
        {desc}
      </Typography>
      <br />
      <br />
      <GetRegisteredBtn content={"Get Registered"} />
    </TextSectionStyled>
  );
};

export default TextSection;
