import React from "react";
import styled from "styled-components"; // import styled from styled-components
import { LogoTitle } from "../../../components/Sections/Heading";

const AchievementContainer = styled.div`
  margin-bottom: 20px;
`;

const AchievementImage = styled.img`
  margin-bottom: 1rem;
`;

const Achievement = ({ achLink, achTitle, achSubTitle, achAlt }) => {
  return (
    <AchievementContainer className="col-md-3 col-sm-6 xsmt col-xs-3 ">
      <AchievementImage src={achLink} alt={achAlt} height="52" />
      <LogoTitle title={achTitle} subtitle={achSubTitle} />
    </AchievementContainer>
  );
};

const AchievementLogos = [
  {
    smallName: "5K+ Qualified Students",
    bigName: "7 Years",
    href: "images/img/iconset/flag.svg",
    alt: "crown",
  },
  {
    smallName: "AIR 01 - 100 Percentile",
    bigName: "JEE 24'",
    href: "images/img/iconset/crown.svg",
    alt: "crown",
  },
  {
    smallName: "AIR 01 - 187",
    bigName: "NATA 23'",
    href: "images/img/iconset/Stars.svg",
    alt: "crown",
  },
  {
    smallName: "No failures ever",
    bigName: "100%",
    href: "images/img/iconset/victory.svg",
    alt: "crown",
  },
];

function AchieveLogos() {
  return (
    <div className="container">
      <div className="row justify-content-center position-relative">
        {AchievementLogos.map((item, index) => (
          <Achievement
            key={index}
            achLink={item.href}
            achTitle={item.bigName}
            achSubTitle={item.smallName}
            achAlt={item.alt}
          />
        ))}
      </div>
    </div>
  );
}

export default AchieveLogos;
