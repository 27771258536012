import React from "react";
import { Typography, Box } from "@mui/material";

// Custom MUI component for the section title
const SectionTitle = ({
  title,
  subtitle,
  titleColor = "var(--violet)",
  subTitleColor = "#000",
}) => {
  return (
    <Box className="section-title">
      <Typography variant="h5" sx={{ fontWeight: 700, color: titleColor }}>
        {title}
      </Typography>
      <Typography variant="subtitle2" sx={{ color: subTitleColor }}>
        {subtitle}
      </Typography>
    </Box>
  );
};

// Custom MUI component for the logo title
const LogoTitle = ({ title, subtitle }) => {
  return (
    <>
      <Typography
        variant="h1"
        sx={{
          fontWeight: 700,
          textTransform: "uppercase",
          fontSize: "2rem",
          color: "var(--violet)",
          marginBottom: "5px",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h1"
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          color: "var(--lightviolet)",
        }}
      >
        {subtitle}
      </Typography>
    </>
  );
};

export { SectionTitle, LogoTitle };
