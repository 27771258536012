import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TextField, Typography } from "@mui/material";
import { SolidButton2 } from "../../../Basic components/Button";
import Popup from "./popup";
import { auth, database } from "../../../Firebase/setup";
import { ref, push } from "firebase/database";
import {
  getCurrentDate,
  getCurrentTime,
} from "../../../Basic components/DateTime";
import axios from "axios";

const Card = styled.div`
  outline: 1px solid #88206d;
  box-shadow: 5px 4px 9px 0 rgb(136 32 109 / 20%);
`;

const TitleHeading = styled.h6`
  line-height: 20px;
  font-weight: 600;
  font-size: 14px;
  color: var(--violet);
`;

const PageTitle = styled.div`
  border-radius: 0 0 1rem 1rem;
  min-width: 15rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem;
  text-align: center;
  top: -1px;
  box-shadow: -4px -4px 4px -4px rgb(0 0 0 / 50%) inset;
`;

const CalciDisplay = styled.div`
  padding: 5px;
  margin-top: 4rem;
  max-height: 6.25rem;
  background-color: ${(props) =>
    props.clicked ? "rgb(236, 251, 167);" : "#93987c"};
  transition: background-color 0.3s ease;
  border-radius: 0.75rem;
  border: 3px solid var(--violet);
  box-shadow: -4px -4px 4px -4px rgba(0, 0, 0, 0.5) inset;
`;

const CalculatorBody = ({ handleClick }) => {
  // AUTH USER
  const [user, setUser] = useState(null); // Track user authentication state

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // FormData

  const [formData, setFormData] = useState({
    phoneNumber: "",
    sname: "",
    city: "",
    markScored: "",
    maxMark: "",
    scores: ["", "", ""],
    date: getCurrentDate(),
    time: getCurrentTime(),
    locationName: "",
  });

  // LOCATION

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getLocationName(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const getLocationName = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json`,
        {
          params: {
            q: `${latitude}+${longitude}`,
            key: "0f2ea0cee8b64644a6548b43a25256fc",
          },
        }
      );
      const location = response.data.results[0].formatted;
      setFormData((prevData) => ({
        ...prevData,
        locationName: location,
      }));
    } catch (error) {
      console.error("Error fetching location name:", error);
    }
  };

  useEffect(() => {
    getLocation();
  }, []); // Run once when the component mounts

  // =-----

  // Function to handle phone number submission
  const handlePhoneNumberSubmit = (phoneNumber) => {
    setFormData({ ...formData, phoneNumber: phoneNumber });
  };

  // ERRORS
  const [errors, setErrors] = useState({
    markScored: false,
    maxMark: false,
    nataScore: [false, false, false],
  });

  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveFormData();
    setButtonClicked(true);
  };

  // TO FIREBASE

  const saveFormData = () => {
    const formDataRef = ref(database, "calc");
    push(formDataRef, formData)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        console.error("Error storing form data:", error);
      });
  };

  // ONCHANGE

  const handleCalCInputChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };
    const newErrors = { ...errors, [name]: false };
    setFormData(newFormData);
    setErrors(newErrors);
  };

  const handleNataInputChange = (index, value) => {
    const newScores = [...formData.scores];
    newScores[index] = value;
    setFormData({ ...formData, scores: newScores });
    const newNataErrors = [...errors.nataScore];
    newNataErrors[index] = value <= 0 || value > 200;
    setErrors({ ...errors, nataScore: newNataErrors });
  };

  // CALCULATIONS

  const calculatePercentage = () => {
    const { markScored, maxMark } = formData;
    if (markScored === "" || maxMark === "") return 0;
    return (markScored / maxMark) * 100;
  };

  // FINAL NATA SCORE

  const calculateGreatestScore = () => {
    return Math.max(...formData.scores.map(Number));
  };

  // light ON/OFF

  const [clicked, setClicked] = useState(false);

  const handleClickInsideCard = () => {
    setClicked(true);
  };

  const handleClickOutsideCard = () => {
    setClicked(false);
  };

  const percentage = calculatePercentage();
  const greatestScore = calculateGreatestScore();
  const nataFinalCutoff =
    typeof greatestScore === "number" && !isNaN(greatestScore)
      ? (percentage * 2 + greatestScore).toFixed(2)
      : "Invalid score";

  // State to track if the Solid button has been clicked
  const [buttonClicked, setButtonClicked] = useState(false);

  return (
    <div className="container" style={{ paddingTop: "6rem" }}>
      <div className="row">
        <div className="col-lg-6 my-5">
          <Card
            className="card p-4 position-relative"
            onClick={handleClickInsideCard}
          >
            <PageTitle
              style={{ backgroundColor: "var(--violet)", color: "#fff" }}
            >
              NATA Cutoff Calculator
            </PageTitle>

            {/* DISPLAY */}
            <CalciDisplay
              onClick={handleClick}
              clicked={clicked}
              style={{ textShadow: "1px 1px 1px rgba(91, 97, 97, 0.85)" }}
            >
              <div className="row1 d-flex flex-row justify-content-between">
                <div className="basic-marks d-flex flex-column">
                  <Typography id="cutoff_1">
                    Board ={" "}
                    {isNaN(parseFloat((percentage * 2).toFixed(2))) ? (
                      <span style={{ color: "red" }}>Invalid score</span>
                    ) : (
                      (percentage * 2).toFixed(2)
                    )}
                    /200
                  </Typography>
                  <Typography id="cutoff_2">
                    NATA ={" "}
                    {NaN ? (
                      <span style={{ color: "red" }}>Invalid score</span>
                    ) : (
                      greatestScore
                    )}{" "}
                    /200
                  </Typography>
                </div>
                {/* eligibility */}
                {(percentage && greatestScore) > 0 && (
                  <div
                    className={
                      "d-flex justify-content-between px-2 py-1" +
                      (buttonClicked ? " d-block" : " d-none")
                    }
                  >
                    <b>
                      <span
                        style={{
                          color:
                            percentage >= 50 && greatestScore >= 70
                              ? "green"
                              : "red",
                        }}
                      >
                        {percentage >= 50 && greatestScore >= 70
                          ? "Eligible"
                          : "Not Eligible"}
                      </span>
                    </b>
                  </div>
                )}
              </div>
              <Typography
                className={
                  "row2 d-flex justify-content-end align-items-end" +
                  (buttonClicked ? " d-block" : " d-none")
                }
              >
                B.Arch Cutoff ={" "}
                <Typography
                  variant="h5"
                  sx={{ lineHeight: "1", margin: "0 0.5rem" }}
                >
                  <b>{nataFinalCutoff}</b>
                </Typography>{" "}
                / 400
              </Typography>
            </CalciDisplay>
            {/* PERSONEL DETAILS */}
            <div className="personel mt-3">
              <TitleHeading>Details</TitleHeading>
              <div className="mt-1">
                <div className="d-flex justify-content-between gap-2">
                  <TextField
                    fullWidth
                    id="sname"
                    label="Name"
                    variant="outlined"
                    size="small"
                    name="sname"
                    value={formData.sname}
                    onChange={handleCalCInputChange}
                  />
                  <TextField
                    fullWidth
                    id="city"
                    label="Your City"
                    variant="outlined"
                    size="small"
                    name="city"
                    value={formData.city}
                    onChange={handleCalCInputChange}
                  />
                </div>
              </div>
            </div>

            {/* BOARD MARK SECTION */}
            <div className="board-mark mt-3">
              <TitleHeading>Board Diploma mark</TitleHeading>
              <div className="mt-1">
                <div className="d-flex justify-content-between gap-2">
                  <TextField
                    fullWidth
                    id="outlined-mark-scored"
                    label={errors.markScored ? "Invalid Score" : "Mark Scored"}
                    variant="outlined"
                    type="number"
                    size="small"
                    name="markScored"
                    value={formData.markScored}
                    onChange={handleCalCInputChange}
                    error={errors.markScored}
                  />
                  <TextField
                    fullWidth
                    id="outlined-max-mark"
                    label={errors.maxMark ? "Invalid Score" : "Maximum Mark"}
                    variant="outlined"
                    type="number"
                    size="small"
                    name="maxMark"
                    value={formData.maxMark}
                    onChange={handleCalCInputChange}
                    error={errors.maxMark}
                  />
                </div>
              </div>
              {percentage > 0 && (
                <div
                  className="d-flex justify-content-between px-2 py-1"
                  style={{
                    backgroundColor: "var(--lightpink)",
                    fontSize: "12px",
                  }}
                >
                  <div style={{ color: percentage >= 50 ? "green" : "red" }}>
                    {"Academic Percentage = " + percentage.toFixed(2) + "%"}
                  </div>
                  <b>
                    <span style={{ color: percentage >= 50 ? "green" : "red" }}>
                      {percentage > 50 ? "Eligible" : "Not Eligible"}
                    </span>
                  </b>
                </div>
              )}
            </div>

            {/* NATA SCORE SECTION */}
            <div className="nata-score mt-4">
              <TitleHeading>NATA Scores</TitleHeading>
              <div className="mt-1">
                <div className="d-flex justify-content-between gap-2">
                  {[1, 2, 3].map((index) => (
                    <TextField
                      key={index}
                      id={`outlined-basic-score${index}`}
                      label={
                        errors.nataScore[index - 1]
                          ? "Invalid Score"
                          : `Score ${index}`
                      }
                      variant="outlined"
                      type="number"
                      size="small"
                      value={formData.scores[index - 1]}
                      onChange={(e) =>
                        handleNataInputChange(index - 1, e.target.value)
                      }
                      error={errors.nataScore[index - 1]}
                      helperText={
                        errors.nataScore[index - 1] ? "Invalid score" : ""
                      }
                    />
                  ))}
                </div>
              </div>
              <div id="cutoff_2">
                {greatestScore > 0 && (
                  <div
                    className="d-flex justify-content-between px-2 py-1"
                    style={{
                      backgroundColor: "var(--lightpink)",
                      fontSize: "12px",
                    }}
                  >
                    <div
                      style={{ color: greatestScore >= 70 ? "green" : "red" }}
                    >
                      {"Final NATA Score = " + greatestScore}
                    </div>
                    <b style={{ color: greatestScore >= 70 ? "green" : "red" }}>
                      {greatestScore >= 70 ? "Eligible" : "Not Eligible"}
                    </b>
                  </div>
                )}
              </div>
            </div>

            {/* CALCULATE BUTTON */}
            <SolidButton2
              className="mt-5 py-1"
              width="100%"
              id="calculate_cutoff"
              onClick={handleSubmit}
            >
              Calculate NATA Cutoff
            </SolidButton2>
          </Card>
        </div>

        {/* DESCRIPTION SECTION */}
        <div
          className="col-lg-6 text-center my-5"
          onClick={handleClickOutsideCard}
        >
          <div className="description">
            <Typography
              variant="h4"
              style={{ color: "var(--violet)", fontWeight: "bold" }}
            >
              NATA Cutoff Calculator
            </Typography>
            <Typography variant="h6">
              National Aptitude test Cut off calculator
            </Typography>
            <br />
            <Typography>
              Every year NATA exams are coming with changes. Based on the latest
              NATA Exam guidelines given by COA, NATA Exam is conducted three
              times in 2022 & 2023. Students are confused how to calculate the
              NATA cutoff.Cutoff calculation procedure is different for students
              attempting 3 Exams and 2 Exams. Also NATA score is not a stand
              alone score for getting admission In architecture colleges, only
              50% of the NATA score will be taken for Architecture admission cut
              off whereas 50% Board/Diploma score will be added to NATA Final
              score. For making NATA cutoff calculation easier, neramclasses
              Online NATA coaching has made this NATA cutoff calculation tool
              for Architecture aspiring students.
            </Typography>
            <ul>
              <li>Cutoff &gt; 300 =&gt; Excellent</li>

              <li>Cutoff between 270 - 300 =&gt; Good</li>
              <li>Cutoff between 250 - 270 =&gt; Average</li>
              <li>Cutoff below 250 =&gt; Bad</li>
            </ul>
            <Typography>
              neramClasses.com will help you reach good NATA Cutoff, since we
              have a remarkable track record for the past 8 Years in Trichy |
              Chennai | Madurai | Coimbatore & Tiruppur
            </Typography>
          </div>
        </div>
      </div>

      {/* Popup only appears if user is not authenticated */}
      {!user && <Popup onPhoneNumberSubmit={handlePhoneNumberSubmit} />}
    </div>
  );
};

export default CalculatorBody;
