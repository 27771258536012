import React from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

const MobileLogoLink = styled(Box)`
  ${({ theme }) => ({
    backgroundColor: "rgba(255, 255, 255, 0.171)",
    padding: "0.5rem",
    color: "#fff !important",
    borderRadius: "5px",
    textDecoration: "none",
    letterSpacing: "0.5px",
    fontFamily: "Roboto",
    cursor: "pointer",
    transition: "0.1s ease-in",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#54285c !important",
    },
  })}
`;

function NeramLogo({ sx }) {
  return (
    <Box sx={sx}>
      <img
        src="images/neram.png"
        alt="Online NATA Coaching center in coimbatore"
      ></img>
    </Box>
  );
}

function MobileLogo({ sx }) {
  return (
    <MobileLogoLink sx={sx}>
      <b>neram</b>Classes
    </MobileLogoLink>
  );
}

export { NeramLogo, MobileLogo };
