import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaLinkedin } from "react-icons/fa";
import {
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../components/Basic components/CarouselArrow";

const Title = styled.h4`
  color: var(--violet);
`;

const HorizLine = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const Role = styled.p`
  font-size: 16px;
  font-weight: 700;
`;

const Job = styled.p`
  color: #6e6e6e;
  font-family: Arial;
  font-size: 14px;
  font-style: italic;
`;

const TeamMem = [
  {
    name: "Ar.Pushparaj",
    photo: "images/img/team/push.webp",
    alt: "NATA Coaching in Trichy by Iarch NATA coaching center in coimbatore",
    role: "CEO - NIT Trichy",
    job: "Senior Architect @Queensland",
    description:
      "We've started NATA coaching to establish a robust foundation for tomorrow's building industry",
    Linkedin: "https://www.linkedin.com/in/pushparajmanoharan/",
    background: "#f26d7d",
  },
  {
    name: "Gnanasambandan S",
    photo: "images/img/team/gnanam.webp",
    alt: "NATA Coaching in Trichy by Iarch NATA coaching center in trichy",
    role: "Project Manager",
    job: "Software Architect @Germany",
    description:
      "Architecture is a profession that encourages you to think differently",
    Linkedin: "",
    background: "#903193",
  },
  {
    name: "Ar.Haribabu",
    photo: "images/img/team/hari.webp",
    alt: "NATA Coaching in Trichy by Iarch NATA coaching center in Madurai",
    role: "Design Lead - NIT Trichy",
    job: "Design Engineer @Bangalore",
    description:
      "Our NATA coaching program ensures accessibility to classes without compromising school academics",
    Linkedin: "https://www.linkedin.com/in/findhari/",
    background: "#17a58e",
  },
  {
    name: "Ar.Sudharshini",
    photo: "images/img/team/sudharshini.webp",
    alt: "NATA Coaching in Trichy by Iarch NATA coaching center in Chennai",
    role: "Academic coordinator @UK",
    job: "Head of Academics @ neramClasses",
    description:
      "I believe, A well-designed curriculum aids in achieving smarter success in the NATA Exam.",
    Linkedin: "https://www.linkedin.com/in/sudharshini-rajan-47a0566b/",
    background: "#e52b23",
  },
  {
    name: "Ar.SriHari",
    photo: "images/img/team/srihari.webp",
    alt: "NATA Coaching in Trichy by Iarch NATA coaching center in anna nagar chennai",
    role: "Creative Partner TCE",
    job: "Practising Architect",
    description:
      "A coaching program that is not affordable for low-income groups is ultimately ineffective for the country.",
    Linkedin: "https://www.linkedin.com/in/srihari-manimaran/",
    background: "#fdcc00",
  },
  {
    name: "Ar.Karthik",
    photo: "images/img/team/karthi.webp",
    alt: "NATA Coaching in Trichy by Iarch NATA coaching center in trichy thillainagar",
    role: "Creative Lead",
    job: "Junior Designer @ neramClasses",
    description:
      "I joined Neram during my college years, which helped support my family even while studying.",
    Linkedin: "",
    background: "#9c0054",
  },
  {
    name: "Ar.Thabis Sithik",
    photo: "images/img/team/thabis.webp",
    alt: "Iarch nata coaching coimbatore",
    role: "Tutor",
    job: "Anna university",
    description:
      "I am a teaching enthusiast who loves to teach, stay in touch with students, and I'm also an architecture nerd.",
    Linkedin: "",
    background: "#ebd800",
  },
  {
    name: "Ar.Ramesh Kanna",
    photo: "images/img/team/ramesh.webp",
    alt: "NATA Coaching in Trichy by aptoin NATA coaching center Coimbatore",
    role: "Content Developer",
    job: "CARE School of Architecture",
    description:
      "I was the first student of NeramClasses, and now I'm also a part of NeramClasses to help students achieve their aspirations for university.",
    Linkedin: "",
    background: "#f7941d",
  },
];

function TitleCard({ name, role, job }) {
  return (
    <>
      <Title className="my-1">{name}</Title>
      <HorizLine />
      <Role className="my-1">{role}</Role>
      <Job className="title">{job}</Job>
    </>
  );
}

const MemImage = styled.img`
  position: relative;
  min-width: 100%;
  -webkit-transform: scale(1) rotate(0) translateY(0);
  -moz-transform: scale(1) rotate(0) translateY(0);
  -o-transform: scale(1) rotate(0) translateY(0);
  -ms-transform: scale(1) rotate(0) translateY(0);
  transform: scale(1) rotate(0) translateY(0);
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  width: 100%;
  max-width: 100%;
`;

const MemPhoto = styled.figure`
  max-width: 65%;

  figcaption {
    border-radius: 10%;
    padding: 50px;
    color: transparent;
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    a:link,
    a:active,
    a:visited {
      color: #fff;
      &:hover {
        color: rgba(255, 255, 255, 0.6);
      }
    }
    p {
      font-size: 16px;
    }
  }
  &:hover {
    figcaption {
      background: ${(props) =>
        props.hoverbackground || "red"}; // Set hover background dynamically
      box-shadow: -15px 16px 14px -11px rgba(0, 0, 0, 0.42) inset;
      align-items: center;
      visibility: visible;
      color: #fff;
      height: 100%;
      z-index: 1;
    }
    img {
      transform: scale(1.1) rotate(1deg) translateY(12px);
      transition: all 0.4s ease-in-out;
      z-index: -1;
    }
    transition: all 0.3s ease-in-out;
  }
`;

const OneTeamCard = ({ item, index }) => {
  return (
    <>
      <MemPhoto
        index={index}
        team={TeamMem}
        className="position-relative overflow-hidden mx-auto p-4"
        hoverbackground={item.background} // Pass hover background dynamically
      >
        <MemImage
          alt={item.alt}
          src={item.photo}
          className="img-responsive"
          loading="lazy"
        />
        <figcaption>
          <p>{item.description}</p>
          {item.Linkedin && (
            <a href={item.Linkedin} target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          )}
        </figcaption>
      </MemPhoto>
      <TitleCard name={item.name} role={item.role} job={item.job} />
    </>
  );
};

function TeamCardIte() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="slider-container container my-5 pb-5">
      <Slider {...settings}>
        {TeamMem.map((item, index) => (
          <div key={index}>
            <OneTeamCard item={item} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default TeamCardIte;
