import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";

function StepOne({
  formData,
  setFormData,
  errors,
  setErrors,
  Gender,
  Class,
  Board,
  Year,
}) {
  // ERRORS

  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    console.log("Selected value:", selectedOption);
  }, [selectedOption]);

  // -----

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.75rem",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", gap: "1.5rem", width: "100%" }}>
        <TextField
          error={Boolean(errors.Sname)}
          fullWidth
          id="standard-basic"
          label={errors.Sname || "Student Name"}
          variant="standard"
          value={formData.Sname}
          onChange={(event) =>
            setFormData({ ...formData, Sname: event.target.value })
          }
        />
        <TextField
          error={Boolean(errors.Fname)}
          fullWidth
          id="standard-basic"
          label={errors.Fname || "Father Name"}
          variant="standard"
          value={formData.Fname}
          onChange={(event) =>
            setFormData({ ...formData, Fname: event.target.value })
          }
        />
      </Box>

      <Autocomplete
        fullWidth
        options={Gender}
        value={formData.Gender}
        onChange={(event, newValue) =>
          setFormData({ ...formData, Gender: newValue })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={errors.Gender || "Gender"}
            variant="standard"
            size="small"
            error={Boolean(errors.Gender)}
          />
        )}
      />
      <Box sx={{ display: "flex", gap: "1.5rem", width: "100%" }}>
        <Autocomplete
          fullWidth
          options={Class}
          value={formData.Class}
          onChange={(event, newValue) =>
            setFormData({ ...formData, Class: newValue })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={errors.Class || "Class"}
              variant="standard"
              size="small"
              error={Boolean(errors.Class)}
            />
          )}
        />
        <Autocomplete
          fullWidth
          options={Board}
          value={formData.Board}
          onChange={(event, newValue) =>
            setFormData({ ...formData, Board: newValue })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label={errors.Board || "Board"}
              variant="standard"
              size="small"
              error={Boolean(errors.Board)}
            />
          )}
        />
      </Box>
      <Autocomplete
        fullWidth
        options={Year}
        value={formData.Year}
        onChange={(event, newValue) =>
          setFormData({ ...formData, Year: newValue })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={errors.Year || "Year"}
            variant="standard"
            size="small"
            error={Boolean(errors.Year)}
          />
        )}
      />
    </Box>
  );
}

export default StepOne;
