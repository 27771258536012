import React from "react";
import styled from "styled-components";
import HeroWavesComponent from "../../components/Sections/WaveAnimate";
import NeramFooter from "../../components/Footer/footer";
import FaqBody from "../../components/Basic components/Faqs/FaqBody";
import ResponsiveAppBar from "../../components/TopNavigationBar/AppBar/AppBarMui";

const PageBackground = styled.div`
  background-color: var(--lightpink);
  position: relative;
`;

const FaqsPage = () => {
  return (
    <PageBackground>
      <ResponsiveAppBar
        variant="opaque"
        showBackButton={false}
        largerScreenTitle="Class Enrollment Application Form"
        smallerScreenTitle="Application Form"
        breadcrumbName={"Application Form"}
        showBreadCrumBar={true}
      />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <FaqBody />

      <div className="position-relative">
        <HeroWavesComponent position="top" bgcolor={"var(--lightpink)"} />

        <NeramFooter />
      </div>
    </PageBackground>
  );
};

export default FaqsPage;
