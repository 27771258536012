import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Stepper, Step } from "@mui/material";

import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HomeIcon from "@mui/icons-material/Home";
import RefreshIcon from "@mui/icons-material/Refresh";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
// STEPS
import StepOne from "./Steps/StepOne";
import StepTwo from "./Steps/StepTwo";
import StepFour from "./Steps/StepFours";
import StepThree from "./Steps/StepThree";
import { database } from "../../../components/Firebase/setup";
import { ref, push, set } from "firebase/database";
import { Link } from "react-router-dom";
import {
  getCurrentDate,
  getCurrentTime,
} from "../../../components/Basic components/DateTime";
import axios from "axios";

// STEPPERS

function CustomStep({ title, onClick, active, step }) {
  return (
    <Step sx={{ padding: "0", flex: "none" }}>
      <Button
        variant={active ? "contained" : "outlined"}
        onClick={onClick}
        sx={{
          textTransform: "none",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "40px",

          ...(active ? { padding: "10px 20px", width: "90px" } : {}),
        }}
      >
        <Typography
          variant="body1"
          sx={{
            alignSelf: "flex-center",
            marginBottom: "auto",
            fontSize: "14px",
            ...(active ? {} : { fontSize: "12px" }),
          }}
        >
          {title}
        </Typography>
      </Button>
    </Step>
  );
}

function StepperExample({ activeStep, handleStep }) {
  if (activeStep === 4) {
    return null; // Render nothing if activeStep is 4
  }

  return (
    <Stepper
      alternativeLabel
      nonLinear
      activeStep={activeStep}
      sx={{ border: "none", alignItems: "center" }}
      connector={false}
    >
      <CustomStep
        title="Details"
        onClick={() => handleStep(0)}
        active={activeStep === 0}
        step={1}
      />
      <CustomStep
        title="Contact"
        onClick={() => handleStep(1)}
        active={activeStep === 1}
        step={2}
      />
      <CustomStep
        title="Payment"
        onClick={() => handleStep(2)}
        active={activeStep === 2}
        step={3}
      />
      <CustomStep
        title="Confirm"
        onClick={() => handleStep(3)}
        active={activeStep === 3}
        step={4}
      />
    </Stepper>
  );
}

// ACTION BUTTONS

function ActionButtonGroup({
  activeStep,
  setActiveStep,
  errors,
  formData,
  setErrors,
  handleSubmit,
}) {
  const validateFields = () => {
    let hasErrors = false;
    const newErrors = { ...errors };

    if (activeStep === 0) {
      if (formData.Sname.trim() === "") {
        newErrors.Sname = "Enter student name";
        hasErrors = true;
      } else if (/\d/.test(formData.Sname)) {
        newErrors.Sname = "Name should not contain numbers";
        hasErrors = true;
      } else {
        newErrors.Sname = "";
      }

      if (formData.Fname.trim() === "") {
        newErrors.Fname = "Enter Father name";
        hasErrors = true;
      } else if (/\d/.test(formData.Fname)) {
        newErrors.Fname = "Name should not contain numbers";
        hasErrors = true;
      } else {
        newErrors.Fname = "";
      }

      if (formData.Gender.trim() === "") {
        newErrors.Gender = "Select Gender";
        hasErrors = true;
      } else {
        newErrors.Gender = "";
      }
      if (formData.Class.trim() === "") {
        newErrors.Class = "Select Class";
        hasErrors = true;
      } else {
        newErrors.Class = "";
      }
      if (formData.Board.trim() === "") {
        newErrors.Board = "Select Board";
        hasErrors = true;
      } else {
        newErrors.Board = "";
      }
      if (formData.Year.trim() === "") {
        newErrors.Year = "Select School completion Year";
        hasErrors = true;
      } else {
        newErrors.Year = "";
      }

      // Add similar validation for other fields
    }

    if (activeStep === 1) {
      if (formData.State.trim() === "") {
        newErrors.State = "Select State you Belong";
        hasErrors = true;
      } else {
        newErrors.State = "";
      }
      if (formData.Country.trim() === "") {
        newErrors.Country = "Select Country you Belong";
        hasErrors = true;
      } else {
        newErrors.Country = "";
      }
      if (formData.Address.trim() === "") {
        newErrors.Address = "Please enter your Address";
        hasErrors = true;
      } else {
        newErrors.Address = "";
      }
      if (formData.ZipCode.trim() === "") {
        newErrors.ZipCode = "Please enter your ZipCode";
        hasErrors = true;
      } else {
        newErrors.ZipCode = "";
      }
      if (formData.Phone.trim() === "") {
        newErrors.Phone = "Please enter your PhoneNumber";
        hasErrors = true;
      } else {
        newErrors.Phone = "";
      }
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (formData.Email.trim() === "") {
        newErrors.Email = "Please enter your Email";
        hasErrors = true;
      } else if (!emailPattern.test(formData.Email.trim())) {
        newErrors.Email = "Please enter a valid email address";
        hasErrors = true;
      } else {
        newErrors.Email = "";
      }
    }

    // Update the errors state with the newErrors object
    setErrors(newErrors);

    if (!hasErrors) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleNext = () => {
    if (validateFields()) {
      setActiveStep((prevStep) => prevStep + 1); // Proceed to the next step
    }
  };

  return (
    <Box sx={{ display: "flex", gap: "1rem", margin: "2rem 0" }}>
      {activeStep !== 0 && (
        <Button
          onClick={() =>
            activeStep === 4
              ? setActiveStep(0)
              : setActiveStep((prevStep) => prevStep - 1)
          }
          variant="outlined"
        >
          {activeStep === 3 ? (
            <>
              <EditIcon /> Edit
            </>
          ) : activeStep === 4 ? (
            "Try Again"
          ) : (
            "Back"
          )}
          {activeStep === 4 ? (
            <RefreshIcon />
          ) : activeStep === 3 ? null : (
            <ChevronLeft />
          )}
        </Button>
      )}
      <Button
        component={activeStep === 4 ? Link : "button"}
        to={activeStep === 4 ? "/" : undefined}
        onClick={activeStep === 3 ? handleSubmit : handleNext}
        variant="contained"
      >
        {activeStep === 3 ? (
          "I confirm"
        ) : activeStep === 4 ? (
          <>
            <HomeIcon /> Home
          </>
        ) : (
          "Next"
        )}
        {activeStep === 3 && <DoneIcon />}
        {activeStep === 3 ? null : <ChevronRight />}
      </Button>
    </Box>
  );
}

// HEADER

const FormHeadTitles = [
  "BasicDetails",
  "PersonalDetails",
  "Confirmation",
  "Payment",
  "ThankYou",
];

function FormHeader({ activeStep }) {
  if (activeStep === 4) {
    return null; // Render nothing if activeStep is 4
  }

  const title = FormHeadTitles[activeStep];
  const stepNumber = activeStep + 1;
  const totalSteps = FormHeadTitles.length;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        color: "var(--violet)",
      }}
    >
      <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
      <Typography
        sx={{ fontSize: "12px" }}
      >{`Step ${stepNumber} of ${totalSteps}`}</Typography>
    </Box>
  );
}

// BODY

function FormBody({
  activeStep,
  formData,
  setFormData,
  errors,
  setErrors,
  Gender,
  Class,
  Board,
  Year,
  State,
  Country,
  tabLabels,
  tabLabelsTwo,
}) {
  // Define a function to render the appropriate step component based on the active step
  const renderStepComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <StepOne
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
            Gender={Gender}
            Class={Class}
            Board={Board}
            Year={Year}
          />
        );
      case 1:
        return (
          <StepTwo
            formData={formData}
            setFormData={setFormData}
            State={State}
            Country={Country}
            errors={errors}
            setErrors={setErrors}
          />
        );
      case 2:
        return (
          <StepThree
            formData={formData}
            setFormData={setFormData}
            tabLabels={tabLabels}
            tabLabelsTwo={tabLabelsTwo}
          />
        );
      case 3:
        return <StepFour formData={formData} setFormData={setFormData} />;
      case 4:
        return <StepFive />;
      default:
        return null;
    }
  };

  return <>{renderStepComponent()}</>;
}

// STEPS

function StepFive({ formData }) {
  const message = `Hello! I have successfully completed the payment process. Here is the screenshot attached.`;
  const phoneNumber = "+919176137043"; // Replace with the recipient's phone number

  const generateWhatsAppLink = () => {
    const encodedMessage = encodeURIComponent(message);
    return `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  };
  const handleWhatsAppClick = () => {
    const whatsappLink = generateWhatsAppLink();
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <Typography>
        Thank you for choosing{" "}
        <Link to={"/"} style={{ color: "var(--pink)", textDecoration: "none" }}>
          neramClasses.com
        </Link>{" "}
        for NATA/JEE2 Coaching Preparation!
        <br /> Together, we'll make your ambition a reality.
        <br />
        <br /> <i>Click the icon below & share your payment receipt.</i>{" "}
        <Button
          variant="contained"
          color="primary"
          startIcon={<WhatsAppIcon />}
          onClick={handleWhatsAppClick}
          size="small"
        >
          Share Receipt
        </Button>
      </Typography>
    </>
  );
}

// DATE and TIME

// INDEX

const FormContent = () => {
  // SUBMIT
  const handleSubmit = () => {
    // Get a reference to the 'formData' node in the database
    const formDataRef = ref(database, "formData");

    // Push the form data to the 'formData' node
    push(formDataRef, formData)
      .then(() => {
        console.log("Form data stored successfully.");
        setActiveStep((prevStep) => prevStep + 1);
      })
      .catch((error) => {
        console.error("Error storing form data:", error);
      });
  };
  // OPTIONS

  const State = ["TamilNadu", "Kerala", "Andhra", "Karnataka"];
  const Country = ["India", "UAE", "OMAN"];

  const tabLabelsTwo = ["Mobile Pay", "UPI ID", "QR Code", "Card Payment"];

  const Gender = ["Male", "Female", "Rather Not to say"];
  const Board = [
    "Matric",
    "Government",
    "CBSE",
    "ICSE",
    "Cambridge",
    "Diploma",
    "Others",
  ];
  const Class = ["12th", "11th", "10th", "12th Completed", "Diploma", "Others"];

  const tabLabels = ["Crash Course", "Regular Course", "One+ Year"]; // Define tab labels

  const currentYear = new Date().getFullYear();
  const Year = [
    ...Array.from({ length: 3 }, (_, index) =>
      (currentYear + index).toString()
    ), // Convert the numbers to strings
    `Before ${currentYear}`,
    "Others",
  ];

  // FIELD INPUT STORE

  const [formData, setFormData] = useState({
    Sname: "",
    Fname: "",
    Gender: Gender[0],
    Class: Class[0],
    Board: Board[0],
    Year: Year[0],
    Address: "",
    ZipCode: "",
    State: State[0],
    Country: Country[0],
    IG: "",
    Phone: "",
    Email: "",
    Course: tabLabels[0],
    Payby: tabLabelsTwo[0],
    date: getCurrentDate(),
    time: getCurrentTime(),
    locationName: "",
  });

  // LOCATION

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getLocationName(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const getLocationName = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json`,
        {
          params: {
            q: `${latitude}+${longitude}`,
            key: "0f2ea0cee8b64644a6548b43a25256fc",
          },
        }
      );
      const location = response.data.results[0].formatted;
      setFormData((prevData) => ({
        ...prevData,
        locationName: location,
      }));
    } catch (error) {
      console.error("Error fetching location name:", error);
    }
  };

  useEffect(() => {
    getLocation();
  }, []); // Run once when the component mounts

  // =-----

  //   ERRORS

  const [errors, setErrors] = useState({
    Sname: "",
    Fname: "",
    Gender: "",
    Class: "",
    Board: "",
    Year: "",
    Address: "",
    ZipCode: "",
    State: "",
    Country: "",
    Phone: "",
    Email: "",
    Course: "",
    Payby: "",
  });

  // MAINTAIN STEPS
  const [activeStep, setActiveStep] = useState(0);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 1.5rem",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          marginTop: "3rem",
          marginBottom: "2rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <StepperExample activeStep={activeStep} handleStep={handleStep} />
      </Box>
      <FormHeader activeStep={activeStep} />
      <FormBody
        activeStep={activeStep}
        formData={formData}
        setFormData={setFormData}
        errors={errors} // Pass errors to FormBody
        setErrors={setErrors} // Pass setErrors to FormBody
        Gender={Gender}
        Class={Class}
        Board={Board}
        Year={Year}
        State={State}
        Country={Country}
        tabLabels={tabLabels}
        tabLabelsTwo={tabLabelsTwo}
      />
      <ActionButtonGroup
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        errors={errors} // Pass errors to ActionButtonGroup
        setErrors={setErrors} // Pass setErrors to ActionButtonGroup
        formData={formData}
        handleSubmit={() => handleSubmit(setActiveStep)}
      />
    </Box>
  );
};

export default FormContent;
