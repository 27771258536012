import { Add, Remove } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputBase,
  InputLabel,
  Typography,
  Modal,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";

const ScrollableArea = styled.div`
  overflow-y: scroll;
  max-height: 16rem;
  @media (max-width: 567px) {
    max-height: 26rem;
  }
`;

const ListMui = styled.div`
  color: var(--grey900);
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    background-color: var(--grey300);
  }
`;

const CustomInput = styled(InputBase)`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  &:focus {
    border: 1px solid #ccc;
  }
`;

const CustomLabel = styled(InputLabel)`
  color: ${(props) => (props.active ? "blue" : "grey")};
  position: absolute;
  top: ${(props) => (props.active ? "-10px" : "50%")};
  left: 8px;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 4px;
  transition: all 0.2s;
`;

const SelectCol = ({ content, onSelectionChange }) => {
  const [inputValue, setInputValue] = useState("");
  const [focused, setFocused] = useState(false);
  const [selectedContent, setSelectedContent] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddCollege = (content) => {
    const newSelectedContent = [...selectedContent, content];
    setSelectedContent(newSelectedContent);
    onSelectionChange(newSelectedContent);
  };

  const handleRemoveCollege = (content) => {
    const newSelectedContent = selectedContent.filter((c) => c !== content);
    setSelectedContent(newSelectedContent);
    onSelectionChange(newSelectedContent);
  };

  const filteredContent = content.filter(
    (college) =>
      college.toLowerCase().includes(inputValue.toLowerCase()) &&
      !selectedContent.includes(college)
  );

  return (
    <div>
      <Box>
        <div>
          <FormControl fullWidth>
            <CustomLabel active={focused || inputValue.length > 0}>
              Search College
            </CustomLabel>
            <CustomInput
              value={inputValue}
              onChange={handleInputChange}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
          </FormControl>
          <div className="popupBody row m-0">
            <div className="col p-0">
              <Box
                className="d-flex align-items-center"
                height={"2rem"}
                borderBottom={"1px solid var(--grey400)"}
                borderRight={"1px solid var(--grey400)"}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: "var(--grey800)",
                    padding: "0 0 0 1rem",
                  }}
                >
                  {filteredContent.length} Available Colleges
                </Typography>
              </Box>
              <ScrollableArea>
                {filteredContent.map((college) => (
                  <ListMui key={college}>
                    <IconButton onClick={() => handleAddCollege(college)}>
                      <Add />
                    </IconButton>
                    {college}
                  </ListMui>
                ))}
              </ScrollableArea>
            </div>
            <div className="col p-0">
              <Box
                className="d-flex align-items-center"
                height={"2rem"}
                borderBottom={"1px solid var(--grey400)"}
                borderRight={"1px solid var(--grey400)"}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: "var(--grey800)",
                    padding: "0 0 0 1rem",
                  }}
                >
                  {selectedContent.length} Selected Colleges
                </Typography>
              </Box>
              <ScrollableArea>
                {selectedContent.map((college) => (
                  <ListMui key={college}>
                    <IconButton onClick={() => handleRemoveCollege(college)}>
                      <Remove />
                    </IconButton>
                    {college}
                  </ListMui>
                ))}
              </ScrollableArea>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default SelectCol;
