import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

const colleges = [
  {
    clgName: "Anna University",
    clgCity: "Chennai",
    imgLink: "./images/asksenior/colleges/Anna_University_Logo.webp",
    link: "https://www.annauniv.edu/sap/",
  },
  {
    clgName: "RATHINAM",
    clgCity: "Coimbatore",
    imgLink: "./images/asksenior/colleges/Rathinam_coimbatore.webp",
    link: "https://rathinamarchitecture.com/",
  },
  {
    clgName: "CEPT",
    clgCity: "Ahmedabad",
    imgLink: "./images/asksenior/colleges/Cept_Ahmedaba.webp",
    link: "https://cept.ac.in/",
  },
  {
    clgName: "Periyar Maniammai",
    clgCity: "Thanjavur",
    imgLink: "./images/asksenior/colleges/periyar.webp",
    link: "https://www.pmu.edu/department-of-architecture/",
  },
  {
    clgName: "Dhanalakshmi Srinivasan",
    clgCity: "Tiruchirappalli",
    imgLink: "./images/asksenior/colleges/DSU.webp",
    link: "https://dsuniversity.ac.in/architecture",
  },
  {
    clgName: "McGAN'S",
    clgCity: "Ooty",
    imgLink: "./images/asksenior/colleges/McGAN'S_ooty.webp",
    link: "https://www.mcgansarch.com/",
  },
  {
    clgName: "Prime",
    clgCity: "Nagapattinam",
    imgLink: "./images/asksenior/colleges/PRIME_Nagapattinam.webp",
    link: "https://primearchitecture.in/",
  },
  {
    clgName: "karpagam University",
    clgCity: "Coimbatore",
    imgLink: "./images/asksenior/colleges/karpagamcollege_Coimbatore.webp",
    link: "https://kahedu.edu.in/academics/architecture/department-of-architecture/",
  },
  {
    clgName: "PAPNI",
    clgCity: "Kancheepuram",
    imgLink: "./images/asksenior/colleges/papni.webp",
    link: "https://papniarch.ac.in/",
  },
  {
    clgName: "Christ",
    clgCity: "Bangaluru",
    imgLink: "./images/asksenior/colleges/christ_bangalore.webp",
    link: "https://christuniversity.in/departments/bangalore%20kengeri%20campus/school%20of%20architecture/school%20of%20architecture",
  },
  {
    clgName: "MEASI",
    clgCity: "Chennai",
    imgLink: "./images/asksenior/colleges/MEASI_chennai.webp",
    link: "https://measiarch.in/",
  },
  {
    clgName: "MSRIT",
    clgCity: "Bangaluru",
    imgLink: "./images/asksenior/colleges/ms_Ramaiah_bangalore.webp",
    link: "https://www.msrit.edu/",
  },
  {
    clgName: "NIT",
    clgCity: "Trichy",
    imgLink:
      "./images/asksenior/colleges/National_Institute_of_Technology_Trichy_Logo.webp",
    link: "https://www.nitt.edu/",
  },
  {
    clgName: "PSG",
    clgCity: "Coimbatore",
    imgLink: "./images/asksenior/colleges/PSG_IAP_coimbatore.webp",
    link: "https://www.psgiap.ac.in/",
  },
  {
    clgName: "SPA",
    clgCity: "Delhi",
    imgLink: "./images/asksenior/colleges/SPA_Delhi.webp",
    link: "https://spa.ac.in/",
  },
  {
    clgName: "SRM",
    clgCity: "Chennai",
    imgLink: "./images/asksenior/colleges/SRM_CHENNAI.webp",
    link: "https://www.srmist.edu.in/department/department-of-architecture/",
  },
  {
    clgName: "TCE",
    clgCity: "Madurai",
    imgLink:
      "./images/asksenior/colleges/Thiagarajar_College_of_Engineering_logo_MADURAI.webp",
    link: "https://www.tce.edu/academics/departments/architecture",
  },
  {
    clgName: "VIT",
    clgCity: "Vellore",
    imgLink: "./images/asksenior/colleges/VIT_Vellore.webp",
    link: "https://admissions.vit.ac.in/",
  },
  {
    clgName: "VNIT",
    clgCity: "Nagpur",
    imgLink: "./images/asksenior/colleges/VNIT_logo_NAGPUR.webp",
    link: "https://vnit.ac.in/undergraduate-programe/",
  },
  {
    clgName: "SPA",
    clgCity: "Bhopal",
    imgLink: "./images/asksenior/colleges/SPA-Bhopal.webp",
    link: "https://spabhopal.ac.in/",
  },
  {
    clgName: "SPA",
    clgCity: "Vijayawada",
    imgLink:
      "./images/asksenior/colleges/School_of_Planning_and_Architecture,_Vijayawada_Logo.webp",
    link: "https://www.spav.ac.in/",
  },
];

const OneColgComp = ({ clgName, clgCity, imgLink, link }) => (
  <a
    href={link}
    target="_blank"
    style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
  >
    <Box display="flex" alignItems="center" gap={2} mx={5}>
      <img src={imgLink} alt={clgName} style={{ maxWidth: "50px" }} />
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography
          variant="p"
          component="b"
          lineHeight={"1"}
          fontWeight={"700"}
        >
          {clgName}
        </Typography>
        <Typography
          variant="p"
          component="i"
          lineHeight={"1"}
          fontWeight={"400"}
        >
          {clgCity}
        </Typography>
      </Box>
    </Box>
  </a>
);

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const CollegeScrollContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`;

const ScrollContent = styled.div`
  display: flex;
  animation: ${scroll} linear infinite;
  width: max-content;
  &:hover {
    animation-play-state: paused;
  }
`;

const CollegeScroll = () => {
  const scrollContentRef = useRef(null);
  const [duration, setDuration] = useState("90s"); // Default duration

  useEffect(() => {
    const updateDuration = () => {
      if (scrollContentRef.current) {
        const scrollWidth = scrollContentRef.current.scrollWidth;
        const viewWidth = scrollContentRef.current.clientWidth;
        const totalWidth = scrollWidth + viewWidth;
        const speed = 100; // Change this value to adjust the speed (px per second)
        const newDuration = `${totalWidth / speed}s`;
        setDuration(newDuration);
      }
    };

    updateDuration();
    window.addEventListener("resize", updateDuration);

    return () => {
      window.removeEventListener("resize", updateDuration);
    };
  }, []);

  // Duplicate the colleges array to create a seamless loop
  const doubledColleges = [...colleges, ...colleges];

  return (
    <CollegeScrollContainer>
      <ScrollContent
        ref={scrollContentRef}
        style={{ animationDuration: duration }}
      >
        {doubledColleges.map((college, index) => (
          <OneColgComp
            key={index}
            clgName={college.clgName}
            clgCity={college.clgCity}
            imgLink={college.imgLink}
            link={college.link}
          />
        ))}
      </ScrollContent>
    </CollegeScrollContainer>
  );
};

export default CollegeScroll;
