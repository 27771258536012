import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import MyButton from "../../../components/Basic components/Buttons/Buttons";

function JoinPremium({ handleOpen, handleClose, open }) {
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "var(--pink)",
            color: "white",
            height: "3rem",
          }}
        >
          Join Premium
          <IconButton onClick={handleClose}>
            <Close sx={{ color: "white" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            paddingBottom: "0",
            paddingTop: "2rem",
            backgroundColor: "var(--lightpink)",
          }}
        >
          <Typography variant="body1">
            Our team has invested its time and dedication in creating these
            study materials. Upon joining the neramClasses Batch, you will gain
            access to this and thousands of other study materials through our
            study app. Click the button below to join the batch.
          </Typography>
        </DialogContent>

        <Link
          to="/NATA_Application_Form_2025"
          style={{
            display: "flex",
            justifyContent: "center",
            textDecoration: "none",
            padding: "2rem 0",
            backgroundColor: "var(--lightpink)",
          }}
        >
          <MyButton variant="contained">Get access</MyButton>
        </Link>
      </Dialog>
    </div>
  );
}

export default JoinPremium;
