import React from "react";
import { Typography, Button, IconButton } from "@mui/material";
import styled from "styled-components";
import ChatIcon from "@mui/icons-material/Chat";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import VerticalCarousel from "./VerticalSlider";
import { Call } from "@mui/icons-material";

const CityArray = [
  // TRICHY
  {
    cityName: "Trichy",
    iframe:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15675.212535195249!2d78.6870475!3d10.8263726!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baaf56cceaec9f7%3A0x2a83793658d0d8d4!2sNeram%20-%20NATA%20coaching%20centre%20in%20trichy!5e0!3m2!1sen!2sin!4v1713864876794!5m2!1sen!2sin",
    cityDescription:
      "NeramClasses.com stands as India's pioneering online platform for comprehensive NATA and JEE Paper 2 exam preparation. Offering the most affordable rates, we bring the nation's top-tier coaching right to your fingertips. With our roots deeply embedded in Trichy, our journey began after graduating from NIT Trichy, where we recognized the city's immense potential for nurturing architectural talent. Trichy holds a special place in our hearts as our lucky city, where we established our first institute.Our platform caters to every aspect of NATA preparation, from exam dates and application details for 2024 to syllabus and question paper patterns. Whether you seek coaching institutes nearby or prefer online learning, we provide comprehensive support to navigate the entrance process seamlessly. Our focus extends beyond mere coaching; we guide aspiring architects towards top colleges nationwide.Join NeramClasses.com today for unmatched expertise, personalized guidance, and unbeatable affordability in your journey towards architectural excellence.",
    address:
      "76-O, Sri Jothi Complex, 2nd Floor NEE road, Thillai nagar,, tiruchirapalli, Tamil Nadu 620018",
    img1: "images/img/center-images/Chennai/Nata-Coaching-center-in-Chennai.webp",
    img2: "images/img/center-images/trichy/Perspective_NATA_Coaching.webp",
    img3: "images/img/center-images/coimbatore/Nata coaching center in coimbatore.webp",
    img4: "images/img/center-images/Madurai/nata coaching centre in salem.webp",
    img5: "images/img/center-images/trichy/Trichy_NATA_Coaching.webp",
    img6: "images/img/center-images/trichy/Best_NATA_Coaching_center_in_trichy.webp",
    pageLink: "https://maps.app.goo.gl/7SpyK1wrETnnE2aZ9",
  },
  // CHENNAI
  {
    cityName: "Chennai",
    iframe:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15547.473693321454!2d80.2115881!3d13.0440461!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526717a5e80953%3A0x5e2203773809c735!2sNata%20Coaching%20center%20in%20Chennai%20-%20neramClasses!5e0!3m2!1sen!2sin!4v1713865439908!5m2!1sen!2sin",
    cityDescription:
      "Neram NATA coaching class in Chennai has a proven track record of producing NATA and JEE 2 toppers consistently year after year. In 2024, a student from Neram achieved the remarkable feat of securing the first rank in the JEE Exam nationwide. At neramClasses.com, our primary focus is on providing systematic NATA exam preparation that seamlessly integrates with school board exam preparations. Our mission is to empower aspiring architects to gain admission to the top architecture colleges across the nation.",
    address:
      "2nd floor, PT Rajan Rd, Sector 13,, Ashok Nagar, Landmark : Near Lakshmi shruthi signal, Chennai, Tamil Nadu 600078",
    img1: "images/img/center-images/Madurai/nata coaching centres in tamilnadu Madurai.webp",
    img2: "images/img/center-images/Madurai/NATA-topper-varsha.webp",
    img3: "images/img/center-images/Madurai/nata coaching centres in Madurai.webp",
    img4: "images/img/center-images/Madurai/nata-topper.webp",
    img5: "images/img/center-images/trichy/Trichy_NATA_Coaching.webp",
    img6: "images/img/center-images/trichy/Best_NATA_Coaching_center_in_trichy.webp",
    pageLink: "https://maps.app.goo.gl/TQ1NDnFoverLeisd6",
  },
  // COIMBATORE
  {
    cityName: "Coimbatore",
    iframe:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15664.741362337923!2d76.9409527!3d11.0247169!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba859cbc7ffdbdd%3A0x8c5d0b991ad74a58!2sNeram%20-%20NATA%20Coaching%20centre%20in%20coimbatore!5e0!3m2!1sen!2sin!4v1715139782923!5m2!1sen!2sin",
    cityDescription:
      "NeramClasses.com introduces an innovative approach to NATA and JEE Paper 2 exam preparation, now available in Coimbatore. As pioneers in online learning, we offer comprehensive coaching at an unbeatable price. Our journey began post-graduation from NIT Trichy, where we identified Coimbatore's potential for nurturing architectural talent. Coimbatore, our fortunate city, witnessed the establishment of our inaugural institute.Our platform covers all aspects of NATA preparation, including 2024 exam dates, application details, syllabus, and question paper patterns. Whether you seek nearby coaching centers or prefer online classes, we provide comprehensive support. Beyond coaching, we guide aspirants towards top colleges nationwide.Join NeramClasses.com today for personalized guidance, unmatched expertise, and affordability, as you embark on your architectural journey in Coimbatore.",
    address:
      "10/31 , Bharathi park, 5th cross, saibaba colony, coimbatore, Tamil Nadu 641011",
    img1: "images/img/center-images/tirupur/nata coaching class in tirupur.webp",
    img2: "images/img/center-images/coimbatore/Nata coaching class.webp",
    img3: "images/img/center-images/coimbatore/Online nata exam class.webp",
    img4: "images/img/center-images/coimbatore/nata.webp",
    img5: "images/img/center-images/trichy/Trichy_NATA_Coaching.webp",
    img6: "images/img/center-images/trichy/Best_NATA_Coaching_center_in_trichy.webp",
    pageLink: "https://maps.app.goo.gl/NXdEDa3LpAEAKKbAA",
  },
  // MADURAI
  {
    cityName: "Madurai",
    iframe:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15721.298023113492!2d78.0986758!3d9.9069078!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00cf27be3cb6a3%3A0x1d4c62df8e46b377!2sNeram%20classes%20NATA%20coaching%20Madurai!5e0!3m2!1sen!2sin!4v1715140148106!5m2!1sen!2sin",
    cityDescription:
      "Discover the next level of NATA and JEE Paper 2 exam preparation with NeramClasses.com, now available in Madurai. As pioneers in online education, we redefine affordability and excellence. Originating from NIT Trichy, our journey led us to Madurai, a city brimming with architectural potential, where we established our flagship institute.Unlock the full potential of your architectural aspirations with our comprehensive platform. From crucial exam dates and application details for 2024 to in-depth syllabus coverage and question paper insights, we've got you covered. Whether you prefer the convenience of online learning or the engagement of local coaching centers, our support extends to every corner of Madurai.Join NeramClasses.com today and embark on a journey fueled by personalized guidance, unwavering expertise, and unbeatable affordability as you strive for architectural excellence in Madurai.",
    address:
      "2/401, IInd Floor, Vasanth Nagar, Palangantham, Madurai, Tamil Nadu 625003",
    img1: "images/img/center-images/Madurai/NATA-class-meet.webp",
    img2: "images/img/center-images/Madurai/nata-1.webp",
    img3: "images/img/center-images/Madurai/nata.webp",
    img4: "images/img/center-images/Madurai/nata coaching centre madurai.webp",
    img5: "images/img/center-images/Madurai/nata_coaching.webp",
    img6: "images/img/center-images/trichy/Best_NATA_Coaching_center_in_trichy.webp",
    pageLink: "https://maps.app.goo.gl/yPCpJWZqQ9XjkfcHA",
  },
  // PUDUKKOTTAI
  {
    cityName: "Pudukkottai",
    iframe:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15696.778012829058!2d78.7928937!3d10.4061213!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0079b1ce7c9045%3A0x43defef4b8af6b71!2sNeramClasses%20-%20NATA%20Coaching%20center%20in%20Pudukkottai!5e0!3m2!1sen!2sin!4v1713866696691!5m2!1sen!2sin",
    cityDescription:
      "Welcome to a new era of NATA and JEE Paper 2 exam preparation with NeramClasses.com, now available in Pudukkottai. As pioneers in online education, we redefine affordability and excellence. Emerging from NIT Trichy, our journey led us to Pudukkottai, a city rich in architectural potential, where we established our flagship institute.Unleash your architectural potential with our comprehensive platform. From vital exam dates and application details for 2024 to extensive syllabus coverage and insightful question paper analysis, we provide all-round support. Whether you prefer the flexibility of online learning or the engagement of local coaching centers, our assistance spans across Pudukkottai. Join NeramClasses.com today and embark on a journey fueled by personalized guidance, unwavering expertise, and unbeatable affordability as you strive for architectural excellence in Pudukkottai.",
    address:
      "10-11 Deivanai nagar near, Balannagar Near,, Karuvappillayan gate, Nathampannai, Pudukkottai, 622203, near Ganapathy Floor mill, Pudukkottai, Tamil Nadu 622003",
    img1: "images/img/center-images/trichy/nata.webp",
    img2: "images/img/center-images/trichy/best-nata-coaching.webp",
    img3: "images/img/center-images/trichy/nata coaching online free.webp",
    img4: "images/img/center-images/trichy/nata2.webp",
    img5: "images/img/center-images/trichy/Trichy_NATA_Coaching.webp",
    img6: "images/img/center-images/trichy/Best_NATA_Coaching_center_in_trichy.webp",
    pageLink: "https://maps.app.goo.gl/pVKighoSK9MyLRFM7",
  },
  // TIRUPPUR
  {
    cityName: "Tiruppur",
    iframe:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7829.943083049672!2d77.32681357770997!3d11.115497399999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba906a3b44f97af%3A0xbac7eceb3feac7f9!2sEnviron%20Architecture!5e0!3m2!1sen!2sin!4v1715140391032!5m2!1sen!2sin",
    cityDescription:
      "Embark on your journey to success with NeramClasses.com, now serving Tiruppur with top-notch NATA and JEE Paper 2 exam preparation. As leaders in online education, we redefine affordability and excellence. Stemming from NIT Trichy, our venture reached Tiruppur, a city teeming with architectural talent, where we established our flagship institute.Unleash your architectural prowess with our comprehensive platform. From essential exam dates and 2024 application details to thorough syllabus coverage and strategic question paper insights, we provide holistic support. Whether you opt for the convenience of online learning or the personal touch of local coaching centers, our assistance extends throughout Tiruppur.Join NeramClasses.com today and embark on a journey driven by personalized guidance, unwavering expertise, and unparalleled affordability as you strive for architectural brilliance in Tiruppur.",
    address: "7(1), Ramaiah Colony, West, 4th St, Tiruppur, Tamil Nadu 641602",
    img1: "images/img/center-images/tirupur/nata_class_tirupur.webp",
    img2: "images/img/center-images/tirupur/nata_near_me.webp",
    img3: "images/img/center-images/tirupur/nata1 (1).webp",
    img4: "images/img/center-images/tirupur/nata1 (2).webp",
    img5: "images/img/center-images/trichy/Trichy_NATA_Coaching.webp",
    img6: "images/img/center-images/trichy/Best_NATA_Coaching_center_in_trichy.webp",
    pageLink: "https://maps.app.goo.gl/DGj4w2PKXxdxziDH6",
  },
  // TAMBARAM
  {
    cityName: "Tambaram",
    iframe:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.737122113342!2d80.121314!3d12.960057!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525fe912e01c05%3A0x5be3117babf90eb!2sNeram%20-%20NATA%20coaching%20center%20in%20Tambaram%20%7C%20Kanchipuram!5e0!3m2!1sen!2sin!4v1715140458043!5m2!1sen!2sin",
    cityDescription:
      "Step into a realm of excellence with NeramClasses.com, now available in Tambaram, offering unparalleled NATA and JEE Paper 2 exam preparation. As pioneers in online education, we redefine affordability and excellence. Originating from NIT Trichy, our venture expanded to Tambaram, a hub of architectural talent, where we set up our flagship institute.Unlock your architectural potential with our comprehensive platform. From crucial exam dates and 2024 application details to extensive syllabus coverage and strategic question paper analysis, we provide comprehensive support. Whether you prefer the flexibility of online learning or the engagement of local coaching centers, our assistance spans across Tambaram.Join NeramClasses.com today and embark on a journey guided by personalized mentorship, unwavering expertise, and unmatched affordability as you strive for architectural greatness in Tambaram.",
    address:
      "FC, Block 10, Jain alpine meadows, Thiruneermalai, chennai, Tamil Nadu 600044",
    img1: "images/img/center-images/chennai/nata-coaching-centres-in-tamilnadu.webp",
    img2: "images/img/center-images/chennai/nata-coaching-classes-fees.webp",
    img3: "images/img/center-images/chennai/nata-coaching-centres-in-anna-nagar.webp",
    img4: "images/img/center-images/chennai/nata.webp",
    img5: "images/img/center-images/trichy/Trichy_NATA_Coaching.webp",
    img6: "images/img/center-images/trichy/Best_NATA_Coaching_center_in_trichy.webp",
    pageLink: "https://maps.app.goo.gl/rXfeJDPgxEyLvnyf8",
  },
];

const CardDesign = styled.div`
  background-color: var(--violet);
  border-radius: 24px;
  overflow: hidden;
  @media (max-width: 768px) {
  }
`;

const HeadTagCity = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 10rem;
  color: white;
  background-image: var(--gradyelloworange);
  padding: 0.5rem 2rem;
  border-radius: 24px 0;
  text-shadow: 2px 2px 4px rgb(0 0 0 / 56%);
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5rem 1rem 3rem 3rem;
  @media (max-width: 768px) {
    padding: 2rem;
    justify-content: center;
  }
`;

const LeftContainer = styled.div`
  height: 100%;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const PurpleContainer = styled.div`
  padding: 0;
  @media (max-width: 576px) {
    height: 100%;
  }
`;

const TopDescription = styled.div`
  outline: 2px solid var(--violet);
  outline-offset: 8px;
  border-radius: 24px 24px 0 0;
  padding: 2rem;
`;

const CityDesign = () => {
  return (
    <div>
      {CityArray.map((city) => (
        <div key={city.cityName} style={{ marginBottom: "4rem" }}>
          {" "}
          {/* Wrap elements with a single parent element */}
          <TopDescription>
            <div>
              <Typography
                variant="body1"
                style={{ color: "var(--violet)", marginBottom: "1rem" }}
              >
                <b>India's Best NATA Coaching center now in {city.cityName}</b>
              </Typography>
              <Typography variant="body2">{city.cityDescription}</Typography>
            </div>
          </TopDescription>
          <CardDesign className="row flex-column-reverse flex-md-row position-relative">
            <HeadTagCity>
              <LocationOnIcon
                style={{
                  filter: "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.56))",
                }}
              />
              <b className="ms-1">{city.cityName}</b>
            </HeadTagCity>
            <PurpleContainer className="col-md-5">
              <LeftContainer>
                <TextContainer>
                  <NataCoachingCenter city={city} />
                  <div className="my-3 my-md-0">
                    <SocialMediaButtons city={city.cityName} />
                  </div>
                </TextContainer>
              </LeftContainer>
            </PurpleContainer>
            <div className="col-md-2">
              <VerticalCarousel
                slides={[
                  city.img1,
                  city.img2,
                  city.img3,
                  city.img4,
                  city.img5,
                  city.img6,
                ]}
              />
            </div>
            <div className="col-md-5 p-0 map">
              <iframe
                style={{ width: "100%", height: "100%", border: "none" }}
                src={city.iframe}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </CardDesign>
        </div>
      ))}
    </div>
  );
};

const NataCoachingCenter = ({ city }) => {
  return (
    <div>
      <Typography
        variant="h5"
        style={{
          fontSize: "14px",
          color: "var(--yellow)",
          marginBottom: "1rem",
        }}
      >
        <b>NATA coaching center in {city.cityName}</b>
      </Typography>
      <Typography
        variant="body1"
        style={{ fontSize: "12px", color: "white", marginBottom: "0.5rem" }}
      >
        <strong>Neram NATA coaching center in {city.cityName}</strong> is the
        best and affordable online & offline integrated NATA coaching center
        located in {city.address}.
      </Typography>
      <Typography
        variant="body1"
        style={{
          fontSize: "12px",
          color: "var(--yellow)",
          fontStyle: "italic",
        }}
      >
        Initiated by IIT & NIT Alumnus Architects
      </Typography>
      <a href={city.pageLink} style={{ color: "#ff7112", fontSize: "14px" }}>
        <i>Visit us</i>
      </a>
      <br />
      <a
        href="tel:+919176137043"
        style={{ color: "#ff7112", fontSize: "14px" }}
      >
        Call us - 91761 37043 <Call />
      </a>
    </div>
  );
};

const SocialMediaButtons = ({ city }) => {
  // Whatsapp
  const handleClick = () => {
    // WhatsApp number to which you want to send the message
    const phoneNumber = "+919176137043"; // Replace with your WhatsApp number

    // Message to be included in the WhatsApp chat
    const message = `Hello! I'm contacting you from the neramClasses.com WebApp, I would like to join neramClasses for my NATA preparation in ${city}.`;

    // Encoding the message for use in the URL
    const encodedMessage = encodeURIComponent(message);

    // Constructing the WhatsApp URL with the phone number and message
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

    // Opening the WhatsApp URL in a new tab/window
    window.open(whatsappUrl, "_blank");

    // Log the action
    console.log("Opening WhatsApp chat...");
  };

  // --------
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {/* Chat Button */}
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        startIcon={<ChatIcon />}
        style={{
          marginRight: "8px",
          backgroundImage: "var(--gradyelloworange)",
          padding: "0.5rem 2rem",
        }}
      >
        Chat
      </Button>

      {/* Instagram Button */}
      <IconButton
        color="primary"
        aria-label="instagram"
        onClick={() => {
          window.open("https://www.instagram.com/", "_blank");
        }}
        style={{ marginRight: "8px", color: "white" }}
      >
        <InstagramIcon />
      </IconButton>
      {/* YouTube Button */}
      <IconButton
        color="primary"
        aria-label="youtube"
        onClick={() => {
          window.open("https://www.youtube.com/", "_blank");
        }}
        style={{ color: "white" }}
      >
        <YouTubeIcon />
      </IconButton>
    </div>
  );
};

export default CityDesign;
