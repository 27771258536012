import React from "react";

const CopyRight = () => {
  return (
    <div style={{ paddingBottom: "3rem" }}>
      <hr />
      <div>
        © Copyright
        <span>
          <strong> neram</strong>classes
        </span>
        . All Rights Reserved
      </div>
      <div className="credits">
        Designed by{" "}
        <a href="/" style={{ color: "var(--yellow)", textDecoration: "none" }}>
          TeamNeram
        </a>
      </div>
    </div>
  );
};

export default CopyRight;
