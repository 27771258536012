import { styled, css, keyframes } from "styled-components";

const PlayButton1 = styled.img`
  right: 0rem;
  bottom: 1rem;
  height: 55px;
`;

function PlayButton() {
  <PlayButton1 src="images/img/iconset/nav/ic-play.svg" alt="NATA videos" />;
}

const ButtonStyle = css`
  width: ${(props) => (props.width ? props.width : "170px")};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 20px;
  transition: 0.2s;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s ease;
`;

const SolidButton = styled.button`
  border: 0;
  ${ButtonStyle}

  ${(props) => (props.rounded === "full" ? "border-radius: 9999px;" : "")}
  ${(props) => (props.rounded === "partial" ? "border-radius: 10px;" : "")}
  ${(props) => (props.rounded === "solid" ? "border-radius: 0;" : "")}

  background: ${(props) => (props.color ? props.color : "var(--pink)")};
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background: #fff;
    color: ${(props) => (props.color ? props.color : "var(--pink)")};
    border: 2px solid ${(props) => (props.color ? props.color : "var(--pink)")};
    box-shadow: none;
  }
`;

const SolidButton2 = styled.button`
  border: 0;
  cursor: pointer;
  border-radius: 5px;
  width: ${(props) =>
    props.width ? props.width : "6rem"}; /* Set default width to 6rem */
  ${(props) => (props.rounded === "full" ? "border-radius: 9999px;" : "")}
  ${(props) => (props.rounded === "partial" ? "border-radius: 10px;" : "")}
  ${(props) => (props.rounded === "solid" ? "border-radius: 0;" : "")}

  background: ${(props) => (props.color ? props.color : "var(--pink)")};
  color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    box-shadow: none;
  }
`;

const OutlineButton = styled.button`
  ${ButtonStyle}
  background: transparent;
  color: ${(props) => props.textColor || "#fff"};
  border: 2px solid ${(props) => props.borderColor || "#fff"};

  &:hover {
    background: ${(props) => props.borderColor || "#fff"};
    color: ${(props) => props.hoverTextColor || "var(--pink)"};
  }
`;

const OutlineButton2 = styled.button`
  border: 0;
  cursor: pointer;
  border-radius: 5px;
  background: transparent;
  color: ${(props) => props.textColor || "#fff"};
  border: 2px solid ${(props) => props.borderColor || "#fff"};
  width: ${(props) => (props.width ? props.width : "6rem")};

  &:hover {
    background: ${(props) => props.borderColor || "#fff"};
    color: ${(props) => props.hoverTextColor || "var(--pink)"};
  }
`;

const shinyBtnAnimation = keyframes`
  0% {
    transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    transform: scale(50) rotate(45deg);
    opacity: 0;
  }
`;

// Styled component for HighlightButton
const HighlightButton = styled.button`
  width: 170px;
  font-family: "Roboto", sans-serif;
  border-radius: 999px;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1px;
  border: none;
  background: linear-gradient(103deg, rgb(251 33 134) 0%, rgb(76 234 139) 100%);
  color: #fff;
  overflow: hidden;
  padding: 10px 20px;
  white-space: nowrap;
  transition: all 0.3s ease;
  position: relative; /* Necessary for absolute positioning of ::before pseudo-element */

  &:hover {
    text-decoration: none;
    color: #fff;
    opacity: 0.7;
  }

  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background: #fff;
    animation: ${shinyBtnAnimation} 3s ease-in-out infinite; /* Apply the animation here */
  }

  &:active {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
      -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
      inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
      inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
  }
`;

// Define keyframes for the animation

export {
  SolidButton,
  SolidButton2,
  OutlineButton,
  OutlineButton2,
  HighlightButton,
  PlayButton,
};
