import React, { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Modal, Box, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { VideoIframe } from "../Achievements/YoutubeCarousel/YtCarousel";
import {
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../components/Basic components/CarouselArrow";

const ParentImage = styled.img`
  max-width: 150px;
`;

const PlayButton = styled.img`
  position: absolute;
  right: 0rem;
  bottom: 1rem;
  height: 55px;
  cursor: pointer;
  transition: transform 0.3s ease;

  :hover {
    transform: scale(1.2);
  }
`;

const TestimonialDescription = styled.p`
  font-size: 13px;
  line-height: 20px;
  color: #333;
`;

const TestimonialName = styled.h5`
  font-size: 16px;
  font-weight: 700;
`;

const CollegeName = styled.p`
  font-size: 12px;
  color: #666;
`;

const ParentsContent = [
  {
    imagelink: "images/img/parents/1x/Asset%203.webp",
    imagealt: "NATA topper 2024",
    imageloading: "lazy",
    buttonlink: "https://www.youtube.com/embed/tumZcpz4N34", // Route path for the button
    description:
      "Our son's ability to draw thing from memory has improved because of Neram Classes",
    identity: "Mother of Vishnu",
    clgname: "Anna university",
  },
  {
    imagelink: "images/img/parents/1x/jagdeesh%20mom.webp",
    imagealt: "NATA Online class 2024 ",
    imageloading: "lazy",
    buttonlink: "https://www.youtube.com/embed/UeTSMcT6Tbs", // Route path for the button
    description:
      "Our son pursuing his dream career Architecture in NIT Trichy because of Neram Classes",
    identity: "Mother of Jagdesh",
    clgname: "NIT | Trichy",
  },
  {
    imagelink: "images/img/parents/1x/shakthi-mom.webp",
    imagealt: "NATA Class 2024",
    imageloading: "lazy",
    buttonlink: "https://www.youtube.com/embed/i82xUw5-DY8", // Route path for the button
    description:
      "My son has never went for any tuitions regularly but he never skipped any of neram classes",
    identity: "Mother of Shakthi",
    clgname: "TCE | Madurai",
  },
  {
    imagelink: "images/img/parents/1x/karthi-mom.webp",
    imagealt: "NATA Coaching",
    imageloading: "lazy",
    buttonlink: "https://www.youtube.com/embed/5mTYMdVPBVY", // Route path for the button
    description:
      "People from neram is more than a teacher for my son Karthi. They are in touch with us.",
    identity: "Mother of Karthi",
    clgname: "TCE | Madurai",
  },
  {
    imagelink: "images/img/parents/1x/Asset%201.webp",
    imagealt: "NATA topper 2024",
    imageloading: "lazy",
    buttonlink: "https://www.youtube.com/embed/XUfYcyMIshE", // Route path for the button
    description:
      "NERAM is the best Architecture coaching center guided my daughter for her career.",
    identity: "Father of Charubala",
    clgname: "NIT | Nagpur",
  },
  {
    imagelink: "images/img/parents/1x/Asset%202.webp",
    imagealt: "NATA Syllabus 2024",
    imageloading: "lazy",
    buttonlink: "https://www.youtube.com/embed/VQyX2gEBGao", // Route path for the button
    description:
      "My son secured Top score in NATA second attempt after joining Neram Classes.",
    identity: "Mother of Gunaa",
    clgname: "TCE | Madurai",
  },
];

function ParentCard({ item, onPlayClick }) {
  return (
    <div className="team-card d-inline-block mt-5">
      <div className="position-relative d-inline-block">
        <ParentImage
          src={item.imagelink}
          alt={item.imagealt}
          loading={item.imageloading}
        />
        <PlayButton
          src="images/img/iconset/nav/ic-play.svg"
          alt=""
          className="position-absolute"
          onClick={() => onPlayClick(item.buttonlink)}
        />
      </div>

      <div>
        <TestimonialDescription className="px-5">
          {item.description}
        </TestimonialDescription>
        <TestimonialName>{item.identity}</TestimonialName>
        <CollegeName>{item.clgname}</CollegeName>
      </div>
    </div>
  );
}

function ParentsCarousel() {
  const [modalOpen, setModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const handlePlayClick = (url) => {
    setVideoUrl(url);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setVideoUrl("");
  };

  const settings = {
    mobileFirst: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow className="top-50 end-0 w-auto" />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="slider-container container">
        <Slider {...settings}>
          {ParentsContent.map((item, index) => (
            <div key={index}>
              <ParentCard item={item} onPlayClick={handlePlayClick} />
            </div>
          ))}
        </Slider>
      </div>

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            p: 4,
          }}
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            sx={{ position: "absolute", top: 0, right: 0 }}
          >
            <CloseIcon />
          </IconButton>
          <VideoIframe
            title="YouTube Video"
            src={`${videoUrl}?autoplay=1`} // Append autoplay parameter to the URL
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></VideoIframe>
        </Box>
      </Modal>
    </div>
  );
}

export default ParentsCarousel;
