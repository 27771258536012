import TextSection from "./TextSecAsk";

const Content = [
  {
    smTitle: " Architectural Networking Hub",
    bgTitle: "Build Your Connections with Architecture Students",
    desc: "By participating in this event, you can expand your network and make valuable connections with fellow architecture students.Imagine having a group of architecture friends even before you start college!",
  },
  {
    smTitle: " Haribabu's Design Journey",
    bgTitle: "Meet Ar. Haribabu!",
    desc: "Gain insights from the expertise of Ar. Haribabu, a stellar designer who graduated from NIT Trichy and now thrives at a prestigious multinational corporation. ",
  },
  {
    smTitle: " Senior Q&A Session",
    bgTitle: "Get Your Doubts Solved about TNEA Counseling",
    desc: "Join us for an opportunity to have all your questions about TNEA B.Arch counseling answered at our Ask Senior event by experts with 10 years of experience in TNEA B.Arch counseling. Here, you will receive the right guidance on how to approach the choice filling and admission reporting process to secure admission into top universities.",
  },
  {
    smTitle: " Predict your college",
    bgTitle: "Experts' Predictions on Your Nata Score",
    desc: "We'll help predict your university prospects based on your NATA score. With over 10 years of experience and 5000+ students assisted, our experts offer insights and predictions for institutions like IITs, NITs, Anna University, and other top-ranked colleges. Don't miss this exclusive event for tailored guidance.",
  },
  {
    smTitle: " Tuition Fee Savings",
    bgTitle: "Get Fee Concessions from College Tuition Fees",
    desc: "We leverage our strong connections with high-ranking colleges to recommend reductions in tuition fees for event participants. Don't miss this opportunity to save on your educational expenses while receiving top-notch Nata coaching",
  },
  {
    smTitle: " Tools of Success",
    bgTitle: "Free Tools Await!",
    desc: "Grab your complimentary toolkit, including sketch pads and more, T-scale, Setsquare exclusively for event participants.",
  },
];

// ARRAY ITERATION

const Iteration = () => {
  return (
    <div className="col">
      {Content.map((item, index) => {
        return (
          <TextSection
            key={index}
            smTitle={item.smTitle}
            bgTitle={item.bgTitle}
            desc={item.desc}
          />
        );
      })}
    </div>
  );
};

// SCROLL HANG SECTION

const ScrollHang = ({ smTitle, bgTitle, desc }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col" style={{ position: "relative" }}>
          <img
            src="/images/asksenior/Best_NATA_Coaching_center_online.png"
            style={{
              position: "sticky",
              top: "20px",
              right: "2.5rem",
              maxWidth: "30rem",
              top: "3.5rem",
            }}
          />
        </div>

        <Iteration />
      </div>
    </div>
  );
};

export default ScrollHang;
