import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  TextField,
  Grid,
  Typography,
  Box,
  Autocomplete,
  Button,
} from "@mui/material";
import { database } from "../../../Firebase/setup";
import { ref, push } from "firebase/database";
import {
  getCurrentDate,
  getCurrentTime,
} from "../../../Basic components/DateTime";
import axios from "axios";

const DemoCard = styled.div`
  height: 477px;
  background-color: var(--lightpink);
  padding: 1rem;
  border-radius: 20px;
  box-shadow: 6px 6px 9px 0px rgb(0 0 0 / 50%);
  -webkit-box-shadow: 6px 6px 9px 0px rgb(0 0 0 / 50%);
  -moz-box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.5);
  @media (min-width: 768px) {
    padding: 2rem;
  }
`;

const HeroRow = styled.div`
  padding: 5rem 0;
`;

const DemoClassForm = () => {
  const Class = ["12th", "11th", "10th", "12th Completed", "Diploma", "Others"];
  const [formData, setFormData] = useState({
    Sname: "",
    Phone: "",
    Email: "",
    Class: Class[0],
    City: "",
    date: getCurrentDate(),
    time: getCurrentTime(),
    locationName: "",
  });

  // LOCATION

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getLocationName(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const getLocationName = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json`,
        {
          params: {
            q: `${latitude}+${longitude}`,
            key: "0f2ea0cee8b64644a6548b43a25256fc",
          },
        }
      );
      const location = response.data.results[0].formatted;
      setFormData((prevData) => ({
        ...prevData,
        locationName: location,
      }));
    } catch (error) {
      console.error("Error fetching location name:", error);
    }
  };

  useEffect(() => {
    getLocation();
  }, []); // Run once when the component mounts

  // =-----

  const [errors, setErrors] = useState({
    Sname: "",
    Phone: "",
    Email: "",
    Class: "",
    City: "",
  });

  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields();
  };

  const validateFields = () => {
    let hasErrors = false;
    const newErrors = { ...errors };

    if (formData.Sname.trim() === "") {
      newErrors.Sname = "Please enter the student's name.";
      hasErrors = true;
    } else if (/\d/.test(formData.Sname)) {
      newErrors.Sname = "The student's name should not contain numbers.";
      hasErrors = true;
    } else {
      newErrors.Sname = "";
    }
    if (formData.Phone.trim() === "") {
      newErrors.Phone = "Please enter the phone number.";
      hasErrors = true;
    } else {
      newErrors.Phone = "";
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.Email.trim() === "") {
      newErrors.Email = "Please enter the email address.";
      hasErrors = true;
    } else if (!emailPattern.test(formData.Email.trim())) {
      newErrors.Email = "Please enter a valid email address.";
      hasErrors = true;
    } else {
      newErrors.Email = "";
    }
    if (formData.Class.trim() === "") {
      newErrors.Class = "Please select the class.";
      hasErrors = true;
    } else {
      newErrors.Class = "";
    }
    if (formData.City.trim() === "") {
      newErrors.City = "Please enter the city.";
      hasErrors = true;
    } else {
      newErrors.City = "";
    }

    setErrors(newErrors);

    if (!hasErrors) {
      saveFormData();
    }
  };

  const saveFormData = () => {
    const formDataRef = ref(database, "demo");
    push(formDataRef, formData)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        console.error("Error storing form data:", error);
      });
  };

  return (
    <DemoCard>
      {success ? (
        <div>
          <Typography variant="h4" align="center">
            Thank you for registering!
          </Typography>
          <Typography variant="body1" align="center">
            We will get back to you shortly.
          </Typography>
        </div>
      ) : (
        <div>
          <Typography
            variant="h2"
            align="center"
            sx={{ margin: 0, fontSize: "1.22em", fontWeight: 900 }}
          >
            Free Book Registration
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{ margin: 0, color: "#6c757d", fontSize: "0.75em" }}
          >
            Learn from India's Top Architects
          </Typography>
          <div className="my-4">
            <Box sx={{ position: "relative" }} className="line-title">
              <hr />
              <Typography
                variant="body1"
                component="p"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "var(--lightpink)",
                  padding: "0 8px",
                  marginTop: "-11px",
                  fontSize: "0.75em",
                }}
              >
                Enter the Details
              </Typography>
            </Box>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                fullWidth
                id="sname"
                label={errors.Sname || "Student Name"}
                variant="standard"
                value={formData.Sname}
                onChange={(event) =>
                  setFormData({ ...formData, Sname: event.target.value })
                }
                error={Boolean(errors.Sname)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="number"
                fullWidth
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                id="phone"
                label={errors.Phone || "Phone Number"}
                variant="standard"
                value={formData.Phone}
                onChange={(event) =>
                  setFormData({ ...formData, Phone: event.target.value })
                }
                error={Boolean(errors.Phone)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                id="email"
                label={errors.Email || "Email"}
                variant="standard"
                value={formData.Email}
                onChange={(event) =>
                  setFormData({ ...formData, Email: event.target.value })
                }
                error={Boolean(errors.Email)}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                fullWidth
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                options={Class}
                value={formData.Class}
                onChange={(event, newValue) =>
                  setFormData({ ...formData, Class: newValue })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Class"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                    }}
                    variant="standard"
                    size="small"
                    error={Boolean(errors.Class)}
                    helperText={errors.Class}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                sx={{
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                id="city"
                label={errors.City || "City"}
                variant="standard"
                value={formData.City}
                onChange={(event) =>
                  setFormData({ ...formData, City: event.target.value })
                }
                error={Boolean(errors.City)}
              />
            </Grid>
          </Grid>
          <div className="my-4">
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      )}
    </DemoCard>
  );
};

const RegistrationSection = () => {
  return (
    <HeroRow>
      <div className="row align-items-center mx-1 mx-md-5">
        <div className="col-md-6">
          <img
            src="images/img/cover-image.png"
            alt="nata coaching centres in chennai"
            style={{ maxWidth: "100%", maxHeight: "330px" }}
            loading="lazy"
          />
        </div>
        <div className="col-md-6 p-0 px-md-5">
          <DemoClassForm />
        </div>
      </div>
    </HeroRow>
  );
};

export default RegistrationSection;
