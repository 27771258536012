import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import UpiSendIcon from "../../../../../assets/images/Payment-Icons/Upi-send.svg";
import MobilePay from "../../../../../assets/images/Payment-Icons/Phone-send.svg";
import RazorPay from "../../../../../assets/images/Payment-Icons/card-pay.svg";
import QrScan from "../../../../../assets/images/Payment-Icons/qr-scan.svg";
// Amount QR
import pay1 from "../../../../../assets/images/Payment_QR/10450.webp";
import pay2 from "../../../../../assets/images/Payment_QR/16450.webp";
import pay3 from "../../../../../assets/images/Payment_QR/25450.webp";
import pay4 from "../../../../../assets/images/Payment_QR/30450.webp";

const IconLabelTabs = ({ formData, setFormData, tabLabelsTwo }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedPayment = event.currentTarget.textContent; // Get the label of the clicked tab
    setFormData({
      ...formData,
      Payby: selectedPayment,
    });
  };

  useEffect(() => {
    if (formData.Payby) {
      const tabIndex = tabLabelsTwo.indexOf(formData.Payby);
      if (tabIndex !== -1) {
        setValue(tabIndex);
      }
    }
  }, [formData.Payby]);

  // MODAL POPUP

  const ModalContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const ModalPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  }));

  const ModalComponent = ({ amount, img }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <Button sx={{ backgroundColor: "white" }} onClick={handleOpen}>
          {amount}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Scan QR to Pay : Rs. {amount}
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "100%" }}
            >
              <img src={img} alt="QR Code" width="200rem" />
            </Box>
          </Box>
        </Modal>
      </>
    );
  };

  // pay buttons

  const IconImage = styled("img")({
    width: "2rem",
    "@media (max-width: 768px)": {
      width: "1.5rem",
    },
  });

  const RenderRazor = ({ index }) => {
    // Convert to a functional component by accepting props
    useEffect(() => {
      if (index === 3) {
        const rzpPaymentForm = document.getElementById("rzp_payment_form");

        if (!rzpPaymentForm.hasChildNodes()) {
          const script = document.createElement("script");
          script.src = "https://checkout.razorpay.com/v1/payment-button.js";
          script.async = true;
          script.dataset.payment_button_id = "your_payment_button_id";
          rzpPaymentForm.appendChild(script);
        }
      }
    }, [index]);
  };

  const RenderTabContent = ({ index }) => {
    // Convert to a functional component by accepting props
    useEffect(() => {
      if (index === 3) {
        const rzpPaymentForm = document.getElementById("rzp_payment_form");

        if (!rzpPaymentForm.hasChildNodes()) {
          const script = document.createElement("script");
          script.src = "https://checkout.razorpay.com/v1/payment-button.js";
          script.async = true;
          script.dataset.payment_button_id = "your_payment_button_id";
          rzpPaymentForm.appendChild(script);
        }
      }
    }, [index]); // Dependency array includes 'index' variable

    switch (index) {
      case 0:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "var(--violet)",
              backgroundColor: "var(--lightpink)",
            }}
          >
            <Typography sx={{ margin: "1rem 0" }}>
              Gpay | Phonepe | Paytm : <b>63801 94614</b>
            </Typography>
          </Box>
        );
      case 1:
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "var(--violet)",
              backgroundColor: "var(--lightpink)",
            }}
          >
            <Typography sx={{ margin: "1rem 0" }}>
              UPI ID : <b>neramClassroom@oksbi</b>
            </Typography>
          </Box>
        );
      case 2:
        return (
          <Box
            sx={{
              backgroundColor: "var(--lightpink)",
              padding: "1rem",
            }}
          >
            <Typography>
              <b>Open QR Code for :</b>
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                marginTop: "0.5rem",
              }}
            >
              <ModalComponent amount="10,450" img={pay1} />
              <ModalComponent amount="16,450" img={pay2} />
              <ModalComponent amount="25,450" img={pay3} />
              <ModalComponent amount="30,450" img={pay4} />
            </Box>
          </Box>
        );
      case 3:
        // RazorPay

        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "var(--violet)",
              backgroundColor: "var(--lightpink)",
              padding: "1rem 0",
            }}
          >
            <form id="rzp_payment_form"></form>
            <Button>Pay2</Button>
            <Button>Pay3</Button>
            <Button>Pay4</Button>
            {/* <RazorpayPaymentButton paymentButtonId={"pl_GlOY7zNDbYYbey"} /> */}
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon label tabs example"
        sx={{
          "& .MuiTab-root": {
            minWidth: "unset", // Remove minimum width
            padding: "8px", // Adjust padding
          },
          "& .MuiTab-labelIcon": {
            minHeight: "unset", // Remove minimum height for label with icon
            paddingTop: 0, // Adjust padding top for label with icon
          },
          "& .Mui-selected": {
            backgroundColor: "var(--lightpink)", // Change background color of selected tab
          },
        }}
      >
        <Tab
          icon={<IconImage src={MobilePay} alt="Mobile Pay" />}
          label="Mobile Pay"
        />
        <Tab
          icon={<IconImage src={UpiSendIcon} alt="UPI ID" />}
          label="PAY UPI-ID"
        />
        <Tab icon={<IconImage src={QrScan} alt="QR Scan" />} label="QR Scan" />
        <Tab icon={<IconImage src={RazorPay} alt="Card" />} label="pay Card" />
      </Tabs>

      <RenderTabContent index={value} />
    </Box>
  );
};

export default IconLabelTabs;
