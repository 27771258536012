import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  SampleNextArrow,
  SamplePrevArrow,
} from "../../../components/Basic components/CarouselArrow";

const IFrame = styled.iframe`
  border: 2px solid #88206d;
  box-shadow: 0 -10px 15px rgb(255 255 255 / 100%),
    inset 0 -10px 15px rgb(255 255 255 / 100%), 0 10px 15px rgb(0 0 0 / 40%),
    inset 0 15px 10px rgb(0 0 0 / 40%);
  border-radius: 16px;
  margin: 20px 0;
  width: 290px;
  scroll-behavior: auto;
`;

const AudioContent = [
  {
    Audiolink:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1376409310&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    Title: "Mother of Nagulan",
    Year: "2022",
  },
  {
    Audiolink:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1354124647&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    Title: "Bharanidharan Father feedback",
    Year: "2022",
  },
  {
    Audiolink:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1354124296&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    Title: "Allan Father feedback",
    Year: "2022",
  },
  {
    Audiolink:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1249360933&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    Title: "Sri Balaji Mother",
    Year: "2022",
  },
  {
    Audiolink:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1216086367&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    Title: "Akbar Mom Feedback Call",
    Year: "2022",
  },
  {
    Audiolink:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/994330783&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    Title: "Mani Megalai Architecture Student Review",
    Year: "2022",
  },
  {
    Audiolink:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/983223505&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    Title: "Jeyashree Brother - Neram Online NATA coaching center",
    Year: "2022",
  },
  {
    Audiolink:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1209530230&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    Title:
      "Neram Online NATA coaching center in Chennai | Coimbatore | Trichy | Madurai",
    Year: "2022",
  },
  {
    Audiolink:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/983223499&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    Title: "Parent Review - Neram Online NATA coaching center",
    Year: "2022",
  },
  {
    Audiolink:
      "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/983223484&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    Title: "Viki Praveen Brother - Neram Online NATA coaching center",
    Year: "2022",
  },
];

function OneItem({ item }) {
  return (
    <>
      <IFrame
        className="audio-review-neram"
        height="100"
        width="250"
        src={item.Audiolink} // Accessing Audiolink from item
      ></IFrame>
      <div className="text-center parent-review">
        <p className="testimonial-desc">{item.MiniTitle}</p>{" "}
        {/* Accessing MiniTitle from item */}
        <h5 className="testimonial-name">{item.Title}</h5>{" "}
        {/* Accessing Title from item */}
        <p className="testimonial-as-a">{item.Year}</p>{" "}
        {/* Accessing Year from item */}
      </div>
    </>
  );
}

function AudioCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="slider-container container my-5">
      <Slider {...settings}>
        {AudioContent.map((item, index) => (
          <div key={index}>
            <OneItem item={item} />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default AudioCarousel;
