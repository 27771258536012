import React from "react";

const FaqSvg = () => {
  return (
    <svg viewBox="0 0 1440 176.41">
      <path
        d="M0,96l48,21.3C96,139,192,181,288,176s192-59,288-74.7c96-16.3,192,5.7,288,16,96,10.7,192,10.7,288-5.3s192-48,240-64l48-16V0H0Z"
        fill="var(--lightpink)"
        className="cls-1"
      ></path>
    </svg>
  );
};

export default FaqSvg;
