// DATE TIME

export const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0]; // Returns YYYY-MM-DD format
};

export const getCurrentTime = () => {
  const today = new Date();
  return today.toTimeString().split(" ")[0]; // Returns HH:MM:SS format
};
