import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import MyButton from "../../../../components/Basic components/Buttons/Buttons";
import { Container } from "@mui/material";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(119px, 1fr));
    gap: 3px;
  }
`;

const VideoCard = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  aspect-ratio: 16/9; /* Default aspect ratio, you can adjust this */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

// Popup Player

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

export const VideoIframe = styled.iframe`
  width: 560px;
  height: 315px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const VideoModal = ({ children, onClose }) => {
  useEffect(() => {
    // Disable scrolling when the modal is open
    document.body.style.overflow = "hidden";
    // Re-enable scrolling when the modal is closed
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleOverlayClick = (event) => {
    // Close the modal only if the click occurs on the overlay itself
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return (
    <ModalOverlay onClick={handleOverlayClick}>
      <ModalContent>
        <CloseButton onClick={onClose}>X</CloseButton>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
};

const YouTubeSubscriberCount = ({ subscriberCount }) => (
  <span>
    <b>{subscriberCount}</b> Subscribers
  </span>
);

const YouTubeVideosGrid = () => {
  const [videos, setVideos] = useState([]);
  const [subscriberCount, setSubscriberCount] = useState(null);
  const [selectedVideoId, setSelectedVideoId] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);

  const apiKey = "AIzaSyBDUAB75fl7qWRkIJcrTcUQoC2x1SxmFKI";
  const channelId = "UCWbr9w9lW1XzN4ldp3sMCRw";

  // SUBSCRIBERS

  useEffect(() => {
    const fetchSubscriberCount = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/channels?key=${apiKey}&id=${channelId}&part=statistics`
        );
        const count = response.data.items[0].statistics.subscriberCount;
        setSubscriberCount(count);
      } catch (error) {
        console.error("Error fetching subscriber count:", error);
      }
    };

    fetchSubscriberCount();
  }, [apiKey, channelId]);

  // VIDEO FETCHING

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=${channelId}&part=snippet,id&order=date&maxResults=15&type=video&videoDuration=medium`
        );
        setVideos(response.data.items);
      } catch (error) {
        console.error("Error fetching videos:", error);
      }
    };

    fetchVideos();
  }, [apiKey, channelId]);

  const openVideoModal = (videoId) => {
    setSelectedVideoId(videoId);
    setShowVideoModal(true);
  };

  const closeVideoModal = () => {
    setSelectedVideoId(null);
    setShowVideoModal(false);
  };

  // Define the playVideo function here
  const playVideo = (videoId) => {
    setSelectedVideoId(videoId);
    setShowVideoModal(true);
  };

  // SUBSCRIBE

  const handleSubscribeClick = async () => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/youtube/v3/channels?key=${apiKey}&id=${channelId}&part=snippet`
      );
      const channelTitle = response.data.items[0].snippet.title;
      alert(`You are now subscribed to ${channelTitle}`);
    } catch (error) {
      console.error("Error subscribing to channel:", error);
    }
  };

  return (
    <Container>
      {subscriberCount !== null && (
        <div className="d-flex justify-content-between align-items-center px-3 my-3 ">
          <YouTubeSubscriberCount subscriberCount={subscriberCount} />
          <MyButton variant="contained" onClick={handleSubscribeClick}>
            Subscribe for Exam Updates
          </MyButton>
        </div>
      )}
      <GridContainer>
        {videos.map((video) => (
          <VideoCard
            key={video.id.videoId}
            onClick={() => playVideo(video.id.videoId)}
          >
            <img
              src={video.snippet.thumbnails.high.url}
              alt={video.snippet.title}
            />
          </VideoCard>
        ))}
      </GridContainer>
      {/* Your existing YouTubeSubscriberCount component */}
      {/* Your existing grid of videos */}
      {showVideoModal && selectedVideoId && (
        <VideoModal onClose={closeVideoModal}>
          <VideoIframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${selectedVideoId}`}
            title="YouTube video player"
            frameborder="0"
            allowfullscreen
          ></VideoIframe>
        </VideoModal>
      )}
    </Container>
  );
};

export default YouTubeVideosGrid;
