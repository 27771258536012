import React from "react";
import styled from "styled-components";

const Section = styled.section`
  width: 100%;
  height: ${({ height }) => height || "750px"};
  text-align: center;
  position: relative;
`;

const GradSection = styled(Section)`
  background-image: var(--gradneram);
`;

const PinkSection = styled(Section)`
  background-color: var(--lightpink);
`;

const CustomSection = styled(Section)`
  background-color: ${({ color }) => color || "#6c757d"};
`;

const SectionBackground = ({
  type,
  color,
  height,
  paddingBottomMedium,
  paddingBottomLarge,
  children,
}) => {
  const paddingBottom =
    window.innerWidth >= 768
      ? paddingBottomLarge || "0rem"
      : paddingBottomMedium || "0rem";

  return (
    <>
      {type === "grad" && (
        <GradSection height={height} style={{ paddingBottom }}>
          {children}
        </GradSection>
      )}
      {type === "pink" && (
        <PinkSection height={height} style={{ paddingBottom }}>
          {children}
        </PinkSection>
      )}
      {!["grad", "pink"].includes(type) && (
        <CustomSection color={color} height={height} style={{ paddingBottom }}>
          {children}
        </CustomSection>
      )}
    </>
  );
};

export default SectionBackground;
