import React, { useState } from "react";
import styled from "styled-components";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { pink } from "@mui/material/colors";

const StyledFab = styled.div`
  z-index: 1;
  position: fixed;
  cursor: pointer;
  background-color: white;
  color: var(--pink);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;

  &:hover {
    background-color: var(--pink);
    color: white;
  }
`;

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {isVisible && (
        <StyledFab onClick={scrollToTop}>
          <KeyboardArrowUpIcon />
        </StyledFab>
      )}
    </>
  );
};

export default BackToTopButton;
