import styled from "styled-components";
import FooterCard from "./Childrens/infocard";
import AboutUs from "./aboutus";
import CopyRight from "../Copyright/Copyright";

const FooterSection = styled.footer`
  background-image: linear-gradient(
    90deg,
    #2b2d4e 1.557291666666667%,
    #e1148b 101.34895833333333%
  );
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 11px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
`;

const FooterInfo = styled.div`
  margin-bottom: 15px;
  color: #fff;
  border-top: 4px solid #fffb01;
  padding: 30px 20px;
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  text-align: left;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 0;
`;

//  gpt

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
`;

const LinkDiv = styled.div``;

const LinkAtag = styled.a`
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  line-height: 1;
  &:hover {
    color: var(--yellow);
    text-decoration: underline;
  }
`;

const Title = styled.h4`
  font-size: 13px;
  font-weight: 600;
  color: #fffb01;
  position: relative;
  text-transform: uppercase;
`;

const Column = ({ data }) => {
  return (
    <ColumnContainer className="col-12 col-lg">
      {data.map((exam, index) => (
        <div key={index} className="mb-4">
          <Title className="d-md-flex d-block mb-3">{exam.Title}</Title>
          {exam.set1.map((link, linkIndex) => (
            <LinkDiv key={linkIndex} className="my-2 d-md-flex d-block">
              <LinkAtag href={link.url}>{link.text}</LinkAtag>
            </LinkDiv>
          ))}
        </div>
      ))}
    </ColumnContainer>
  );
};

const YourComponent = () => {
  const linkbuilding = [
    // Column 1
    [
      {
        Title: "Exams",
        set1: [
          { text: "NATA", url: "contact-neram-nata-coaching.html" },
          { text: "JEE-PAPER-2", url: "contact-neram-nata-coaching.html" },
          { text: "JEEMAINS-2", url: "contact-neram-nata-coaching.html" },
          { text: "JEE-ARCH", url: "jee-arch-url" },
          { text: "UCEED", url: "uceed-url" },
          { text: "NIFT", url: "nift-url" },
          { text: "NID", url: "nid-url" },
          { text: "AAT", url: "aat-url" },
        ],
      },
      {
        Title: "qUESTIONS",
        set1: [
          { text: "NATA-DRAWING", url: "nata-drawing-url" },
          { text: "NATA-QUESTION", url: "nata-syllabus-url" },
          { text: "NID-2020", url: "nid-syllabus-url" },
          { text: "NIFT-2022", url: "nift-syllabus-url" },
          { text: "ARCHITECTURE", url: "contact-neram-nata-coaching.html" },
          { text: "NATA-ONLINE", url: "nata-online-url" },
        ],
      },
    ],

    // Column 2
    [
      {
        Title: "Syllabus",
        set1: [
          { text: "NATA-Syllabus", url: "about-us-url" },
          { text: "NID-Syllabus", url: "centers-url" },
          { text: "NIFT-Syllabus", url: "results-url" },
          { text: "JEE-Paper-2", url: "nata-app-url" },
          { text: "NATA-Online", url: "team-url" },
        ],
      },
      {
        Title: "Questions",
        set1: [
          { text: "NATA Aptitude", url: "tamilnadu-url" },
          { text: "Jee 2 Aptitude", url: "kerala-url" },
          { text: "Aptitude Class", url: "andhrapradesh-url" },
          { text: "Nata paper 2", url: "karnataka-url" },
          { text: "Jee Paper 2", url: "maharashtra-url" },
          { text: "Nata book", url: "madhyapradesh-url" },
          { text: "Nata e book", url: "telangana-url" },
          { text: "Question Nata", url: "delhi-url" },
          { text: "Best Nata", url: "delhi-url" },
        ],
      },
    ],

    // Column 3
    [
      {
        Title: "Neram",
        set1: [
          { text: "About-us", url: "nata-aptitude-url" },
          { text: "Centers", url: "jee-2-aptitude-url" },
          { text: "results", url: "aptitude-class-url" },
          { text: "NATA-app", url: "nata-paper-2-url" },
          { text: "team", url: "jee-paper-2-url" },
        ],
      },
      {
        Title: "City",
        set1: [
          { text: "Chennai", url: "nata-drawing-url" },
          { text: "Madurai", url: "nata-question-url" },
          { text: "Coimbatore", url: "nata-2020-url" },
          { text: "Trichy", url: "nata-2022-url" },
          { text: "Salem", url: "architecture-url" },
          { text: "Erode", url: "online-coaching-url" },
          { text: "Tiruppur", url: "online-coaching-url" },
          { text: "Vellore", url: "online-coaching-url" },
          { text: "Pondicherry", url: "online-coaching-url" },
        ],
      },
    ],
    // Column 4
    [
      {
        Title: "Neram",
        set1: [
          { text: "Tamilnadu", url: "chennai-url" },
          { text: "Kerala", url: "madurai-url" },
          { text: "Andhrapradesh", url: "coimbatore-url" },
          { text: "Karnataka", url: "trichy-url" },
          { text: "Maharastra", url: "salem-url" },
          { text: "Madhya pradesh", url: "erode-url" },
          { text: "Telungana", url: "tiruppur-url" },
          { text: "Delhi", url: "vellore-url" },
        ],
      },
      {
        Title: "Nata IN",
        set1: [
          { text: "MUMBAI", url: "mumbai-url" },
          { text: "COCHIN", url: "cochin-url" },
          { text: "TRIVANDRAM", url: "trivandram-url" },
          { text: "HYDERABAD", url: "hyderabad-url" },
          { text: "VIZAG", url: "vizag-url" },
          { text: "BANGALORE", url: "bangalore-url" },
        ],
      },
    ],
    // Column 5
    [
      {
        Title: "COACHING IN",
        set1: [
          { text: "DINDIGUL", url: "dindigul-url" },
          { text: "KANYAKUMARI", url: "kanyakumari-url" },
          { text: "NAMAKKAL", url: "namakkal-url" },
          { text: "PUDUKKOTTAI", url: "pudukkottai-url" },
          { text: "KARAIKUDI", url: "karaikudi-url" },
          { text: "SIVAGANGAI", url: "sivagangai-url" },
          { text: "THANJAVUR", url: "thanjavur-url" },
          { text: "TIRUNELVELI", url: "tirunelveli-url" },
          { text: "ANNA-NAGAR", url: "anna-nagar-url" },
          { text: "PERAMBUR", url: "perambur-url" },
          { text: "TAMBARAM", url: "tambaram-url" },
          { text: "THIRUVALLUR", url: "thiruvallur-url" },
          { text: "UDUMALAPET", url: "udumalapet-url" },
          { text: "MALAPURAM", url: "malapuram-url" },
          { text: "OOTY", url: "ooty-url" },
          { text: "KANCHIPURAM", url: "kanchipuram-url" },
          { text: "PUDUKKOTTAI", url: "kanchipuram-url" },
        ],
      },
    ],
  ];

  return (
    <div className="col row m-0">
      {linkbuilding.map((columnData, index) => (
        <Column key={index} data={columnData} />
      ))}
    </div>
  );
};

function NeramFooter() {
  return (
    <FooterSection id="content-wrapper-with-footer">
      <div className="container py-5">
        <div className="footersec row pt-5">
          <FooterInfo className="col-lg-4">
            <FooterCard />
          </FooterInfo>
          <YourComponent />
          <div className="my-3">
            <hr />
          </div>
          <AboutUs />
        </div>
        <div className="footersec2"></div>
      </div>
      <CopyRight />
    </FooterSection>
  );
}

export default NeramFooter;
