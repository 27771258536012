import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useRef } from "react";

export default function Tawktochat() {
  const tawkRef = useRef(null);
  return (
    <TawkMessengerReact
      propertyId="6041bc2a1c1c2a130d652337"
      widgetId="1f00ce2gr"
      ref={tawkRef}
    />
  );
}
