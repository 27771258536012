import { Close } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";

const PopupHeader = ({ text, handleClose }) => {
  return (
    <Box
      sx={{
        display: "flex",
        background: "var(--grey400)",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 0 0 1rem",
      }}
    >
      <Typography
        id="modal-modal-title"
        variant="subtitle1"
        component="h2"
        color={"#000"}
        fontWeight={"500"}
      >
        {text}
      </Typography>
      <IconButton onClick={handleClose}>
        <Close />
      </IconButton>
    </Box>
  );
};

export default PopupHeader;
