import { Box, Button, Grid } from "@mui/material";
import { RedOrangeText } from "../Askseniors";
import styled from "styled-components";
import GetRegisteredBtn from "./GetRegistered";

// IMAGE STYLE

const ResImage = styled.img`
  position: relative;
  right: -30px;
  bottom: 2rem;
  transform: scale(0.85);
  @media (max-width: 900px) {
    position: relative;
    height: auto;
    right: 5rem;
    transform: scale(0.7);
  }
`;

const NATAText = styled.h1`
  font-family: "Suez One";
  font-size: 80px;
  line-height: 55px;
  @media (max-width: 576px) {
    font-size: 47px;
    line-height: 40px;
  }
`;

const Hero = () => {
  return (
    <Grid container>
      <Grid
        className="z-1"
        item
        xs={8}
        md={7}
        lg={6}
        sx={{
          paddingLeft: "1rem",
        }}
      >
        <Box sx={{ position: "relative", top: "1rem" }}>
          <RedOrangeText>#askSeniors</RedOrangeText>
          <NATAText>NATA Event</NATAText>
          <p className="pb-3" style={{ fontSize: "14px" }}>
            <b style={{ color: "var(--violet)", fontSize: "14px" }}>
              Ready to Choose the Perfect College for Your BArch Journey?
            </b>{" "}
            <br />
            <br /> Join the exclusive{" "}
            <span
              style={{
                background: "var(--gradneram)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                fontFamily: "Poppins",
                fontWeight: "700",
              }}
            >
              #AskSeniors
            </span>{" "}
            event by NeramClasses.com and interact with alumni from over 50+
            prestigious institutions! Before making your choice in the TNEA
            BArch counseling, gain invaluable insights directly from students
            who have been in your shoes. Understand the positives and negatives
            of universities across Tamil Nadu and India from a student's point
            of view. Hear firsthand accounts of campus life, faculty, and
            facilities, clear your doubts, and make informed decisions. This is
            a free online event. Click the Button Below for Registration:
          </p>
          <GetRegisteredBtn content={"Get Registered"} />
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        md={3}
        lg={4}
        className="d-flex justify-content-md-start z-0 "
      >
        <ResImage
          src="/images/asksenior/NATA_Coaching_center_Students.png"
          alt="NATA Coaching Center Students"
        />
      </Grid>

      <Grid
        item
        xs={3}
        md={2}
        lg={2}
        sx={{
          display: "flex",
          alignItems: "end",
          paddingLeft: "2rem",
          paddingTop: "2rem",
          paddingBottom: "2.5rem",
        }}
      >
        <img
          src="/images/asksenior/NATA_Coaching_in_Chennai.png"
          alt="NATA Coaching in Chennai"
          style={{
            width: "100%",
            height: "auto",
            maxWidth: {
              xs: "100%", // 100% width for extra-small screens
              md: "80%", // 80% width for medium screens
              lg: "100%", // 100% width for large screens
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Hero;
