import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { Container, IconButton, Typography } from "@mui/material";

const BreadCrumBar = ({ title, breadcrumbName }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment);

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Container className="h-100">
      <div className="d-flex flex-sm-row-reverse align-items-center justify-content-between justify-content-sm-end gap-2 h-100">
        <div className="BreadCrum">
          <div className="d-flex align-items-center ">
            {/* BACK */}
            <IconButton
              edge="start"
              aria-label="back"
              onClick={handleGoBack}
              className="d-sm-none"
              style={{ color: "var(--yellow)" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ color: "var(--grey300)" }}
            >
              {/* Render Home breadcrumb */}
              <Link
                style={{
                  color: "var(--yellow)",
                  fontStyle: "italic",
                  fontSize: "14px",
                  textDecoration: "none",
                }}
                to="/"
              >
                Home
              </Link>

              {pathSegments.map(
                (
                  segment,
                  index // Changed variable name to "segment"
                ) => (
                  <Link
                    style={{
                      color: "var(--yellow)",
                      fontStyle: "italic",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                    key={index}
                    to={"/" + pathSegments.slice(0, index + 1).join("/")}
                  >
                    {index === pathSegments.length - 1
                      ? breadcrumbName
                      : segment}{" "}
                    {/* Use breadcrumbName for the last segment */}
                  </Link>
                )
              )}
            </Breadcrumbs>
          </div>
        </div>
        <Typography color={"#ffffff38"} className="d-none d-sm-block">
          |
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontSize: "14px",
            backgroundColor: "var(--yellow)",
            padding: "0 0.5rem",
            borderRadius: "4px",
          }}
        >
          {title}
        </Typography>
      </div>
    </Container>
  );
};

export default BreadCrumBar;
