import React from "react";
import ResponsiveAppBar from "../../components/TopNavigationBar/AppBar/AppBarMui";
import { Box } from "@mui/material";
import FormContent from "./Components/FormContent";
import Welcomeimage from "./Components/WelcomeImage";

const ApplicationPage = () => {
  return (
    <>
      <ResponsiveAppBar
        variant="opaque"
        showBackButton={false}
        largerScreenTitle="Class Enrollment Application Form"
        smallerScreenTitle="Application Form"
        breadcrumbName={"Application Form"}
        showBreadCrumBar={true}
      />
      {/* PAGE CONTAINER */}

      <Box
        sx={{
          height: "100vh",
          bgcolor: "var(--lightpink)",
          position: "relative",
        }}
      >
        {/* CARD CONTAINER */}
        <Box
          bgcolor={"white"}
          sx={{
            marginTop: "3rem",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%", // Default width
            borderRadius: "35px",
            boxShadow: "5px 4px 9px 0 rgb(136 32 109 / 20%)",
            "@media (max-width: 768px)": {
              // Adjust width for smaller displays
              width: "90%",
            },
          }}
        >
          <div className="position-relative">
            <Welcomeimage />
            <FormContent />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default ApplicationPage;
