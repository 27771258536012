import React, { useState } from "react";
import styled from "styled-components";

function AccordionItem({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen ? "true" : "false"}
        >
          {title}
        </button>
      </h2>
      <div className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}>
        <div className="accordion-body">{children}</div>
      </div>
    </div>
  );
}

const AccordianStyle = styled.div`
  button {
    color: var(--violet);
    font-weight: bold;
  }
`;

export function FaqAccordion1() {
  return (
    <div className="accordion mb-4" id="accordionExample">
      <AccordianStyle>
        <AccordionItem title="Accordion Item #1">
          <strong>This is the first item's accordion body.</strong> It is shown
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #2">
          <strong>This is the second item's accordion body.</strong> It is
          hidden by default, until the collapse plugin adds the appropriate
          classes that we use to style each element. These classes control the
          overall appearance, as well as the showing and hiding via CSS
          transitions. You can modify any of this with custom CSS or overriding
          our default variables. It's also worth noting that just about any HTML
          can go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #3">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordian Item #4">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>
    </div>
  );
}

export function FaqAccordion2() {
  return (
    <div className="accordion mb-4" id="accordionExample">
      <AccordianStyle>
        <AccordionItem title="Accordion Item #1">
          <strong>This is the first item's accordion body.</strong> It is shown
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #2">
          <strong>This is the second item's accordion body.</strong> It is
          hidden by default, until the collapse plugin adds the appropriate
          classes that we use to style each element. These classes control the
          overall appearance, as well as the showing and hiding via CSS
          transitions. You can modify any of this with custom CSS or overriding
          our default variables. It's also worth noting that just about any HTML
          can go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #3">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordian Item #4">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>
    </div>
  );
}

export function FaqAccordion3() {
  return (
    <div className="accordion mb-4" id="accordionExample">
      <AccordianStyle>
        <AccordionItem title="Accordion Item #1">
          <strong>This is the first item's accordion body.</strong> It is shown
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #2">
          <strong>This is the second item's accordion body.</strong> It is
          hidden by default, until the collapse plugin adds the appropriate
          classes that we use to style each element. These classes control the
          overall appearance, as well as the showing and hiding via CSS
          transitions. You can modify any of this with custom CSS or overriding
          our default variables. It's also worth noting that just about any HTML
          can go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #3">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordian Item #4">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>
    </div>
  );
}

export function FaqAccordion6() {
  return (
    <div className="accordion mb-4" id="accordionExample">
      <AccordianStyle>
        <AccordionItem title="Accordion Item #1">
          <strong>This is the first item's accordion body.</strong> It is shown
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #2">
          <strong>This is the second item's accordion body.</strong> It is
          hidden by default, until the collapse plugin adds the appropriate
          classes that we use to style each element. These classes control the
          overall appearance, as well as the showing and hiding via CSS
          transitions. You can modify any of this with custom CSS or overriding
          our default variables. It's also worth noting that just about any HTML
          can go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #3">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordian Item #4">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>
    </div>
  );
}

export function FaqAccordion5() {
  return (
    <div className="accordion mb-4" id="accordionExample">
      <AccordianStyle>
        <AccordionItem title="Accordion Item #71">
          <strong>This is the first item's accordion body.</strong> It is shown
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #82">
          <strong>This is the second item's accordion body.</strong> It is
          hidden by default, until the collapse plugin adds the appropriate
          classes that we use to style each element. These classes control the
          overall appearance, as well as the showing and hiding via CSS
          transitions. You can modify any of this with custom CSS or overriding
          our default variables. It's also worth noting that just about any HTML
          can go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #83">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordian Item #4">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>
    </div>
  );
}

export function FaqAccordion4() {
  return (
    <div className="accordion mb-4" id="accordionExample">
      <AccordianStyle>
        <AccordionItem title="Accordion Item #1">
          <strong>This is the first item's accordion body.</strong> It is shown
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #2">
          <strong>This is the second item's accordion body.</strong> It is
          hidden by default, until the collapse plugin adds the appropriate
          classes that we use to style each element. These classes control the
          overall appearance, as well as the showing and hiding via CSS
          transitions. You can modify any of this with custom CSS or overriding
          our default variables. It's also worth noting that just about any HTML
          can go within the <code>.accordion-body</code>, though the transition
          does limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordion Item #3">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>

      <AccordianStyle>
        <AccordionItem title="Accordian Item #44">
          <strong>This is the third item's accordion body.</strong> It is hidden
          by default, until the collapse plugin adds the appropriate classes
          that we use to style each element. These classes control the overall
          appearance, as well as the showing and hiding via CSS transitions. You
          can modify any of this with custom CSS or overriding our default
          variables. It's also worth noting that just about any HTML can go
          within the <code>.accordion-body</code>, though the transition does
          limit overflow.
        </AccordionItem>
      </AccordianStyle>
    </div>
  );
}

const PaddedCell = styled.td`
  padding: 1rem !important;
  &:hover {
    background-color: var(--lightpink);
  }
  font-weight: ${(props) => (props.isSelected ? "bold" : "normal")};

  /* Adjust the padding as needed */
`;

const TableRow = styled.tr``;

const SixRowTable = ({ onRowClick, ClickedRowIndex }) => {
  const rowNames = ["Profile", "Account", "Help", "Login", "Setup", "Account"];

  const handleClick = (index) => {
    onRowClick(index);
  };

  return (
    <tbody>
      {rowNames.map((name, index) => (
        <TableRow key={index} onClick={() => handleClick(index)}>
          <PaddedCell
            isSelected={ClickedRowIndex === index}
            style={{ color: "var(--violet)" }}
          >
            {name}
          </PaddedCell>
        </TableRow>
      ))}
    </tbody>
  );
};

export default SixRowTable;
