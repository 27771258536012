import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
// Accordation
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

const TitleBar = ({ handleMenuToggle, isMenuOpen }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
      {isSmallScreen && (
        <IconButton color="inherit" onClick={handleMenuToggle}>
          {isMenuOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      )}
      <Box>
        <Typography
          variant="h6"
          style={{ color: "var(--violet)", fontWeight: 700 }}
        >
          Syllabus for JEE 2025 (Main) Paper 2A (B.Arch.)
        </Typography>
        <Typography variant="body2">
          Paper 2 Architecture involves - Mathematics, Aptitude Test and Drawing
          Test
        </Typography>
      </Box>
    </Box>
  );
};

// ACOORDATION

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

// ARRAYS

const MathList = [
  {
    title: "Sets",
    description:
      "Sets are a fundamental concept in mathematics that represent collections of distinct objects.",
    sample_img: "https://example.com/sample_sets_image.jpg",
  },
  {
    title: "Functions",
    description:
      "Functions describe the relationship between inputs and outputs, where each input maps to exactly one output.",
    sample_img: "https://example.com/sample_functions_image.jpg",
  },
  {
    title: "Relation",
    description:
      "Relations are connections or associations between two or more elements or sets.",
    sample_img: "https://example.com/sample_relation_image.jpg",
  },
  {
    title: "Complex Numbers & Quadratic Equations",
    description:
      "Complex numbers extend the concept of real numbers and are used to solve quadratic equations with complex roots.",
    sample_img: "https://example.com/sample_complex_numbers_image.jpg",
  },
  {
    title: "Matrices & Determinants",
    description:
      "Matrices are arrays of numbers, and determinants are specific properties of square matrices.",
    sample_img: "https://example.com/sample_matrices_image.jpg",
  },
  {
    title: "Permutation & Combinations",
    description:
      "Permutations and combinations are techniques used to count and arrange objects in different ways.",
    sample_img: "https://example.com/sample_permutation_combinations_image.jpg",
  },
  {
    title: "Binomial Theorem & Its Simple Applications",
    description:
      "The binomial theorem is an algebraic formula used to expand binomials raised to positive integer powers.",
    sample_img: "https://example.com/sample_binomial_theorem_image.jpg",
  },
  {
    title: "Sequence & Series",
    description:
      "Sequences and series are ordered lists of numbers with specific patterns and summation properties.",
    sample_img: "https://example.com/sample_sequence_series_image.jpg",
  },
  {
    title: "Limit, Continuity & Differentiability",
    description:
      "Limits, continuity, and differentiability are concepts in calculus that study the behavior of functions near specific points.",
    sample_img:
      "https://example.com/sample_limit_continuity_differentiability_image.jpg",
  },
  {
    title: "Integral Calculus",
    description:
      "Integral calculus deals with the accumulation of quantities and the computation of areas under curves.",
    sample_img: "https://example.com/sample_integral_calculus_image.jpg",
  },
  {
    title: "Differential Equations",
    description:
      "Differential equations involve functions and their derivatives and are used to model various real-world phenomena.",
    sample_img: "https://example.com/sample_differential_equations_image.jpg",
  },
  {
    title: "Coordinate Geometry",
    description:
      "Coordinate geometry involves the study of geometric figures using coordinate systems.",
    sample_img: "https://example.com/sample_coordinate_geometry_image.jpg",
  },
  {
    title: "3D Geometry",
    description:
      "Three-dimensional geometry deals with geometric objects in three-dimensional space.",
    sample_img: "https://example.com/sample_3d_geometry_image.jpg",
  },
  {
    title: "Vector Algebra",
    description:
      "Vector algebra deals with vectors, which are quantities that have both magnitude and direction.",
    sample_img: "https://example.com/sample_vector_algebra_image.jpg",
  },
  {
    title: "Statistics & Probability",
    description:
      "Statistics and probability involve the analysis of data and the likelihood of events occurring.",
    sample_img: "https://example.com/sample_statistics_probability_image.jpg",
  },
  {
    title: "Trigonometry",
    description:
      "Trigonometry is the study of the relationships between the angles and sides of triangles.",
    sample_img: "https://example.com/sample_trigonometry_image.jpg",
  },
];

const LeftMenus = () => {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //   LIST

  const [selectedItem, setSelectedItem] = useState("");

  const handleItemClick = (event, item) => {
    event.preventDefault();
    setSelectedItem(item);
    const sectionIndex = MathList.findIndex(
      (mathItem) => mathItem.title === item
    );
    const sectionId = sectionIndex + 1;
    const element = document.getElementById(sectionId.toString());
    if (element) {
      // Scroll the section into view
      element.scrollIntoView({ behavior: "smooth", block: "start" });

      // Set focus to the section for better user experience
      element.focus();
    }
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Mathematics</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "0" }}>
          <div style={{ maxHeight: "14rem", overflowY: "auto" }}>
            <List>
              {MathList.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    sx={{ padding: "0 0 0 1rem" }}
                    selected={selectedItem === item.title}
                    onClick={(event) => handleItemClick(event, item.title)}
                  >
                    <ListItemText
                      primary={`${index + 1}. ${item.title}`}
                      sx={{ fontSize: "14px" }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Aptitude</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button variant="text">1. Sets</Button>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Drawing</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada
            lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

// --------

const JEE_Sylabus_Comp = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Container sx={{ padding: "6.5rem 0 2rem 0", height: "100vh" }}>
      <TitleBar handleMenuToggle={handleMenuToggle} isMenuOpen={isMenuOpen} />
      <Grid container sx={{ height: "25.5rem" }}>
        <Grid
          item
          md={3}
          sx={{
            width: isMenuOpen ? "35%" : "0",
            overflow: "hidden",
            transition: "width 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
            height: "100%",
          }}
        >
          <LeftMenus />
        </Grid>
        <Grid
          item
          md={9}
          sx={{
            backgroundColor: "white",
            width: isMenuOpen ? "65%" : "100%",
            transition: "width 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
            height: "100%",
          }}
        >
          <Syllabus_Body />
        </Grid>
      </Grid>
    </Container>
  );
};

const Syllabus_Body = () => {
  return (
    <Box sx={{ height: "100%", overflowY: "auto" }}>
      {MathList.map((item, index) => (
        <Box key={index} id={index + 1} sx={{ height: "20rem" }}>
          <Typography>
            <b>
              {index + 1}.{item.title}
            </b>
          </Typography>
          <Typography>{item.description}</Typography>
          <img src={item.sample_img} alt="" />
        </Box>
      ))}
    </Box>
  );
};

export default JEE_Sylabus_Comp;
