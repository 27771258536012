// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
// Set persistence
import { browserLocalPersistence } from "firebase/auth";
import { setPersistence } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
const firebaseConfig = {
  apiKey: "AIzaSyC2YRs8xUF6SNQoGA-lpJPsmhFeXkbfI3I",
  authDomain: "neramclasses-ab480.firebaseapp.com",
  databaseURL: "https://neramclasses-ab480-default-rtdb.firebaseio.com/",
  projectId: "neramclasses-ab480",
  storageBucket: "neramclasses-ab480.appspot.com",
  messagingSenderId: "694669335029",
  appId: "1:694669335029:web:ba3ed74edbca69746128bc",
  measurementId: "G-NQT7WJDQZL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set successfully");
  })
  .catch((error) => {
    console.error("Error setting session persistence:", error);
  });

const database = getDatabase(app);
const db = getFirestore(app); // Add this line to get the Firestore instance

// FIRESTRORE

export { auth, database, db }; // Export auth and database
