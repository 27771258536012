import React from "react";
import styled from "styled-components";

const StyledWave = styled.svg``;

export const Wave2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 170">
      <path
        fill="var(--lightpink)"
        fillOpacity="1"
        d="M0,96L48,85.3C96,75,192,53,288,53.3C384,53,480,75,576,90.7C672,107,768,117,864,117.3C960,117,1056,107,1152,101.3C1248,96,1344,96,1392,96L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
      ></path>
    </svg>
  );
};

const Wave1 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 120 1440 200">
      <path
        fill="#fdeffd"
        fillOpacity="1"
        d="M0,320L80,320C160,320,320,320,480,304C640,288,800,256,960,245.3C1120,235,1280,245,1360,250.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
      ></path>
    </svg>
  );
};

export const Wave4 = () => {
  return (
    <svg viewBox="0 0 1200 81.7">
      <path
        d="M447.27,69.5C528,56.21,606.14,32.4,686.63,18,801.29-2.64,920.7-3.88,1035.18,17.47,1146.43,38.17,1200,52.92,1200,52.92V0H0V33.68C142.6,81.52,297.28,93.91,447.27,69.5Z"
        fill="#fff"
      ></path>
    </svg>
  );
};

export const Wave5 = () => {
  return (
    <svg viewBox="0 0 500 150" preserveAspectRatio="none">
      <path
        d="M-0.84,108.05 C136.29,234.38 301.63,-67.59 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
        fill="#FFB6C1"
      ></path>
    </svg>
  );
};

export const Wave6 = () => {
  return (
    <svg viewBox="0 0 1440 202.7" preserveAspectRatio="none">
      <path
        d="M0,32,48,74.7C96,117,192,203,288,202.7c96,.3,192-85.7,288-101.4C672,85,768,139,864,138.7c96,.3,192-53.7,288-64C1248,64,1344,96,1392,112l48,16V0H0Z"
        fill="#fff"
      ></path>
    </svg>
  );
};

export const Wave7 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 55 1440 160">
      <path
        fill="#fdeffd"
        fillOpacity="1"
        d="M0,192L120,165.3C240,139,480,85,720,69.3C960,53,1200,75,1320,85.3L1440,96L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"
      ></path>
    </svg>
  );
};

export const Wave8 = () => {
  return (
    <svg viewBox="0 0 1440 202.7" preserveAspectRatio="none">
      <path
        d="M0,32,48,74.7C96,117,192,203,288,202.7c96,.3,192-85.7,288-101.4C672,85,768,139,864,138.7c96,.3,192-53.7,288-64C1248,64,1344,96,1392,112l48,16V0H0Z"
        fill="#fff"
      ></path>
    </svg>
  );
};

export const Wave9 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 250">
      <path
        fill="var(--lightpink)"
        fillOpacity="1"
        d="M0,64L48,69.3C96,75,192,85,288,112C384,139,480,181,576,192C672,203,768,181,864,176C960,171,1056,181,1152,181.3C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
      ></path>
    </svg>
  );
};

export const Wave10 = () => {
  return (
    <svg viewBox="0 0 1440 103.91">
      <path
        d="M0,96l80-5.3C160,85,320,75,480,58.7,640,43,800,21,960,10.7,1120,0,1280,0,1360,0h80V103.91L0,103.16Z"
        fill="#fff"
      ></path>
    </svg>
  );
};

export const Wave11 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
      <path
        fill="var(--lightpink)"
        fillOpacity="1"
        d="M0,160L80,138.7C160,117,320,75,480,69.3C640,64,800,96,960,96C1120,96,1280,64,1360,48L1440,32L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
      ></path>
    </svg>
  );
};

export default Wave1;
