import { Autocomplete, Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";

function StepTwo({ formData, setFormData, State, Country, errors, setErrors }) {
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    console.log("Selected value:", selectedOption);
  }, [selectedOption]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", gap: "1rem", width: "100%" }}>
        <TextField
          fullWidth
          id="standard-basic"
          error={Boolean(errors.Address)}
          label="Street + Nr"
          variant="standard"
          value={formData.Address}
          onChange={(event) =>
            setFormData({ ...formData, Address: event.target.value })
          }
        />
        <TextField
          id="standard-basic"
          error={Boolean(errors.ZipCode)}
          label="ZipCode"
          type="number"
          variant="standard"
          value={formData.ZipCode}
          onChange={(event) =>
            setFormData({ ...formData, ZipCode: event.target.value })
          }
        />
      </Box>
      <Box sx={{ display: "flex", gap: "1rem", width: "100%" }}>
        <Autocomplete
          fullWidth
          options={State}
          value={formData.State}
          onChange={(event, newValue) =>
            setFormData({ ...formData, State: newValue })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="State"
              variant="standard"
              size="small"
              error={Boolean(errors.State)}
            />
          )}
        />
        <Autocomplete
          fullWidth
          options={Country}
          value={formData.Country}
          onChange={(event, newValue) =>
            setFormData({ ...formData, Country: newValue })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Country"
              variant="standard"
              size="small"
              error={Boolean(errors.Country)}
            />
          )}
        />
      </Box>

      <Box sx={{ display: "flex", gap: "1.5rem", width: "100%" }}>
        <TextField
          fullWidth
          id="standard-basic"
          label="Instagram ID"
          variant="standard"
          value={formData.IG}
          onChange={(event) =>
            setFormData({ ...formData, IG: event.target.value })
          }
        />
        <TextField
          fullWidth
          id="standard-basic"
          type="number"
          error={Boolean(errors.Phone)}
          label="Phone"
          variant="standard"
          value={formData.Phone}
          onChange={(event) =>
            setFormData({ ...formData, Phone: event.target.value })
          }
        />
      </Box>
      <TextField
        fullWidth
        id="standard-basic"
        error={Boolean(errors.Email)}
        label="Email"
        variant="standard"
        value={formData.Email}
        onChange={(event) =>
          setFormData({ ...formData, Email: event.target.value })
        }
      />
    </Box>
  );
}

export default StepTwo;
