import { Box, Typography } from "@mui/material";
import { RedOrangeText } from "../Askseniors";
import styled from "styled-components";
import GetRegisteredBtn from "./GetRegistered";

const VideoIframe = styled.iframe`
  width: 100%;
  height: 250px;
  @media (min-width: 576px) {
    width: 720px;
    height: 350px;
  }
`;

// VIDEO SECTION

const VideoSection = () => {
  return (
    <Box sx={{ position: "relative", bottom: "3rem" }}>
      <Box display={"flex"} justifyContent={"center"}>
        <RedOrangeText>#askSeniors</RedOrangeText>
      </Box>

      <Box display={"flex"} justifyContent={"center"} marginBottom={"1rem"}>
        <Typography variant="body2" className="text-center px-md-5 mx-5">
          <b style={{ color: "var(--violet)", fontSize: "16px" }}>
            Why Attend??
          </b>
          <br />
          <span>
            {" "}
            Discover the real insights you need to make the best college choice!
            At the "Ask Senior" event, you'll have the unique opportunity to
            hear personal experiences directly from alumni. Get all your
            questions answered to clear any doubts and make well-informed
            decisions. Best of all, this is a free online event, Watch the video
            below for more details.
          </span>
        </Typography>
      </Box>

      <Box display={"flex"} justifyContent={"center"}>
        <VideoIframe
          src="https://www.youtube.com/embed/eP6Z6Bd7ahc"
          title="NATA Best architecture colleges in Tamilnadu B.arch students"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></VideoIframe>
      </Box>
      <Box className="text-center my-5">
        <GetRegisteredBtn content={"Get Registered"} />
      </Box>
    </Box>
  );
};

export default VideoSection;
