import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const CountrySelect = ({ setSelectedCountry, selectedCountry }) => {
  const countries = [
    { code: "AE", label: "UAE", phone: "971" },
    { code: "IN", label: "India", phone: "91" },
    { code: "LK", label: "SL", phone: "94" },
    { code: "OM", label: "Oman", phone: "968" },
    { code: "SA", label: "SA", phone: "966" },
  ];

  const defaultCountry = countries.find((country) => country.label === "India");

  useEffect(() => {
    setSelectedCountry(defaultCountry);
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  const handleCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
  };

  return (
    <Autocomplete
      clearIcon={null}
      id="country-select-demo"
      size="small"
      sx={{ width: 100 }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => `+${option.phone}`}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 1, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Code"
          sx={{ backgroundColor: "white" }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
      onChange={handleCountryChange}
      value={selectedCountry} // Set the value of the Autocomplete component
    />
  );
};

export default CountrySelect;
