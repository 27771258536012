import { TextField } from "@mui/material"; // Fix import statement
import { useRef, useEffect } from "react";

// OTP FIELD COMPONENT

const OtpInput = ({ otp, handleOtpChange }) => {
  const inputRefs = useRef([]);

  useEffect(() => {
    // Focus on the first OTP field when the component mounts
    inputRefs.current[0]?.focus();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleChange = (index, event) => {
    const { value } = event.target;
    handleOtpChange(index, value);
    // You might also want to focus on the next input field if available
    if (value && index < otp.length - 1) {
      // Move focus to the next input field
      inputRefs.current[index + 1]?.focus();
    }
  };

  return (
    <div className="d-flex gap-2 justify-content-center align-items-center">
      {otp.map((digit, index) => (
        <TextField
          key={index}
          inputRef={(ref) => (inputRefs.current[index] = ref)} // Save the input reference
          id={`otp-input-${index}`}
          value={digit}
          onChange={(event) => handleChange(index, event)}
          variant="outlined"
          size="small"
          margin="normal"
          inputProps={{
            maxLength: 1,
            inputMode: "numeric",
            style: { width: "20px", textAlign: "center" },
          }}
        />
      ))}
    </div>
  );
};

export default OtpInput;
