import React from "react";
import ResponsiveAppBar from "../../components/TopNavigationBar/AppBar/AppBarMui";
import styled from "styled-components";
import {
  Container,
  createTheme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import HeroWavesComponent from "../../components/Sections/WaveAnimate";
import NeramFooter from "../../components/Footer/footer";
// COMPOENTS
import CollegeScroll from "./Components/CollegeScroll";
import Hero from "./Components/HeroAsk";
import VideoSection from "./Components/VideoAsk";
import { SmHeading } from "./Components/TextSecAsk";
import ScrollHang from "./Components/ScrollHang";
import BackToTopButton from "../../components/Basic components/BackToTop";

import { ScrollingProfiles } from "./Components/ProfileCard";
import BaseChatImg from "./Components/LastChatSec";

const BgGradient = styled.div`
  background: linear-gradient(to bottom, #fdeefe, #f0bdeb);
  min-height: 100vh; /* Ensure it covers the full height of the viewport */
  @media (max-width: 900px) {
    overflow-x: hidden;
  }
`;

export const RedOrangeText = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  @media (max-width: 576px) {
    font-size: 35px;
    line-height: 30px;
  }
  line-height: 52px;
  letter-spacing: -0.06em;
  background: var(--gradredorange);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

// THEME

const buttonTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        // Define styles for different variants
        contained: {
          background: "var(--gradredorange)",
          color: "#fff", // Ensure the text color is readable
          "&:hover": {
            background: "var(--gradredorange)", // Keep the same gradient on hover
            filter: "brightness(90%)", // Slightly darken on hover
          },
        },
      },
    },
  },
});

// MAIN COMPONENT

const Askseniors = () => {
  return (
    <ThemeProvider theme={buttonTheme}>
      <BackToTopButton />
      <BgGradient>
        <ResponsiveAppBar
          variant="opaque"
          largerScreenTitle="Ask Seniors Architecture Event"
          smallerScreenTitle="Ask Seniors"
          breadcrumbName={"Ask Seniors"}
          showBreadCrumBar={true}
          showBackButton={true}
        />
        {/* CONTENT */}
        <Container
          sx={{ padding: "7.5rem 0 0 0", zIndex: "1", position: "relative" }}
        >
          <Hero />
        </Container>
        <CollegeScroll />
        <br />
        <br />
        <br />

        <Container>
          <VideoSection />
          <ScrollHang />
          <SmHeading content={"Participating Guests"} />
          <Typography variant="h4" fontFamily={"Suez One"}>
            Proud NERAM Alumni: Shaping Futures at Prestigious Institutions
          </Typography>
          <br />

          <ScrollingProfiles />
          <BaseChatImg />
        </Container>
        {/* FOOTER */}
        <div className="position-relative">
          <HeroWavesComponent position="top" bgcolor={"var(--lightpink)"} />
          <NeramFooter />
        </div>
      </BgGradient>
    </ThemeProvider>
  );
};

export default Askseniors;
