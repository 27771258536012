import React, { useState, useEffect } from "react";
import styled from "styled-components";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Box, useMediaQuery } from "@mui/material";
import { MobileLogo, NeramLogo } from "../Childrens/logo";
import MyButton from "../../Basic components/Buttons/Buttons";
import { Link } from "react-router-dom";
// ICONS
import {
  School as SchoolIcon,
  MoreVert as MoreVertIcon,
  Task as TaskIcon,
  AddIcCall as AddIcCallIcon,
  Event as EventIcon,
  LocationCity as LocationCityIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// ROUTING
import { useNavigate } from "react-router-dom";
import BreadCrumBar from "./BreadCrumb";

const StyledAppBar = styled(AppBar)`
  .separator {
    background: white;
    width: 2px;
    opacity: 15%;
    height: 25px;
  }
  ${({ variant, scrolled }) =>
    variant === "opaque" || scrolled
      ? `
        background: var(--gradneram) !important;
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.15);
        top: 0;
      `
      : `
        background: transparent !important;
        box-shadow: none !important;
      `}
`;

const BackBreadCrum = styled.div`
  background-color: #ffffff1a;
  color: var(--violet);
  height: 2rem;
`;

const NavaItemYellow = styled.span`
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: 700;
  color: var(--yellow);
  text-transform: ${(props) => (props.uppercase ? "uppercase" : "none")};
`;

const MenuStyle = styled.div`
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: 0.5px;
`;

const MobileMenu = styled(Menu)`
  .MuiPaper-root {
    background: linear-gradient(
      111.37738709038058deg,
      #505050 1.557291666666667%,
      #e1148b 101.34895833333333%
    ) !important;
    width: 100%;
  }
`;

const OneMenu = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Changed to align-items */
  justify-content: center; /* Centering content along the main axis */
  height: 2rem;
  text-decoration: none;
  padding: 0 0.5rem;
  transition: ease-in 0.09s;
  &:hover {
    background-color: #ffffff3d;
    border-radius: 3px;
  }
`;

const ModifiedLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  @media (max-width: 768px) {
    padding: 1rem 0;
  }
`;

const pages = [
  {
    smallName: "Free",
    bigName: "Books",
    href: "/NATA_Free_Books",
    sub: [
      {
        name: "",
        link: "",
      },
      {
        name: "",
        link: "",
      },
    ],
  },
  {
    smallName: "NATA Cutoff",
    bigName: "Calculator",
    href: "/nata-cutoff-calculator",
    sub: [
      {
        name: "JEE Syllabus2",
        link: "",
      },
      {
        name: "NATA Syllabus2",
        link: "",
      },
    ],
  },
  {
    smallName: "NATA Meet",
    bigName: "#askSeniors",
    href: "/NATA_Best_Architecture_Colleges",
    sub: [
      {
        name: "JEE Syllabus",
        link: "",
      },
      {
        name: "NATA Syllabus",
        link: "",
      },
    ],
  },
  {
    smallName: "B.Arch Exam",
    bigName: "Syllabus",
    href: "",
    sub: [
      {
        name: "JEE Syllabus",
        link: "/JEE_B.arch_Syllabus",
      },
      {
        name: "NATA Syllabus",
        link: "",
      },
    ],
  },
  {
    smallName: "NATA Coaching",
    bigName: "Contact Us",
    href: "/NATA_Coaching_center_near_me_address",
    sub: [
      {
        name: "JEE Syllabus",
        link: "",
      },
      {
        name: "NATA Syllabus",
        link: "",
      },
    ],
  },
  {
    smallName: "MORE",
    bigName: <KeyboardArrowDownIcon />,
    href: "",
    sub: [
      {
        name: "Under Construction 1",
        link: "",
      },
      {
        name: "Under Construction 2",
        link: "",
      },
    ],
  },
];

export function MenuListItem({ subtext, maintext, index, sub, href }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    if (!href) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div onClick={handleClick}>
        <ModifiedLink to={href || "#"}>
          <OneMenu>
            <NavaItemYellow uppercase={index === 0}>{subtext}</NavaItemYellow>
            <MenuStyle className="nav-white" style={{ marginTop: "-5px" }}>
              {maintext}
            </MenuStyle>
          </OneMenu>
        </ModifiedLink>
      </div>
      {!href && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {sub.map((submenuItem, subIndex) => (
            <MenuItem key={subIndex} onClick={handleClose}>
              <Link to={submenuItem.link}>{submenuItem.name}</Link>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const settingsWithLinksAndIcons = [
  {
    text: "Neram Application Form",
    link: "/NATA_Application_Form_2025",
    icon: <TaskIcon />,
  },
  {
    text: "Call neramClasses",
    link: "tel:+1234567890",
    icon: <AddIcCallIcon />,
  },
  {
    text: "office Locations",
    link: "/NATA_Coaching_center_near_me_address",
    icon: <LocationCityIcon />,
  },
  {
    text: "Schedule DemoClass",
    link: "/NATA_Coaching_center_near_me_address",
    icon: <EventIcon />,
  },
  // You can add other settings with their corresponding links and icons here
];

function ResponsiveAppBar({
  smallerScreenTitle,
  largerScreenTitle,
  variant,
  showBreadCrumBar,
  showBackButton,
  breadcrumbName,
  ...props
}) {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const [scrolled, setScrolled] = useState(false);

  // MENU

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  // BREADCRUB
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  // ----

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    console.log("Opening user menu");
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <HideOnScroll {...props}>
      <StyledAppBar position="fixed" variant={variant} scrolled={scrolled}>
        <Container>
          <Toolbar disableGutters>
            {showBackButton && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="back"
                onClick={handleGoBack}
                className="d-none d-sm-block"
              >
                <ArrowBackIcon />
              </IconButton>
            )}
            <Link to="/">
              <NeramLogo sx={{ display: { xs: "none", md: "flex" } }} />
            </Link>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <MobileMenu
                id="menu-appbar-nav"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((item, index) => [
                  <MenuListItem
                    key={index}
                    subtext={item.smallName}
                    maintext={item.bigName}
                    index={index}
                    sub={item.sub}
                    href={item.href}
                  />,
                  index < 5 ? (
                    <div className="separator" key={`separator-${index}`}></div>
                  ) : null,
                ])}
              </MobileMenu>
            </Box>
            <Link to="/" style={{ textDecoration: "none" }}>
              <MobileLogo
                sx={{
                  display: { xs: "flex", md: "none" },
                }}
              />
            </Link>

            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  md: "flex",
                  justifyContent: "center",
                  alignItems: "Center",
                  gap: "10px",
                },
              }}
            >
              {pages.map((item, index) => (
                <React.Fragment key={index}>
                  <MenuListItem
                    href={item.href}
                    subtext={item.smallName}
                    maintext={item.bigName}
                    index={index}
                    sub={item.sub}
                  />
                  {index < 5 ? <div className="separator"></div> : <div></div>}
                </React.Fragment>
              ))}
            </Box>

            <Box
              sx={{
                flexGrow: { xs: 1, md: 0 },
                display: { xs: "flex", justifyContent: "end" },
              }}
            >
              <div className="d-none d-lg-block">
                <Tooltip title="Join NATA Coaching">
                  {/* Wrapping multiple elements inside a single div */}
                  <div>
                    <MyButton
                      variant="outlined"
                      className="rounded-pill"
                      onClick={handleOpenUserMenu}
                    >
                      Join Class
                    </MyButton>
                  </div>
                </Tooltip>
              </div>
              <div className="d-flex justify-content-center align-items-center d-lg-none gap-3 text-light">
                <Link
                  to="/NATA_Application_Form_2025"
                  style={{ textDecoration: "none" }}
                >
                  <SchoolIcon style={{ color: "white" }} />
                </Link>
                <MoreVertIcon
                  onClick={handleOpenUserMenu}
                  style={{ cursor: "pointer" }}
                />
              </div>

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar-user"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {" "}
                {settingsWithLinksAndIcons.map((item, index) => (
                  <MenuItem key={index} onClick={handleCloseUserMenu}>
                    {item.link.startsWith("tel:") ? (
                      <a
                        href={item.link}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <IconButton color="inherit">{item.icon}</IconButton>
                        {item.text}
                      </a>
                    ) : (
                      <Link
                        to={item.link}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <IconButton color="inherit">{item.icon}</IconButton>
                        {item.text}
                      </Link>
                    )}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
        <BackBreadCrum style={{ display: showBreadCrumBar ? "block" : "none" }}>
          {showBreadCrumBar && (
            <BreadCrumBar
              title={isSmallScreen ? smallerScreenTitle : largerScreenTitle}
              breadcrumbs={pages}
              breadcrumbName={breadcrumbName}
            />
          )}{" "}
          {/* Pass the pages data as breadcrumbs */}
        </BackBreadCrum>
      </StyledAppBar>
    </HideOnScroll>
  );
}

export default ResponsiveAppBar;
