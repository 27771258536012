import React from "react";
import CourseTabs from "./StepThreeComp/Course";
import IconLabelTabs from "./StepThreeComp/PayOptions";

function StepThree({ formData, setFormData, tabLabels, tabLabelsTwo }) {
  return (
    <>
      {/* COURSE */}
      <CourseTabs
        formData={formData}
        setFormData={setFormData}
        tabLabels={tabLabels}
      />
      <IconLabelTabs
        formData={formData}
        setFormData={setFormData}
        tabLabelsTwo={tabLabelsTwo}
      />
    </>
  );
}

export default StepThree;
