import { Avatar, Box, Typography } from "@mui/material";
import styled from "styled-components";
import GetRegisteredBtn from "./GetRegistered";

const Participant = [
  {
    imageLink: "/image/instagram.jpg",
    name: "Varsha",
    college: "NIT Trichy",
    rank: "JEE 99 Percentile",
  },
  {
    imageLink: "/image/instagram.jpg",
    name: "Vishnu",
    college: "CEPT",
    rank: "Masters in IIT",
  },
  {
    imageLink: "/image/instagram.jpg",
    name: "Sudharshini",
    college: "Oxford, London",
    rank: "Architect in UK",
  },
];

const ScrollingContainer = styled.div`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  justify-content: space-between;
`;

const ProfileWrapper = styled.div`
  display: inline-flex;
`;

export const ScrollingProfiles = () => {
  return (
    <>
      <Typography variant="p" fontSize={"14px"}>
        Meet our distinguished guests who began their journeys at NERAM and are
        now excelling at some of the most prestigious colleges across India.
        These accomplished alumni are here to share invaluable insights,
        guidance, and experiences that will help you navigate your own path to
        success. Don’t miss this opportunity to learn from the best!
      </Typography>
      <br />
      <br />
      <GetRegisteredBtn content={"Get Registered"} />
      <br />
      <br />
      <br />
      <ScrollingContainer>
        {Participant.map((participant, index) => (
          <ProfileWrapper key={index}>
            <OneProfileCard
              imageLink={participant.imageLink}
              name={participant.name}
              college={participant.college}
              rank={participant.rank}
            />
          </ProfileWrapper>
        ))}
      </ScrollingContainer>
    </>
  );
};

// PROFILE CARD

const OneProfileCard = ({ imageLink, name, college, rank }) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        gap: 2,
        py: 2,
        px: 3,
        backgroundColor: "var(--chatgreen)",
        borderRadius: "5px",
      }}
    >
      <Avatar
        alt="Profile Picture"
        src={imageLink}
        sx={{
          width: 85,
          height: 85,
          borderRadius: "5rem 0 5rem 5rem",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h6" sx={{ fontSize: "16px" }}>
            {name}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontStyle: "italic",
              color: "var(--grey700)",
            }}
          >
            {college}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontFamily: "Bakbak One",
            fontSize: "18px",
            color: "#1E4620",
          }}
        >
          {rank}
        </Typography>
      </Box>
    </Box>
  );
};

export default OneProfileCard;
