import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import routes from "./routes.js"; // Import the routes configuration
import { auth } from "./components/Firebase/setup.js";
import WhatsAppChatBubble from "./components/Whatsapp/ChatComponent.js";
import Tawktochat from "./components/TawkChat/Tawkto.js";
import { Box } from "@mui/material";

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  // DISABLE RIGHT CLICK
  useEffect(() => {
    const disableRightClick = (event) => {
      if (event.button === 2) {
        event.preventDefault();
      }
    };

    window.addEventListener("contextmenu", disableRightClick);

    return () => {
      window.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  return (
    <Router>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Routes>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <WhatsAppChatBubble />
        <Tawktochat />
      </Box>
    </Router>
  );
}

export default App;
