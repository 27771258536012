import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CountrySelect from "./CountryCode";

const PhoneInput = ({ onNext1, verificationCompleted }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [error, setError] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    // Focus on the input field when the component mounts
    inputRef.current?.focus();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleNext = (selectedCountry, phoneNumber) => {
    if (selectedCountry) {
      const fullNumber = `+${selectedCountry.phone}${phoneNumber}`;
      onNext1(fullNumber);
    }
  };

  // Validation function for phone number
  const validatePhoneNumber = (country, number) => {
    const countryCode = country.phone;
    const firstDigit = number.charAt(0);
    const reverseOrder = "9876543210";

    const isValid =
      number.length === 10 && // Adjusted length to 10
      countryCode === "91" && // Check for India country code
      (firstDigit === "6" ||
        firstDigit === "7" ||
        firstDigit === "8" ||
        firstDigit === "9") && // Check first digit
      !reverseOrder.includes(number); // Check if number is not in reverse order

    return isValid;
  };

  // Handler for phone number change
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value); // Set the phone number first

    if (
      selectedCountry &&
      selectedCountry.code === "IN" &&
      value.length === 10 // Change this to 10
    ) {
      // Perform validation
      const isValid = validatePhoneNumber(selectedCountry, value);
      if (isValid) {
        handleNext(selectedCountry, value); // Call handleNext only after validation
      } else {
        setError(true); // Set error state if validation fails
      }
    } else {
      setError(false); // Reset error state if phone number length is not 10
    }
  };

  return (
    <div className="d-flex gap-2 justify-content-center">
      <CountrySelect
        setSelectedCountry={setSelectedCountry}
        selectedCountry={selectedCountry}
      />
      <TextField
        inputRef={inputRef} // Correct usage of ref
        label="Phone Number"
        placeholder="Enter phone number"
        variant="outlined"
        size="small"
        inputProps={{
          maxLength: 10,
          inputMode: "numeric",
        }}
        sx={{ backgroundColor: "white", width: "100%" }}
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        error={error} // Apply error state to the TextField
        helperText={error ? "Invalid phone number" : ""} // Show helper text if error state is true
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleNext}
                disabled={!verificationCompleted}
              >
                {" "}
                {/* Disable if verification is not completed */}
                <ArrowForwardIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default PhoneInput;
